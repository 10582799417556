import React, { useState } from 'react'
import ScrollTrigger from 'react-scroll-trigger'
import CountUp from 'react-countup'

export default function Data(props) {
    const [counterOn, setCounterOn] = useState(false)
    return (

        <div className="col text-center">
            <div className="outer">
                <div className="inner">
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => { setCounterOn(false) }}>
                        <div className='text'>
                            {counterOn && <CountUp start={props.startcount} end={props.endcount} duration={2} delay={0} />}
                            <span>{props.sign}</span>
                        </div>
                    </ScrollTrigger>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                <defs>
                    <linearGradient id="GradientColor">
                        <stop offset="0%" stopColor="#e91e63" />
                        <stop offset="100%" stopColor="#222222" />
                    </linearGradient>
                </defs>
                <circle cx="80" cy="80" r="70" strokeLinecap="round" />
            </svg>
            <span className='name'>{props.title}</span>
        </div>


    )
}
