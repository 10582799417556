import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export default function Solution() {
  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', 'Innovate your employee upskilling with AI ‘text to video’ solutions. The Deep AI tech allows you to convert your legacy content into meaningful, engaging content.')
  })
  return (
    <>
      <Helmet>
        <title>AI based learning platform </title>
        <meta charset="utf-8" />
        <meta name="keywords" content="AI-based learning platform, employee upskilling, text to video solutions, Deep AI tech, legacy content" />
        <meta property="og:title" content='AI based learning platform' />
        <meta property="og:description" content='Innovate your employee upskilling with AI ‘text to video’ solutions. The Deep AI tech allows you to convert your legacy content into meaningful, engaging content.' />
        <meta property="og:url" content='https://byteedge.com/solution' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content='AI based learning platform' />
        <meta name="twitter:description" content='Innovate your employee upskilling with AI ‘text to video’ solutions. The Deep AI tech allows you to convert your legacy content into meaningful, engaging content.' />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://byteedge.com/solution" />
      </Helmet>
      {/* <div className='header-space'></div> */}
      <section>
        <div className='solution-sec-E'>
          <div className='container'>
            <div className='heading'>
              <h2>build an agile and constantly transforming organization</h2>
            </div>
            <ul className='bullet_points'>
              <li>build something “new” for empowering your people at scale</li>
              <li>innovate with latest “generative ai-based” solution to transform knowledge distribution </li>
              <li>improve accessibility and drive a culture of high performance across the organization</li>
              <li>cost-effective, safe, immersive, and easy to deploy internally and with external partners</li>
              <li>customizable to meet domain-specific business needs</li>
              <li>data-driven insights to support continuous knowledge flow</li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <div className='solution-sec-D'>
          <div className='container'>
            <div className='heading'>
              <h2>unmatched value for all organizational development needs </h2>
            </div>
            <div className='flex'>
              <div className='col'>
                <div className='item'>
                  <ul className='bullet_points'>
                    <li>400+ reel-style knowledge bytes to engage modern learners</li>
                    <li>tik-tok style peer to peer video sharing platform</li>
                    <li>measure everything in real time</li>
                    <li>pulse surveys, polls, quiz, assessments, & more</li>
                  </ul>
                </div>
              </div>
              <div className='col'>
                <div className='item'>
                  <ul className='bullet_points'>
                    <li>cinematic experience with meta-avatars converted from text & slideware content  </li>
                    <li>200+ expert curated microlearning courses – warm start with soft skills </li>
                    <li>perpetual digital micro credential</li>
                    <li>75+ language content localization </li>
                  </ul>
                </div>
              </div>
              <div className='col'>
                <div className='item'>
                  <ul className='bullet_points'>
                    <li>unique instructional designed based on andragogy and brain science of adult learning </li>
                    <li>next day launch enterprise-wide launch ready</li>
                    <li>dedicated customer success manager</li>
                    <li>in-platform nudging and notification for driving adoption</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='solution-sec-A'>
        <div className='container'>
          <div className='heading'>
            <h2>multilingual bespoke content</h2>
          </div>
          <div className='flex'>
            <div className='col'>
              <video playsInline controls>
                <source src='https://content.jwplatform.com/videos/kjYFTlNM-VXoqnFRg.mp4'></source>
              </video>
            </div>
            <div className='col'>
              <div className='content'>
                <p>true global democratization of knowledge can only happen with multi-lingual content and we are at the forefront of this transformation.</p>
                <p>promise of digital platforms can democratize knowledge distribution on a global scale but language barriers pose a significant challenge to this effort. </p>
                <p>ai dubbing is not just a novel technology; it's a game-changer, to overcome language barriers in knowledge and information flow. it ensures that quality knowledge, learning and education, irrespective of its language of origin, reaches and benefits everyone.</p>
                <p>voice plays a pivotal role in e-learning. a clear, coherent, and culturally relevant voiceover can significantly enhance comprehension and retention. it's not just about understanding the words; it's about relating to the content </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='solution-sec-B'>
        <div className='container'>
          <div className='heading'>
            <h2>microlearning</h2>
          </div>
          <div className='flex'>
            <div className='col'>
              <div className='content'>
                <ul className='bullet_points'>
                  <li>human attention span now at <b> 8 secs </b> is lower than that of a  gold fish</li>
                  <li><b>49% of learners</b> don't have time to learn </li>
                  <li><b>14 mins</b> is the time workers devote to learning per week</li>
                  <li><b>28% faster ability</b> to answer questions with microlearning </li>
                  <li><b>60 sec video equals 1.8 million</b> of written words for training retention </li>
                  <li><b>17% more efficient</b> when learning in micro byte for transfer of learning from class to desk</li>
                </ul>

              </div>
            </div>
            <div className='col'>
              <video playsInline controls>
                <source src='https://content.jwplatform.com/videos/Wp18nTzC-VXoqnFRg.mp44'></source>
              </video>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
