import { useQuery, gql } from '@apollo/client';

const ALLPOST = gql`query ($first: Int, $cursor: String) {
    posts(first: $first, after: $cursor) {
      pageInfo{
            startCursor
            hasNextPage
            hasPreviousPage
            endCursor
          }  
      edges {
        cursor
        node { 
          id
          title
          excerpt         
          slug        
          featuredImage{
            node{
              sourceUrl
              
            }
            
          }
          author{
            node{
              name
              
            }
          }
          date
          
      }
    }
  }
  }`

  export const UseBlogs=(first)=>{
    const { loading, error, data } = useQuery(ALLPOST,{
      variables: {
        first,
        cursor: null
    }
    })

    return{
        loading,
        error,
        data
    }
  }