import React, { useEffect } from 'react'
import lms from '../icons/LMS.png'
import compliance from '../icons/compliance-courses.png'
import analytics from '../icons/Data-Analytics.png'
import multiling from '../icons/multi-lingual.png'
import enterprise from '../icons/enterprise.png'
import healthcare from '../icons/health-care.png'
import awareness from '../images/solution/awareness.png'
import knowledge from '../images/solution/enforce-knowledge.png'
import publish from '../images/solution/publish-report.png'
import { Helmet } from 'react-helmet'

export default function Complience() {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute('content', 'AI in L&D, Gen AI, Sales 101, Negotiation, POSH, etc. are some of our best winning courses that every workforce needs to get their hands on.')
    })
    return (
        <>
            <Helmet>
                <title>Best compliance courses </title>
                <meta charset="utf-8" />
                <meta name="keywords" content="Best compliance courses, AI in L&D, Gen AI, Sales 101, Negotiation, POSH" />
                <meta property="og:title" content='Best compliance courses ' />
                <meta property="og:description" content='AI in L&D, Gen AI, Sales 101, Negotiation, POSH, etc. are some of our best winning courses that every workforce needs to get their hands on.' />
                <meta property="og:url" content='https://byteedge.com/compliance' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Best compliance courses' />
                <meta name="twitter:description" content='AI in L&D, Gen AI, Sales 101, Negotiation, POSH, etc. are some of our best winning courses that every workforce needs to get their hands on.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/compliance" />

            </Helmet>

            <div className="compliance-sec-A">
                <div className="container">
                    <div className="heading">
                        <h2>remove long, dull and boring content with fast paced, immersive and engaging content on compliance for modern workforce </h2>
                    </div>
                    <div className="flex">

                        <div className="item">

                            <div className="circle">
                                <div className="icon">
                                    <img src={lms} alt='byteedge'/>
                                </div>
                            </div>
                            <div className="text">
                                <p>bundled with our lxp & courses</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="circle">
                                <div className="icon">
                                    <img src={compliance} alt='byteedge'/>
                                </div>
                            </div>
                            <div className="text">
                                <p>customisable compliance courses to meet your specific needs</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="circle">
                                <div className="icon">
                                    <img src={analytics} alt='byteedge'/>
                                </div>
                            </div>
                            <div className="text">
                                <p>real time data analytics</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="circle">
                                <div className="icon">
                                    <img src={multiling} alt='byteedge'/>
                                </div>
                            </div>
                            <div className="text">
                                <p>multi-lingual courses available on demand</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="circle">
                                <div className="icon">
                                    <img src={enterprise} alt='byteedge'/>
                                </div>
                            </div>
                            <div className="text">
                                <p>designed for enterprise needs</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="circle">
                                <div className="icon">
                                    <img src={healthcare} alt='byteedge'/>
                                </div>
                            </div>
                            <div className="text">
                                <p>create custom courses for specific industry needs like healthcare and pharma</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className='compliance-sec-B'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>our approach to risk and compliance mitigation</h2>
                        </div>
                        <div className='flex'>
                            <div className='col'>
                                <div className='img'>
                                    <img src={awareness} alt='byteedge'/>
                                </div>
                                <p>create awareness and engagement</p>
                            </div>
                            <div className='col'>
                                <div className='img'>
                                    <img src={knowledge} alt='byteedge'/>
                                </div>
                                <p>enforce knowledge and assessment.</p>
                            </div>
                            <div className='col'>
                                <div className='img'>
                                    <img src={publish} alt='byteedge'/>
                                </div>
                                <p>publish report on participation and consumption</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='compliance-sec-C'>
                    <div className='container'>
                        <div className='content'>
                            <h3>intuitive and cinematic courses on</h3>
                            <ul className='bullet_points'>
                                <li>insider trading</li>
                                <li>conflict of interest</li>
                                <li>POSH</li>
                                <li>anti-bribery</li>
                                <li>protection of ipr</li>
                                <li>business code of conduct</li>
                                <li>data privacy and protection</li>
                                <li>role of ethics in decision making</li>
                                <li>anti-competitive and unfair pricing</li>
                                <li>regulatory and policy compliance</li>
                                <li>third-party relationships</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
