import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import chatbot from "../icons/be_chatbot.png";
import Chatform from "./Chatform";
import Chatmessage from "./Chatmessage"; 
import axios from "axios";
import { use } from "react";

export default function Chatbot() {
  const [chatHistory, setChatHistory] = useState([]);
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversationId, setConversationId] = useState("");
  const [userId, setUserId] = useState("");
  const [messageId, setMessageId] = useState(""); 
  const chatBodyRef = useRef();
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const inactivityTimeoutRef = useRef(null);
  const inactivityCalledRef = useRef(false);
  const userInteractedRef = useRef(false);
  const email = "experience@byteedge.com"; 
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const emailSentRef = useRef(false); 

  useEffect(() => {
    setUserId(uuidv4());
  }, []);

  useEffect(() => {
    if (!messageId || !userId) return;

    const controller = new AbortController();

    axios
      .get(`https://llm.byteedge.com/v1/messages/${messageId}/suggested`, {
        params: { user: userId },
        headers: {
          Authorization: "Bearer app-7jawyeR03OgOJGnwqWmJ2hzA",
          "Content-Type": "application/json",
        },
        signal: controller.signal,
      })
      .then((response) => {
        if (response.data.result === "success") {
          setSuggestedQuestions(response.data.data);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error fetching questions:", error);
          setSuggestedQuestions([]);
        }
      });

    return () => controller.abort();
  }, [messageId, userId]);

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setChatHistory([
      ...chatHistory,
      { role: "user", text: question },
      { role: "model", text: "..." },
    ]);
    generateBotResponse([{ role: "user", text: question }]);
  };

  const makeLinksClickable = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(
      urlRegex,
      (url) => `<a href="${url}" target="_blank">${url}</a>`
    );
  };

  const handleRefreshChat = async () => {
    setChatHistory([]);

    setSuggestedQuestions([]); 

    await sendChatEmail();
  };

  const handleInactivity = async () => {
    if (inactivityCalledRef.current || !userInteractedRef.current) return;
    inactivityCalledRef.current = true;

    try {
      await sendChatEmail();
      const userHasSentMessages = chatHistory.some(
        (msg) => msg.role === "user"
      );

      if (userHasSentMessages) {
        updateHistoryOnce(
          "This chat has ended due to inactivity. Please refresh to start new chat."
        );
        setSuggestedQuestions([]);
      }
    } catch (error) {
      console.error("Inactivity handling failed:", error);
    } finally {
      clearTimeout(inactivityTimeoutRef.current);
    }
  };
  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeoutRef.current);

    inactivityTimeoutRef.current = setTimeout(() => {
      if (!inactivityCalledRef.current) {
        handleInactivity();
      }
    }, 2 * 60 * 1000);
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!userInteractedRef.current) {
        userInteractedRef.current = true;
      }
      resetInactivityTimeout();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    window.addEventListener("click", handleUserInteraction);

    resetInactivityTimeout();

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      window.removeEventListener("click", handleUserInteraction);
      clearTimeout(inactivityTimeoutRef.current); 
    };
  }, []);

  const sendChatEmail = async () => {
    const conversation_Id = sessionStorage.getItem("conversationId");
    const user_Id = sessionStorage.getItem("userId");

    if (emailSentRef.current) return;

    try {
      const response = await fetch(
        "https://lms.byteedgebiz.com/api/send-chat-email",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer app-7jawyeR03OgOJGnwqWmJ2hzA",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user_Id,
            conversation_id: conversation_Id,
            email: email,
          }),
        }
      );

      if (response.status === 200) {
        emailSentRef.current = true;
        console.log("Chat email sent successfully.");
      }
    } catch (error) {
      console.error("Error sending chat email:", error);
      emailSentRef.current = true;
    }
  };

  const updateHistory = (text) => {
    setChatHistory((prev) => {
      const newHistory = [
        ...prev.filter((msg) => msg.text !== "..."),
        { role: "model", text: makeLinksClickable(text) },
      ];
      return newHistory;
    });
  };

  const updateHistoryOnce = (text) => {
    setChatHistory((prev) => {

      const messageExists = prev.some(
        (msg) =>
          msg.role === "model" &&
          msg.text ===
            "This chat has ended due to inactivity. Please refresh the page and start a new chat."
      );

      if (messageExists) return prev;

      const newHistory = [
        ...prev.filter((msg) => msg.text !== "..."),
        { role: "model", text: makeLinksClickable(text) }, 
      ];
      return newHistory;
    });
  };

  const generateBotResponse = async (history) => {
    userInteractedRef.current = true;
    const requestBody = {
      query: history[history.length - 1].text,
      inputs: {},
      response_mode: "streaming",
      user: userId,
      conversation_id: conversationId || undefined,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer app-7jawyeR03OgOJGnwqWmJ2hzA",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        "https://llm.byteedge.com/v1/chat-messages",
        requestOptions
      );

      if (!response.ok) {
        const data = await response.json();
        console.error("Error response from API:", data);
        if (
          data.code === "not_found" &&
          data.message === "Conversation Not Exists."
        ) {
          updateHistoryOnce(
            "This chat has ended. Please refresh the page and start a new chat."
          );
          setSuggestedQuestions([]);
        } else {
          throw new Error(data.error.message || "something went wrong!");
        }

        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let result = "";
      let accumulatedText = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
        const chunks = result.split("\n\n");
        result = chunks.pop(); 

        for (let chunk of chunks) {
          if (chunk.startsWith("data: ")) {
            try {
              const data = JSON.parse(chunk.slice(6));
              if (data.answer) {
                accumulatedText += data.answer;
              }
              if (data.conversation_id) {
                setConversationId(data.conversation_id);
                sessionStorage.setItem("conversationId", data.conversation_id);
                sessionStorage.setItem("userId", userId);

              }
              if (data.message_id) {
                setMessageId(data.message_id); 
              }
              if (data.suggested_questions) {
                setSuggestedQuestions(data.suggested_questions); 
              }
            } catch (e) {
              console.error("Error parsing chunk:", chunk, e);
            }
          }
        }
      }

      updateHistory(accumulatedText.trim());
      setSelectedQuestion(null);
    } catch (error) {
      console.error("Error fetching response:", error.message);
      updateHistory("Sorry, something went wrong.");
    }
  };

  useEffect(() => {
    chatBodyRef.current.scrollTo({
      top: chatBodyRef.current.scrollHeight,
      behaviour: "smooth",
    });
    resetInactivityTimeout();
  }, [chatHistory]);

  useEffect(() => {
    const controller = new AbortController();
    const chatContainer = document.querySelector(".chatbot-popup");
    const resetTimeout = () => resetInactivityTimeout();

    chatContainer.addEventListener("mousemove", resetTimeout);
    chatContainer.addEventListener("keypress", resetTimeout);

    const handleBeforeUnload = async (event) => {
      await sendChatEmail();

      event.preventDefault();

      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      controller.abort();
      clearTimeout(inactivityTimeoutRef.current);
      chatContainer.removeEventListener("mousemove", resetTimeout);
      chatContainer.removeEventListener("keypress", resetTimeout);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    resetInactivityTimeout();
  }, []);

  useEffect(() => {
    const handleUserInteraction = () => {
      userInteractedRef.current = true;
      resetInactivityTimeout();
    };

    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keypress", handleUserInteraction);

    return () => {
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keypress", handleUserInteraction);
    };
  }, []);

  return (
    <>
      <div className={`container ${showChatbot ? "show-chatbot" : ""}`}>
        <button
          onClick={() => {
            setShowChatbot((prev) => !prev);
          }}
          id="chatbot-toogler"
        >
          <span>
            <img src={chatbot} />
          </span>
          <span className="material-symbols-outlined">close</span>
        </button>
        <div className="chatbot-popup">
          {/* chatbot header*/}
          <div className="chat-header">
            <div className="header-info">
              <img className="chat" src={chatbot} alt="byteedge" />
              <h2 className="logo-text">chatbot</h2>
            </div>
            <button
              className="material-symbols-outlined"
              onClick={handleRefreshChat}
            >
              cached
            </button>
          </div>
          {/* chatbot body*/}
          <div ref={chatBodyRef} className="chatbot-body">
            <div className="message bot-message">
              <img className="chat" src={chatbot} alt="byteedge" />
              <p className="message-text">
                I am your AI buddy and can answer any question in natural human
                like language. Want to give me a try ?
              </p>
            </div>
            {chatHistory.map((message, index) => (
              <Chatmessage key={index} message={message} />
            ))}
          </div>
          <div className="ai-sugesstion">
            {suggestedQuestions.length > 0 && (
              <div className="ai-sugesstion">
                {suggestedQuestions.map((question, index) =>
                  !selectedQuestion ? (
                    <span
                      key={index}
                      onClick={() => handleQuestionClick(question)}
                    >
                      {question}
                    </span>
                  ) : null
                )}
              </div>
            )}
          </div>
          {/* chatbot footer*/}
          <div className="chat-footer">
            <Chatform
              chatHistory={chatHistory}
              setChatHistory={setChatHistory}
              generateBotResponse={generateBotResponse}
            />
          </div>
        </div>
      </div>
    </>
  );
}
