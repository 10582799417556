import React, { useEffect, useState } from 'react'
import logo from '../images/black-logo.png'
import close from '../icons/closeb.png'
import downarrow from '../icons/down-arrow.png'
import $ from 'jquery';
import {
    NavLink,
    Link
} from "react-router-dom";



export default function Header() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, SetSubject] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [custommessage, setCustommessage] = useState('')
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false)

    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleSubject = (e) => {
        SetSubject(e.target.value)
    }
    const handlePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleMessage = (e) => {
        setMessage(e.target.value)
    }
    const handleCustommessage = (e) => {
        setCustommessage(e.target.checked)
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!name) {
            newErrors.name = "name is required";
            isValid = false;
        }
        if (!email) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            newErrors.email = "please enter valid email";
            isValid = false;
        }
        if (!subject) {
            newErrors.subject = "subject is required";
            isValid = false;
        }
        if (!phone) {
            newErrors.phone = "please is required";
            isValid = false;
        } else if (isNaN(phone)) {
            newErrors.phone = "please enter numbers only";
            isValid = false;
        } else if (phone.length < 10) {
            newErrors.phone = "please enter 10 digit number"
            isValid = false;
        }

        if (!message) {
            newErrors.message = "message is required";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    function handleApi(event) {
        event.preventDefault()
        if (validateForm() === false) {
            setSubmitted(true);
            return true
        }
        const formdata = new FormData();
        setCustommessage(false)
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("message", message);
        formdata.append("subject", subject);
        formdata.append("phone", phone);
        formdata.append("custom_content", custommessage);
        const requestOptions = {
            method: "POST",
            body: formdata,
        };
        const responseDiv = document.getElementById('response-message');
        fetch("https://backend.byteedgelearning.com/request-a-demo-api.php", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                result = JSON.parse(result)
                if (result.message) {
                    responseDiv.innerText = result.message
                    responseDiv.classList.remove("error");
                    setName('')
                    setEmail('')
                    SetSubject('')
                    setPhone('')
                    setMessage('')
                    // return () => clearTimeout(timeout);

                    setTimeout(() => {
                        responseDiv.innerText = '';
                        
                      }, 2000);

                } else {
                    responseDiv.innerText = result.error
                    responseDiv.classList.add("error");
                    setTimeout(() => {
                        responseDiv.innerText = '';
                        responseDiv.classList.remove("error");
                      }, 2000);
            
                }
            })
            .catch((error) => error);


    }


    useEffect(() => {
        $('.model_open').on('click', function (e) {
            e.preventDefault();
            var model = $(this).attr('data-model')
            $('.model').removeClass('is-open')
            $(model).toggleClass('is-open')
            $('.overlay').addClass('overlyflm')
        })
        $('.close_model,.overlay').on('click', function (e) {
            e.preventDefault();
            $('.model').removeClass('is-open')
            $('.overlay').removeClass('overlyflm')
        })

        $(".frm-cntrl").each(function () {
            if ($(this).val() !== "") {
                $(this).addClass("valid")
            } else {
                $(this).removeClass("valid")
            }
        })
        // Form Input 
        $(".frm-cntrl").on("focusout", function () { $(this).val() ? $(this).addClass("valid") : $(this).removeClass("valid") })

        $('.downarrow').on('click', function (e) {
            e.preventDefault();

            $(this).siblings('ul.nablisting').addClass('open');
            $(this).parent().siblings('li').children('ul.nablisting').removeClass('open')
        })
        
        // Replace the text on the right hand side of the '=' with the id of your button.
        // Make sure you do not remove the single quotes.
        var buttonId = 'submitFormButton'; // Change this

        // DO NOT CHANGE THE CODE BELOW

        function trackingListener() {

            var capterra_vkey = 'b498d382e295db35ade03dbf72fcd405',

                capterra_vid = '2310905',

                ct = document.createElement('img');

            ct.src = 'https://ct.capterra.com/capterra_tracker.gif?vid='

                + capterra_vid + '&vkey=' + capterra_vkey;

            document.body.appendChild(ct);

        };

        var button = document.getElementById(buttonId);

        button.addEventListener(

            'click',

            trackingListener

        );
    })
    $(window).on('scroll', function () {
        var scroll = $(window).scrollTop();
        if (scroll < 100) {
            $('header').removeClass("header-fixed");
        } else {
            $('header').addClass("header-fixed");
        }
    });
    const closesidebar = () => {
        $('.model').removeClass('is-open')
        $('.overlay').removeClass('overlyflm')

    }
    return (
        <>
            <header>
                <div className="top-strip">
                    <div className="container-fluid">
                        <div className="flex">
                            <div className="col1">
                                <div className="nav-text left-side">
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                            <Link to="mailto:info@byteedge.com">info@byteedge.com</Link>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                            <Link to="tel:+919560304895">+91 95603 04895</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col2">
                                <div className="right-side">
                                    <ul className="social-links">
                                        <li>
                                            <Link to="https://www.linkedin.com/company/byteedge-learning/mycompany/" target='_blank' rel="noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                                </svg>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.facebook.com/byteEDGE/" target='_blank' rel="noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                </svg>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.instagram.com/byteedge_learning/" target='_blank' rel="noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                </svg>
                                            </Link>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-wapper">
                    <div className="container-fluid">
                        <div className="flex">
                            <div className="col1">
                                <div className="custom-logo"><Link to="/"><img src={logo} alt="bytedge" /></Link></div>
                            </div>

                            <div className="menu-trigger model_open" data-model=".model-navigation">
                                <span className="sma"></span>
                                <span className="smb"></span>
                                <span className="smc"></span>
                            </div>
                            <div className="col2">
                                <div className="custom-nav nav-text">
                                    <ul>
                                        <li className=""><Link to="/">home</Link></li>
                                        <li className=""><Link to="/pricing">pricing</Link></li>
                                        <li className="has-dropdown"><Link to="/products">products</Link>
                                            {/* <ul className="dropdown">

                                                <li><NavLink to="/text-to-video">ai “text to video” solutions </NavLink></li>
                                                <li><NavLink to="/pathbreak">pathbreaking mobile experience platform</NavLink></li>
                                                <li><NavLink to="/management-system">management system</NavLink></li>
                                                <li><NavLink to="/content-library">content library</NavLink></li>
                                                <li><NavLink to="/peer-to-peer-learning">community learning & video sharing </NavLink></li>
                                                <li><NavLink to="/workforce-effectiveness">workforce effectiveness calibration assessments</NavLink></li>
                                                <li><NavLink to="/pulse-and-polls">polls and pulse survey</NavLink></li>
                                            </ul> */}
                                            {/* <div className="dd-icon"><img src={downarrow} alt="arrow" /></div> */}
                                        </li>
                                        {/* <li className="has-dropdown"><NavLink to="/solution">solutions</NavLink>
                                            <ul className="dropdown">
                                                <li><NavLink to="/frontline-sales">frontline sales</NavLink></li>
                                                <li><NavLink to="/workplace-communication">workplace communication</NavLink></li>
                                                <li><NavLink to="/compliance">compliance </NavLink></li>

                                            </ul>
                                            <div className="dd-icon"><img src={downarrow} alt="arrow" /></div>
                                        </li> */}
                                        <li className="has-dropdown">
                                            <NavLink to="#">resources <div className="dd-icon"><img src={downarrow} alt="arrow" /></div> </NavLink>
                                            <ul className="dropdown">
                                                <li><NavLink to="/blogs">blogs</NavLink></li>
                                                {/* <li><NavLink to="/newsletter">newsletter</NavLink></li> */}
                                                <li><NavLink to="/people">people</NavLink></li>
                                            </ul>
                                           
                                        </li>
                                        <li><NavLink to="/contact-us">contact us</NavLink></li>
                                    </ul>
                                    <div className="nav-text demo-link"><Link to="#" className="model_open" data-model=".EnquiryModel"><span>request a live demo</span></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="model modelPop model-navigation">
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                {/* <div className="navover"><img src={logo} alt="byteedge" /></div> */}
                <div className="modelBody">
                    <div className="navigation-list">
                        <div className="serviice_nav">
                            <ul>
                                <li className='nablist'><NavLink to="/products" onClick={closesidebar}>products</NavLink>
                                    <ul className='nablisting'>
                                        <li><NavLink to="/text-to-video" onClick={closesidebar}>ai “text to video” solutions </NavLink></li>
                                        <li><NavLink to="/pathbreak" onClick={closesidebar}>pathbreaking mobile experience platform</NavLink></li>
                                        <li><NavLink to="/management-system" onClick={closesidebar}>management system</NavLink></li>
                                        <li><NavLink to="/content-library" onClick={closesidebar}>content library</NavLink></li>
                                        <li><NavLink to="/peer-to-peer-learning" onClick={closesidebar}>community – peer to peer learning & video sharing </NavLink></li>
                                        <li><NavLink to="/workforce-effectiveness" onClick={closesidebar}>workforce effectiveness calibration assessments</NavLink></li>
                                        <li><NavLink to="/pulse-and-polls" onClick={closesidebar}>polls and pulse survey</NavLink></li>
                                    </ul>
                                    <div className="downarrow"><img src={downarrow} alt="arrow" /></div></li>
                                {/* <li className='nablist'><NavLink to="/solution" onClick={closesidebar}>Solutions</NavLink>
                                    <ul className="nablisting">
                                        <li><NavLink to="/frontline-sales" onClick={closesidebar}>frontline sales</NavLink></li>
                                        <li><NavLink to="/workplace-communication" onClick={closesidebar}>workplace communication</NavLink></li>
                                        <li><NavLink to="/compliance" onClick={closesidebar}>compliance </NavLink></li>
                                    </ul>
                                    <div className="downarrow"><img src={downarrow} alt="arrow" /></div>
                                </li> */}
                                <li className='nablist'><NavLink to="#">Resources</NavLink>
                                    <ul className="nablisting">
                                        <li><NavLink to="/blogs" onClick={closesidebar}>blogs</NavLink></li>
                                        <li><NavLink to="/people" onClick={closesidebar}>people</NavLink></li>
                                    </ul>
                                    <div className="downarrow"><img src={downarrow} alt="arrow" /></div>
                                </li>
                                <li><NavLink to="/contact-us" onClick={closesidebar}>Contact us</NavLink></li>
                            </ul>
                        </div>
                        <div className="nav_list">
                            <ul>
                                <li><NavLink href="/privacy-policy" onClick={closesidebar}>Privacy Policy</NavLink></li>
                                <li><NavLink href="/terms-and-conditions" onClick={closesidebar}>Terms & Conditions</NavLink></li>
                            </ul>
                        </div>
                        <div className="social-links">
                            <ul>
                                <li>
                                    <Link to="https://www.facebook.com/byteEDGE/" target='_blank' rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/byteedge_learning/" target='_blank' rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/byteedge-learning/mycompany/" target='_blank' rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model modelPop EnquiryModel">
                <div className="close_model"><img src={close} alt="" /></div>
                <div className="contactform modelBody">

                    <form>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="back-input">
                                    <input id="name" type="text" name='name' className='frm-cntrl' required value={name} onChange={handleName} />
                                    <label>name</label>
                                    {errors.name && <div className="error">{errors.name}</div>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="back-input">
                                    <input id="email" type="email" name="email" className='frm-cntrl' required value={email} onChange={handleEmail} />
                                    <label>email</label>
                                    {errors.email && <div className="error">{errors.email}</div>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="back-input">
                                    <input id="subject" type="text" name="subject" className='frm-cntrl' required value={subject} onChange={handleSubject} />
                                    <label>context</label>
                                    {errors.subject && <div className="error">{errors.name}</div>}
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="back-input">
                                    <input id="phone" type="text" name="phone" className='frm-cntrl' required value={phone} onChange={handlePhone} />
                                    <label>mobile</label>
                                    {errors.phone && <div className="error">{errors.phone}</div>}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="back-textarea">
                                    <textarea id="message" name="message" className='frm-cntrl' required value={message} onChange={handleMessage}></textarea>
                                    <label>type message here....</label>
                                    {errors.message && <div className="error">{errors.message}</div>}

                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="back-input">
                                    <input id="customcontent" type="checkbox" name="customcontent" className='frm-cntrl custom' required value={custommessage} onChange={handleCustommessage} />
                                    <div className="text">need custom content</div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <button className="demo-link" onClick={handleApi} type='submit' id='submitFormButton' ><Link to="#"><span>submit</span></Link></button>
                            </div>

                        </div>
                    </form>
                    <div id="response-message"></div>
                </div>
            </div>
            <div className="mob_strip">

                <Link to="tel:9560938777">call us</Link>
                <Link to="#" className="model_open" data-model=".EnquiryModel">request a live demo</Link>
            </div>
            <div className="overlay"></div>
        </>
    )
}
