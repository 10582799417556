import React, { useEffect } from 'react'
import survey from '../images/product/pulse-survey.jpg'
import { Helmet } from 'react-helmet'

export default function Pollssurvey() {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute('content', 'the Polls and Survey feature of the byteEDGE LMS/LXP allows you to gauge the nerve of what others are thinking in your organization.')
    })
    return (
        <>
            <Helmet>
                <title>Drive Year-Round Engagement with byteEDGE</title>
                <meta charset="utf-8" />
                <meta name="keywords" content=" real-time analytics, data visualization, engagement platform, employee surveys, workforce engagement, pulse survey tool, polls platform, survey customization, engagement analytics " />
                <meta property="og:title" content='Drive Year-Round Engagement with byteEDGE' />
                <meta property="og:description" content='the Polls and Survey feature of the byteEDGE LMS/LXP allows you to gauge the nerve of what others are thinking in your organization.' />
                <meta property="og:url" content='https://byteedge.com/pulse-and-polls' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Drive Year-Round Engagement with byteEDGE' />
                <meta name="twitter:description" content='the Polls and Survey feature of the byteEDGE LMS/LXP allows you to gauge the nerve of what others are thinking in your organization.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/pulse-and-polls" />
            </Helmet>
            {/* <div className="header-space"></div> */}
            <div className="polls-sec-A">
                <div className="container">
                    <div className="text">
                        <h3>listen and engage to get valuable insights and sentiments</h3>
                    </div>
                </div>
            </div>
            <div className="polls-sec-B">
                <div className="container">
                    <div className="flex">
                        <div className="item">
                            <img src={survey} alt="byteedge" />
                        </div>
                        <div className="item">
                            <ul className="bullet_points">
                                <li>create surveys, assign users within minutes</li>
                                <li>pathbreaking ux designed for modern workforce</li>
                                <li>frictionless user access</li>
                                <li>deep customization with branding</li>
                                <li>10+ ready to go pulse surveys </li>
                                <li>free form template </li>
                                <li>unlimited surveys, admins, participants</li>
                                <li>drive year around engagement</li>
                                <li>all data stored locally at customer instance</li>
                                <li>in-platform nudging and notifications</li>
                                <li>real time analytics and data visualization</li>
                                <li>secured, private, protected</li>
                                <li>single click reporting</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="polls-sec-C">
                <div className="container">
                    <div className="flex">
                        <div className="item">
                            <div className="point">
                                <span>01</span>
                            </div>
                            <div className="text">
                                <h4>employee engagement </h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>02</span>
                            </div>
                            <div className="text">
                                <h4>mental health and wellness</h4>

                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>03</span>
                            </div>
                            <div className="text">
                                <h4>learning agility & skills assessment</h4>

                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>04</span>
                            </div>
                            <div className="text">
                                <h4>diversity and inclusion</h4>

                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>05</span>
                            </div>
                            <div className="text">
                                <h4>workplace communication</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>06</span>
                            </div>
                            <div className="text">
                                <h4>employee productivity</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>07</span>
                            </div>
                            <div className="text">
                                <h4>job roles effectiveness, training & skills gap</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>08</span>
                            </div>
                            <div className="text">
                                <h4>organizational culture</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>09</span>
                            </div>
                            <div className="text">
                                <h4>teaming and collaboration</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="point">
                                <span>10</span>
                            </div>
                            <div className="text">
                                <h4>workplace safety</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
