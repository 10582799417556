import React, { useState, useEffect } from 'react'
import pricing from '../images/pricing/pricing-banner.png'
import feature from '../images/pricing/feature1.png'
import feature2 from '../images/pricing/feature2.png'
import feature3 from '../images/pricing/feature3.png'
import enterprise from '../icons/study.png'
import communication from '../images/home/communication.jpg'
import close from '../icons/closeb.png'
import $ from 'jquery';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function NewHome() {
    const [name, setName] = useState('')
    const [errors, setErrors] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [designation, setDesignation] = useState('')
    const [company, setCompany] = useState('')
    const [employees, setEmployees] = useState('')
    const [cased, setCased] = useState('')
    const [subjectnew, SetSubjectnew] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const handleName = ((e) => {
        setName(e.target.value)
    })
    const handleEmail = ((e) => {
        setEmail(e.target.value)
    })
    const handlePhone = ((e) => {
        setPhone(e.target.value)
    })
    const handleDesignation = ((e) => {
        setDesignation(e.target.value)
    })
    const handleCompany = ((e) => {
        setCompany(e.target.value)
    })
    const handleEmployees = ((e) => {
        setEmployees(e.target.value)
    })
    const handlecased = ((e) => {
        setCased(e.target.value)
    })
    const handleClick = (e) => {
        const attr = e.target.getAttribute('data-attr');
        SetSubjectnew(attr); // Update state
    };
    const handleFoundationLaunchpad = (e) => {
        const attr = e.target.getAttribute('data-attr');
        SetSubjectnew(attr); // Update state
    };
    const handleAdvanced = (e) => {
        const attr = e.target.getAttribute('data-attr');
        SetSubjectnew(attr); // Update state
    };
    const handleElite = (e) => {
        const attr = e.target.getAttribute('data-attr');
        SetSubjectnew(attr); // Update state
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!name) {
            newErrors.name = "name is required";
            isValid = false;
        }

        if (!email) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            newErrors.email = "please enter valid email";
            isValid = false;
        }
        if (!phone) {
            newErrors.phone = "phone is required";
            isValid = false;
        } else if (isNaN(phone)) {
            newErrors.phone = "please enter numbers only";
            isValid = false;
        } else if (phone.length < 10) {
            newErrors.phone = "please enter 10 digit number"
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    function handleApi(event, e) {
        event.preventDefault()
        if (validateForm() === false) {
            setSubmitted(true);
            return true
        }
        const attr = $('button').attr('data-subject');

        // SetSubjectnew(attr)

        // SetSubject(attr)
        const formdata = new FormData();
        console.log(formdata)
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("designation", designation);
        formdata.append("employees", employees);
        formdata.append("company", company);
        formdata.append("cased", cased);
        formdata.append("subject", attr);
        // var attr = $(this).attr('data-attr')

        const requestOptions = {
            method: "POST",
            body: formdata,
        };
        const responseDiv = document.getElementById('form-message');
        window.location.href = '/demo-video';
        fetch("https://backend.byteedgelearning.com/connect-us-api.php", requestOptions)
            .then((response) => response.text())

            .then((result) => {
                result = JSON.parse(result)
                if (result.message) {
                    responseDiv.innerText = result.message
                    responseDiv.classList.remove("error");
                    setName('')
                    setEmail('')
                    setPhone('')
                    setDesignation('')
                    setCompany('')
                    setEmployees('')
                    setCased('')

                } else {
                    responseDiv.innerText = result.error
                    responseDiv.classList.add("error");
                }
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        $('.model_open').on('click', function (e) {
            e.preventDefault();
            var model = $(this).attr('data-model')
            var attr = $(this).attr('data-attr')
            $("button").attr("data-subject", attr);
            // $('.subjectnew').val(attr)
            $('.model').removeClass('is-open')
            $(model).toggleClass('is-open')
            $('.overlay').addClass('overlyflm')
        })
        $('.close_model,.overlay').on('click', function (e) {
            e.preventDefault();
            $('.model').removeClass('is-open')
            $('.overlay').removeClass('overlyflm')
            $('.Video-play').css('display', 'none')
        })

        $(".frm-cntrl").each(function () {
            if ($(this).val() !== "") {
                $(this).addClass("valid")
            } else {
                $(this).removeClass("valid")
            }
        })

        // var buttonId = 'submitFormButton'; // Change this

        // // DO NOT CHANGE THE CODE BELOW
        // function trackingListener() {
        //     var capterra_vkey = 'b498d382e295db35ade03dbf72fcd405',
        //         capterra_vid = '2310905',
        //         ct = document.createElement('img');
        //     ct.src = 'https://ct.capterra.com/capterra_tracker.gif?vid='
        //         + capterra_vid + '&vkey=' + capterra_vkey;
        //     document.body.appendChild(ct);
        // };
        // var button = document.getElementById(buttonId);
        // button.addEventListener(
        //     'click',
        //     trackingListener
        // );
    })
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://ct.capterra.com/capterra_tracker.gif?vid=2310905&vkey=b498d382e295db35ade03dbf72fcd405";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <div className='newhome-sec'>
                <div className='container'>
                    <div className="flex">
                        <div className='col'>
                            <img src={pricing} className='banner-image' alt='byteedge' />
                        </div>
                        <div className='col'>
                            <h2>empower your people and ecosystem with confidence</h2>
                            <p>Built for future of work | Powered by AI | Designed for Engaging Modern Workforce | Pathbreaking LXP and LMS with New Age Content Library, Custom Content, Reels, Social Learning and so much more</p>
                            <Link to="javascript:void(0)" className='highlight model_open' data-model=".Tablemodel">Compare with traditional LMS</Link>
                            <div className='watch-demo'>
                                <Link to="" className="btn model_open" data-model=".Pricing-form" onClick={handleClick} data-attr="watchademo">watch a demo</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='features'>
                <div className='container'>
                    <div className='heading'>
                        <h2>Features at a Glance</h2>
                    </div>
                    <div className='feature-list'>
                        <div className="flex">
                            <div className='col'>
                                <div className='feature-heading'>
                                    <div className='icon'><img src={enterprise} alt='byteedge' /></div>
                                    <h4>Enterprise Grade Secured ISO Certified LMS</h4>
                                </div>

                                <ul>
                                    <li>ILT Management, Coaching and Mentoring</li>
                                    <li>Integrated Modern CMS and CDN</li>
                                    <li>Assessment and Evaluation - Self Calibration</li>
                                    <li>Data Visualization and Deep Analytics</li>
                                    <li>Frictionless Reporting</li>
                                    <li>Pulse Surveys, User Ranking, Rewards, Certificates and more</li>
                                </ul>
                            </div>
                            <div className='col'>
                                <img src={feature} alt='byteedge'></img>
                            </div>
                        </div>
                    </div>
                    <div className='feature-list'>
                        <div className="flex">
                            <div className='col'>
                                <img src={feature2} alt='byteedge' style={{ width: '300px' }}></img>
                            </div>
                            <div className='col'>
                                <div className='feature-heading'>
                                    <div className='icon'><img src={enterprise} alt='byteedge' /></div>
                                    <h4>Consumer Grade Modern Mobile LXP</h4>
                                </div>

                                <ul>
                                    <li>Pathbreaking User Experience</li>
                                    <li>Integrate with Existing Systems</li>
                                    <li>Hyper Personalised for Every User</li>
                                    <li>Microlearning with Video Reels</li>
                                    <li>Gamification and Personalized Paths</li>
                                    <li>Social Learning - Peer to Peer Video Sharing Communities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='feature-list'>
                        <div className="flex">
                            <div className='col'>
                                <div className='feature-heading'>
                                    <div className='icon'><img src={enterprise} alt='icon' /></div>
                                    <h4>Immersive Microlearning Video Course Library</h4>
                                </div>
                                <ul>
                                    <li>400+ Courses for Frontline Workforce</li>
                                    <li>Compliance Courses</li>
                                    <li>“How to” Content - Improved Communication</li>
                                    <li>Multi Lingual Courses in 75+ Languages</li>
                                    <li>Curated Journeys for Sales and Frontline</li>
                                    <li>First Time Manager, User Onboarding, Sales and Services, Behavioural, Digital and more</li>
                                </ul>
                            </div>
                            <div className='col'>
                                <img src={feature3} alt='byteedge'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='licence-detial'>
                <div className='container'>
                    <div className='heading'>
                        <h2>every standard license product incudes</h2>
                    </div>
                    <div className='flex'>
                        <div className='col'>
                            <div className='item'>
                                <h4>LMS</h4>
                                <p>ISO Certified New Age LMS with Data Visualization, Reporting, Training Management, User Analytics, Gamification, Coaching and Mentoring, Assessments and Quiz, Pulse Surveys, Rewards, Certificates, Badges and More</p>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='item'>
                                <h4>LXP</h4>
                                <p>Consumer Grade Modern Mobile LXP with Microlearning, Curiosity Reels, Hyper Personalized , Individual Pathways Gamified Experience , Dashboards, Ranking, Leaderboard with Social Learning Community feature and more</p>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='item'>
                                <h4>content library</h4>
                                <p>Immersive Microlearning Video Course Library of 400+ Courses covering Behavioural, D&I, Compliance, ESG, Team Building, Communication and Collaboration, Sales Skills, Services for Frontline workforce & Managers</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className='pricing'>
                <div className='container'>
                    <div className='heading'>
                        <h2>pricing plans</h2>
                    </div>
                    <div className='flex'>
                        <div className='col'>
                            <div className='item'>
                                <div className='price'>
                                    <div className='type-btn'>Foundation - Launchpad</div>
                                    <div className='rupees'>
                                        <span className='symbol'>Rs.</span>
                                        <span className='rate'>2 Lacs <span className='year'>/year</span></span>
                                    </div>
                                    <div className='user'>Upto 100 users</div>
                                    <p>Full Stack Solution Enterprise Grade SaaS Solution</p>
                                </div>
                                <ul className='bullet_points'>
                                    <li>Custom Logo and Branding</li>
                                    <li>9 x 5 Remote Support</li>
                                    <li>Next Day Launch Ready with Frictionless Onboarding</li>
                                    <li>Available on Mobile and Desktop</li>
                                    <li>Online Provisioning</li>
                                    <li>Assisted Training for Admins</li>
                                    <li>Full assisted User onboarding till Go-live</li>
                                    <li>Customer Admin can create their own specific communities</li>
                                </ul>
                                <div className='buy-now'>
                                    <Link to="" className="btn model_open" onClick={handleFoundationLaunchpad} data-model=".Pricing-form" data-attr="FoundationLaunchpad">watch demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='item'>
                                <div className='price'>
                                    <div className='type-btn'>Advanced PRO</div>
                                    <div className='rupees'>
                                        <span className='symbol'>Rs.</span>
                                        <span className='rate'>3.99 Lacs <span className='year'>/year</span></span>
                                    </div>
                                    <div className='user'>Upto 250 users</div>
                                    <p>Full Stack Solution Enterprise Grade SaaS Solution</p>
                                </div>
                                <ul className='bullet_points'>
                                    <li>Custom Logo and Theme Branding</li>
                                    <li>Remote Customer Success Manager</li>
                                    <li>3 Working Days to Launch and Go-live</li>
                                    <li>Available on Mobile, Desktop</li>
                                    <li>Online Provisioning with Assisted CSM Support</li>
                                    <li>Full Admin Training and Go -live Assistance, Marketing Support</li>
                                    <li>Full assisted User onboarding till Go-live</li>
                                    <li>1 Hours of Free Course Dubbing or Translation in 75+ languages</li>
                                    <li>5 Custom Reels</li>
                                    <li>1 Hours of Custom Video Content Creation at no cost</li>
                                    <li>Assistance to create their own specific communities</li>
                                </ul>
                                <div className='buy-now'>
                                    <Link to="" className="btn model_open" onClick={handleAdvanced} data-model=".Pricing-form" data-attr="Advanced PRO">watch demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='item'>
                                <div className='price'>
                                    <div className='type-btn'>Elite Enterprise</div>
                                    <div className='rupees'>
                                        <span className='symbol'>Rs.</span>
                                        <span className='rate'>2000 <span className='year'>/user</span></span>
                                    </div>
                                    <div className='user'>more than 250 user</div>
                                    <p>Everything on Advanced PRO</p>
                                </div>
                                <ul className='bullet_points'>
                                    <li>Everything on Advanced PRO</li>
                                    <li>Available on Mobile, Desktop, Embed with Company App</li>
                                    <li>Marketing Assist, SSO Integration</li>
                                    <li>Rate card for Custom Video Content Creation Powered by AI</li>
                                    <li>Rate card for Dubbing and Translation Services in 75+ Languages</li>
                                    <li>100+ Custom Reels based on key organization priorities</li>
                                    <li>Rest API based Integration Existing Systems</li>
                                </ul>
                                <div className='buy-now'>
                                    <Link to="" className="btn model_open" onClick={handleElite} data-model=".Pricing-form" data-attr="Elite Enterprise">watch demo</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ai-powered'>
                <div className='container'>
                    <div className="content">
                        <ul className='bullet_points'>
                            <li>AI powered Custom Content Creation</li>
                            <li>Auto Text Extraction from PPT, PDF, Word and Excel</li>
                            <li>Instruction Designed Auto Scripting</li>
                            <li>5 click Document to Video Co-pilot</li>
                            <li>Avatars as Talking Heads with Lip Synced Audio</li>
                            <li>Rich Media Integration for Immersive Cinematic Videos</li>
                            <li>Video to Video Dubbing and Translation Services in 75+ Languages</li>
                            <div className='learn-more'>
                                <Link to="https://ai.byteedge.com/" className="btn" target='_blank'>learn more....</Link>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="model modelPop Pricing-form" id='form'>
                <div className="close_model">
                    <img src={close} alt="bytedge" />
                </div>
                <div className="flex">
                    <div className="col">
                        <div className="banner">
                            <img src={communication} alt="byteedge" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="modelBody">
                            <div className="contact-form">
                                <div className='title'>
                                    <h3>fill the details</h3>
                                </div>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <input id="subjectnew" type="hidden" name="subjectnew" className="subjectnew frm-cntrl" required="" value={subjectnew} placeholder='subject' />
                                            <div className="back-input">
                                                <input id="name" type="text" name="name" className="frm-cntrl" required="" value={name} onChange={handleName} placeholder='name' />
                                                {/* <label>name</label> */}
                                                {errors.name && <div className="error">{errors.name}</div>}
                                            </div>
                                            <div className="back-input">
                                                <input id="email" type="email" name="email" className="frm-cntrl" required="" value={email} onChange={handleEmail} placeholder='email' />
                                                {/* <label>email</label> */}
                                                {errors.email && <div className="error">{errors.email}</div>}
                                            </div>
                                            <div className="back-input">
                                                <input id="phone" type="text" name="phone" className="frm-cntrl" required="" value={phone} onChange={handlePhone} placeholder='phone' />
                                                {/* <label>email</label> */}
                                                {errors.phone && <div className="error">{errors.phone}</div>}
                                            </div>
                                            <div className="back-input">
                                                <input id="designation" type="text" name="designation" className="frm-cntrl" required="" value={designation} onChange={handleDesignation} placeholder='designation' />
                                                {/* <label>designation<span>(optional)</span></label> */}
                                                {errors.designation && <div className="error">{errors.designation}</div>}
                                            </div>
                                            <div className="back-input">
                                                <input id="company" type="text" name="company" className="frm-cntrl" required="" value={company} onChange={handleCompany} placeholder='company' />
                                                {/* <label>company<span>(optional)</span></label> */}
                                                {errors.company && <div className="error">{errors.company}</div>}
                                            </div>
                                            <div className="back-input">
                                                <input id="employees" type="text" name="employees" className="frm-cntrl" required="" value={employees} onChange={handleEmployees} placeholder='no. of employees' />
                                                {/* <label>no, of employees<span>(optional)</span></label> */}
                                                {errors.employees && <div className="error">{errors.name}</div>}
                                            </div>
                                            <div className="back-input">
                                                <input id="cased" type="text" name="cased" className="frm-cntrl" required="" value={cased} onChange={handlecased} placeholder='use case' />
                                                {/* <label>Use Case<span>(optional)</span></label> */}
                                                {errors.cased && <div className="error">{errors.cased}</div>}
                                            </div>
                                            <div className="col-lg-12">
                                                <button className="demo-link" type='submit' onClick={handleApi} data-subject="subject" id='submitFormButton'><Link to="#"><span>submit</span></Link></button>
                                            </div>
                                        </div>
                                        <div id="form-message"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model modelPop Tablemodel">
                <div className="close_model">
                    <img src={close} alt="bytedge" />
                </div>
                <div className="modelBody">
                    <div className="heading">
                        <h2 className='text-center'>Compare with traditional LMS</h2>
                    </div>
                    <div className='table-container'>
                        <table border="1">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Challenges with Current Approaches</th>
                                    <th>byteEDGE Approach</th>
                                    <th>Traditional Legacy LMS </th>
                                    <th>Key Benefits</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>High Friction Access - Not Available at their "Moments of Need"</td>
                                    <td>A Native Mobile Approach to provide single click access to knowledge served at the "moments of need", "how-to content", Reels, Actionable Insights and more for immediate application</td>
                                    <td>Desktop based, and only mobile responsive, lacking modern mobile features and functionality. Not intuitive, high friction, not designed for access at points of need</td>
                                    <td>This just-in-time and just-enough approach drives up to 40% improvement over traditional solutions as measured across 7000 users across 5 industries over a period of 10 months</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Poor Adoption due to lack of time and lowered span of attention</td>
                                    <td>Insta type Reels of course Trailers to ignite curiosity leading to higher engagement and adoption</td>
                                    <td>Lack modern Reels, unable to ignite curiosity to learn, dependent of L&D nudging, assignments only, does not engage modern workforce</td>
                                    <td>Modern Adult Workforce prefer platforms like insta, YouTube, Facebook etc. for media consumption. LMS need to go where learners are rather than trying to pull them to legacy systems. Get improved course launches and completion rates</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>High Course Dropouts due to dull boring content</td>
                                    <td>500+ Content Library of high velocity video based micro-learning courses and journeys based on Andragogy framework and evidence-based brain science for modern workforce</td>
                                    <td>Lack Impactful Video based Microlearning Courses Library - most of them have dated content built with pedagogy-based approaches which are long, dull and boring</td>
                                    <td>Up to 50% improvement in completion rates of courses due to Netflix-like video-based courses, high velocity relevant content and more</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Lack of Relevant functional, domain specific knowledge</td>
                                    <td>AI Powered Tools to convert any document like Word, PPT, PDF to Instructionally Designed Micro Learning Cinematic Video Courses at fraction of cost and time.</td>
                                    <td>Many do not have Integrated Authoring Tools, or are complex, need SME, time consuming, high cost to produce custom content, old style dull animation content not suited to adults</td>
                                    <td>Fit for Purpose workforce that drives improved results, high levels of productivity, lower conflicts, and improved collaboration, Improved TCE at industry lowest costs</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Unable to Learn from Peers, Social Learning engagements</td>
                                    <td>Tik-Tok like Video Sharing in Social Communities among peers to best practice sharing, learning, clarifying doubts and more</td>
                                    <td>Lack Social Communities, Lack Video sharing, unable to capture knowledge of experts in the organization and knowledge is lost when employee leaves</td>
                                    <td>75% of adult learning happens from Peers. Retain knowledge of people who have left the organization facilitating faster new employee assimilation</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Not personalized, lack of recommendation engine, Difficult discovery of content</td>
                                    <td>Powerful CMS system for deep categorization, AI powered search and recommendation systems for a hyper personalized experience with global CDNs</td>
                                    <td>Lack in-built CMS for creating persona/industry/competency-based content classification for personalization. Lack CDNs and not suited to rich media Videos</td>
                                    <td>Deeper Personalization with adaptive learning pathways, suggestions and recommendation facilitates culture of continuous learning and developing workforce</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Lack of motivation, reminders, insights, nudging and notifications</td>
                                    <td>Fitbit style goal tracking, user ranking, user dashboard, in-platform nudging and auto notifications with deep linking</td>
                                    <td>Lack Nudging and Notifications on mobile, desktop/email notification lack deep links and not effective</td>
                                    <td>Nudges and Motivates users to set and meet learning goals, keep track of assignments and create an overall competitive environment which motivates people to learn</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>On-job Feedback, lack mentoring, identification of Skill gaps</td>
                                    <td>Managers can assign and see activities of team members, self-calibration tool for identifying skill and competency gaps. Mentoring and Coaching feature</td>
                                    <td>Lack self-assessment, No support for Mentoring or coaching based on competency framework for identifying skill gaps at individual and organizational levels. Managers cannot take control of team's development, broken experience</td>
                                    <td>Improved alignment to core competencies by roles, skill gaps and capabilities. Helps in Building High performance teams</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Lack of Multi-lingual content and localization</td>
                                    <td>Multi-lingual Content, fully localized platform in over 75+ languages</td>
                                    <td>Lack multi-lingual content or local language support for platform</td>
                                    <td>Improves Accessibility specially for blue/grey collar workforce and frontline workforce</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Lacking impactful rewards and recognition</td>
                                    <td>Fully integrated user badges, rewards, recognition, certificates</td>
                                    <td>Lack rewards or gamified badging based on levels</td>
                                    <td>Motivates employee to learn more and perform better</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>



            <div className='overlay' />
        </>
    )
}
