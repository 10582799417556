import React, { useEffect } from 'react'

import knowledge from '../images/product/knowledge.jpg'
import { Helmet } from 'react-helmet'
export default function Community() {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute('content', 'With the byteEDGE community and peer to peer learning features, you can ensure that learning is inclusive.')
    })
    return (
        <>
            <Helmet>
                <title>Workforce engagement platform</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="Workforce upskilling platform, community, peer to peer learning, inclusive learning" />
                <meta property="og:title" content='Workforce engagement platform' />
                <meta property="og:description" content=' With the byteEDGE community and peer to peer learning features, you can ensure that learning is inclusive.' />
                <meta property="og:url" content='https://byteedge.com/peer-to-peer-learning' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Workforce engagement platform' />
                <meta name="twitter:description" content='With the byteEDGE community and peer to peer learning features, you can ensure that learning is inclusive.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/peer-to-peer-learning" />
            </Helmet>

            <section>
                <div className="managment-sec-A">
                    <video loop controls playsInline>
                        <source src='https://content.jwplatform.com/videos/571TAxKl-VXoqnFRg.mp4' type="video/mp4" />
                    </video>
                </div>
            </section>
            <div className="community-sec-A">
                <div className="container">
                    <div className="heading">
                        <h2>video-based knowledge sharing in community of peers transforms user engagements</h2>
                    </div>
                    <div className="flex">
                        <div className="col">
                            <div className="text">
                                <p>the impact of a community on organizations is enormous. it motivates the learners and instils in them a sense of belonging. the idea behind it is simple: ‘learn together’.</p>
                                <p>studies show that social e-learning approaches have a 75:1 ROI ratio compared to formal web-based training. </p>
                                <p>our 'community' feature allows learners to move across different communities or groups within the organization. these communities can also be used to share knowledge and insights and solve various queries. </p>
                            </div>
                        </div>
                        <div className="col">
                            <img src={knowledge} alt="byteedge" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="community-sec-C">
                <div className="container">
                    <div className="content">
                        <h3>community is a power tool for modern workforce</h3>
                        <ul className="bullet_points">
                            <li>practitioner generated social video sharing</li>
                            <li>build reputation as expert in closed community </li>
                            <li>clarify doubts from your company veterans</li>
                            <li>safe place to share insights, best practices</li>
                            <li>helps to overcome limiting belief</li>
                            <li>drives new perspectives and non-linear thinking</li>
                            <li>unlock creativity, unleash potential</li>
                            <li>admin controlled participation</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
