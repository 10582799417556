import React, { useEffect } from 'react'

import experience from '../icons/experience.png'
import Engage from '../icons/engaged.png'
import Accelerate from '../icons/Accelerate.png'
import Transform from '../icons/transform.png'
import change from '../icons/change.png'
import automate from '../icons/automation.png'
import onboard from '../icons/onboarding.png'
import develop from '../icons/develop.png'
import assess from '../icons/assess.png'
import assign from '../icons/assignment.png'
import recognize from '../icons/recognition.png'
import reward from '../icons/reward.png'
import Uidesgined from '../images/product/generation-mobile.jpg'
import Accelerategrowth from '../images/product/accelerate-growth.jpg'
import buildteams from '../images/product/winning-team.jpg'
import employee from '../images/product/employee-engage.jpg'
import partnerecosystem from '../images/product/partner.jpg'
import { Helmet } from 'react-helmet'
export default function Pathbreak() {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute('content', 'The transition to mobile learning is real, especially when it comes to workforce upskilling. With the byteEDGE LMS you can learn on the go.')
    })
    return (
        <>
            <Helmet>
                <title> Best Mobile LMS</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="Best Mobile LMS, mobile learning, workforce upskilling, learn on the go" />
                <meta property="og:title" content='Best Mobile LMS' />
                <meta property="og:description" content='The transition to mobile learning is real, especially when it comes to workforce upskilling. With the byteEDGE LMS you can learn on the go.' />
                <meta property="og:url" content='https://byteedge.com/pathbreak' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Best Mobile LMS' />
                <meta name="twitter:description" content='The transition to mobile learning is real, especially when it comes to workforce upskilling. With the byteEDGE LMS you can learn on the go.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/pathbreak" />
            </Helmet>
            {/* <div className="header-space"></div> */}
            <section>
                <div className="managment-sec-A">
                    <video loop controls playsInline>
                        <source src='https://content.jwplatform.com/videos/ZGY3iKz5-VXoqnFRg.mp4' type="video/mp4" />
                    </video>
                </div>
            </section>
            <section>
                <div className="pathbreak-sec-A">
                    <div className="container">
                        <div className="heading">
                            <h2>pathbreaking mobile experience platform </h2>
                        </div>
                        <div className="flex">
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={experience} alt="experience" />
                                    </div>
                                </div>
                                <span>experience</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={Engage} alt="engaged" />
                                    </div>
                                </div>
                                <span>engage</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={Accelerate} alt="accelerate" />
                                    </div>
                                </div>
                                <span>accelerate</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={Transform} alt="transform" />
                                    </div>
                                </div>
                                <span>transform</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={Transform} alt="transform" />
                                    </div>
                                </div>
                                <span>ignite</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={change} alt="change" />
                                    </div>
                                </div>
                                <span>change</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={automate} alt="automate" />
                                    </div>
                                </div>
                                <span>automate</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={onboard} alt="onboard" />
                                    </div>
                                </div>
                                <span>onboard</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={develop} alt="develop" />
                                    </div>
                                </div>
                                <span>develop</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={assess} alt="assess" />
                                    </div>
                                </div>
                                <span>assess</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={assign} alt="assign" />
                                    </div>
                                </div>
                                <span>assign</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={recognize} alt="recognize" />
                                    </div>
                                </div>
                                <span>recognize</span>
                            </div>
                            <div className="item">
                                <div className="circle">
                                    <div className="icon">
                                        <img src={reward} alt="reward" />
                                    </div>
                                </div>
                                <span>reward</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="pathbreak-sec-B">
                    <div className="container">
                        <div className="flex">
                            <div className="col1">
                                <div className="img">
                                    <img src={Uidesgined} alt="" />
                                </div>
                            </div>
                            <div className="col2">
                                <h4 className='mb-3 text-white' >next generation mobile ui/ux designed for today's workforce</h4>
                                <ul className="bullet_points">
                                    <li>professional improvement</li>
                                    <li>scalable across enterprise</li>
                                    <li>hyper-personalized</li>
                                    <li>local language-based discovery</li>
                                    <li>rapid onboarding</li>
                                    <li>system and process knowledge</li>
                                    <li>quiz, nps and pulse surveys</li>
                                    <li>stress free, frictionless access</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="pathbreak-sec-C">
                    <div className="container">
                        <div className="flex">
                            <div className="item">
                                <div className="img">
                                    <img src={Accelerategrowth} alt="byteedge" />
                                </div>
                                <p>accelerate growth</p>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src={buildteams} alt="byteedge" />
                                </div>
                                <p>build winning teams</p>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src={employee} alt="byteedge" />
                                </div>
                                <p>transform employee engagement</p>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src={partnerecosystem} alt="" />
                                </div>
                                <p>enable your partner ecosystem</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
