import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel'
import research from '../images/product/research.jpg'
import Accelerategrowth from '../images/product/applying-acquired.png'
import buildteams from '../images/product/just-in-time.png'
import employee from '../images/product/job-done.png'
import partnerecosystem from '../images/product/memory-retention.png'
import inference from '../images/product/inference.jpg'
import aigenerated from '../images/product/AI-Generated.jpg'
import bella from '../images/product/bella.jpg'
import classroom from '../images/product/Classroom.jpg'
import conversation from '../images/product/conversational.jpg'
import humanavtar from '../images/product/Human-avatar.jpg'
import metaavtar from '../images/product/meta-avatar.jpg'
import metavirtual from '../images/product/meta-virtual.jpg'
import multilingual from '../images/product/multilingual.jpg'
import webcast from '../images/product/webcast.jpg'
import vdeiocn from '../icons/vdeiocn.png'
import close from '../icons/closeb.png'
import $ from 'jquery'
import { Helmet } from 'react-helmet'

export default function Content() {
    useEffect(() => {
        $('.model_open').click(function (e) {
            e.preventDefault();
            var model = $(this).attr('data-model')
            $('.overlay').addClass('overlyflm')
            var thisindex = $(this).attr('data-value')
            $(model).each(function () {
                if ($(this).attr('id') === thisindex) {
                    $(model).removeClass('is-open')
                    $(this).addClass('is-open')
                }
            })
        });
        $('.close_model,.overlay').on('click', function () {
            $('.model').removeClass('is-open')
            $('.overlay').removeClass('overlyflm');
            $('.video_full iframe').attr('src', '')
        })

        document.querySelector('meta[name="description"]').setAttribute('content', 'Designed by our in-house team of videographers, we take employee engagement as seriously as the L&D heads do.')
    })
    const options = {
        loop: false,
        items: 4,
        margin: 20,
        dots: true,
        nav: false,
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 4000,
        smartSpeed: 800,
        responsive: {
            0: {
                items: 1,
                margin: 0
            },
            767: {
                items: 2,
                margin: 0

            },
            991: {
                items: 2,
                margin: 0
            },
            1024: {
                items: 4
            }
        }

    }
    return (
        <>
            <Helmet>
                <title>Employee Engagement Platform</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="Employee Engagement Platform, course library, employee engagement, in-house team, videographers" />
                <meta property="og:title" content='Employee Engagement Platform' />
                <meta property="og:description" content='Designed by our in-house team of videographers, we take employee engagement as seriously as the L&D heads do.' />
                <meta property="og:url" content='https://byteedge.com/content-library' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Employee Engagement Platform' />
                <meta name="twitter:description" content='Designed by our in-house team of videographers, we take employee engagement as seriously as the L&D heads do.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/content-library" />
                
            </Helmet>

            <div className='content-banner-sec'>
                <div className='bg'>
                    <img src={research} alt='byteedge'></img>
                </div>
                <div className='content'>
                    <p>brain science research & evidence-based andragogy is the framework we have adopted for all our course development to make it unlike any other.</p>
                </div>
            </div>
            <section>
                <div className='content-sec-A'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>knowledge reinforcement</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>easy high velocity sharp content</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>scenario based learning</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>real world case studies for global best practices</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>motivating quotes from acclaimed leaders</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>engaging storytelling for retention</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='text'>
                                        <p>summary and recap for retention</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='content-sec-B'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <div className='img'>
                                    <img src={inference} alt='byteedge' />
                                </div>
                            </div>
                            <div className='col'>
                                <div className='content'>
                                    <h3>inference and demonstrating understanding</h3>
                                    <ul className='bullet_points'>
                                        <li>by classifying key ideas</li>
                                        <li>rich media infographics</li>
                                        <li>proof points of statistical data and infographics</li>
                                        <li>audio selection for increased retention </li>
                                        <li>knowledge check evaluation</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="content-sec-C">
                    <div className="container">
                        <div className='heading'>
                            <h2>apply with confidence</h2>
                        </div>
                        <div className="flex">
                            <div className="item">
                                <div className="img">
                                    <img src={Accelerategrowth} alt="byteedge" />
                                </div>
                                <p>apply acquired knowledge</p>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src={buildteams} alt="byteedge" />
                                </div>
                                <p>just in time application</p>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src={employee} alt="byteedge" />
                                </div>
                                <p>just enough to get the job done</p>
                            </div>
                            <div className="item">
                                <div className="img">
                                    <img src={partnerecosystem} alt="" />
                                </div>
                                <p>6-8 mins for short term memory retention</p>
                            </div>
                            {/* <div className="item">
                                <div className="img">
                                    <img src={partnerecosystem} alt="" />
                                </div>
                                <p>actionable insights for quick referrals</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <section id='journeys'>
                <div className='content-sec-E'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>experience </h2>
                        </div>
                        <OwlCarousel {...options}

                        >
                            <div className="item model_open" data-model='.model-video' data-value='ai-generater'>
                                <div className="img">
                                    <img src={aigenerated} alt="byteedge" />
                                </div>
                                <div className='text'>
                                    <p>ai-generated content</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model='.model-video' data-value='human'>
                                <div className="img">
                                    <img src={humanavtar} alt="byteedge" />
                                </div>
                                <div className='text'>
                                    <p>human avatar</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>

                            <div className="item model_open" data-model=".model-video" data-value='conversation'>
                                <div className="img">
                                    <img src={conversation} alt="byteedge" />
                                </div>
                                <div className='text'>
                                    <p>conversational</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model=".model-video" data-value='classroom'>
                                <div className="img">
                                    <img src={classroom} alt="byteedge" />
                                </div>
                                <div className='text'>
                                    <p>classroom</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model=".model-video" data-value='bella'>
                                <div className="img">
                                    <img src={bella} alt="byteedge" />
                                </div>
                                <div className='text'>
                                    <p>bella</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model=".model-video" data-value='webcast'>
                                <div className="img">
                                    <img src={webcast} alt="" />
                                </div>
                                <div className='text'>
                                    <p> webcast to video content</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model=".model-video" data-value='multilingual'>
                                <div className="img">
                                    <img src={multilingual} alt="" />
                                </div>
                                <div className='text'>
                                    <p>multilingual</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model=".model-video" data-value='meta'>
                                <div className="img">
                                    <img src={metaavtar} alt="" />
                                </div>
                                <div className='text'>
                                    <p>meta</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                            <div className="item model_open" data-model=".model-video" data-value='virtual'>
                                <div className="img">
                                    <img src={metavirtual} alt="" />
                                </div>
                                <div className='text'>
                                    <p>virtual</p>
                                </div>
                                <div className="VdeMnSemn" >
                                    <div className="VdeIcnMn VideObtnSec">
                                        <img src={vdeiocn} alt="byteedge" />
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            <div className="model modelPop model-video" id='ai-generater'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/9Z5IA4Lv-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='human'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/sOgPnql8-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='conversation'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/ApWy5dZE-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='classroom'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/clmHGOs2-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='bella'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/hsOaMovS-ZMg8u8Kl.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='webcast'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/5xptvLa8-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='multilingual'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/sJdE7Xoe-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='meta'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/zJw7AXr3-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>
            <div className="model modelPop model-video" id='virtual'>
                <div className="close_model">
                    <img src={close} alt="" />
                </div>
                <div className="modelBody">
                    <video controls>
                        <source src='https://cdn.jwplayer.com/videos/zOklKGYK-VXoqnFRg.mp4'></source>
                    </video>
                </div>
            </div>



        </>
    )
}
