import React, { useState, useEffect } from 'react'
// import $ from 'jquery'
import OwlCarousel from 'react-owl-carousel'
// import Slider from './Slider'
import logo1 from '../images/logo/AD.png'
import logo2 from '../images/logo/GT.png'
import logo3 from '../images/logo/Pollinate-group.png'
import logo5 from '../images/logo/Seagull.png'
import logo6 from '../images/logo/Healthium.png'
import logo7 from '../images/logo/LT.png'
import logo8 from '../images/logo/neulandlabs.png?v=11'
import logo9 from '../images/logo/dcm-logo.png'
import logo10 from '../images/logo/Sysnet.png'
import logo11 from '../images/logo/tatacommunication_white.png'
import logo12 from '../images/logo/ESAF.png'
import logo13 from '../images/logo/ARKA.png'
import logo14 from '../images/logo/honda-logo.png'
import logo15 from '../images/logo/JK-cement.png'
import challenge1 from '../images/short-format-video.jpg'
import challenge2 from '../images/mobile-has-surpassed-desktop.jpg'
import challenge3 from '../images/no-time-to-read-texts.jpg'
import challenge4 from '../images/1.jpg'
import challenge5 from '../images/budget-drain.jpg'
import challenge6 from '../images/inability-to-calibrate.jpg'
import experience from '../icons/learning.png'
import management from '../icons/management.png'
import creation from '../icons/video-creation.png'
import analytics from '../icons/analytic.png'
import transform_sales from '../images/home/transform-sales-workforce.jpg'
import communication from '../images/home/communication.jpg'
import compliance from '../images/home/compliance.jpg'
import conflicts from '../images/home/conflicts.jpg'
import workforce from '../images/home/workfrorce.jpg'
import trainings from '../images/home/modern-trainings.jpg'
import listening from '../images/home/listening-workforce.jpg'
import Data from './Data'
import Partner from './Partner'
import Clients from './Clients'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'



export default function Home() {
    const [testimonials, Settestimonials] = useState(null)
    useEffect(() => {
        const url = 'https://lms.byteedgebiz.com/api/v2/testimonial'
        axios
            .get(url)
            .then((res) => {
                Settestimonials(res.data)
            })
        document.querySelector('meta[name="description"]').setAttribute('content', 'upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.')
    }, [])
    const options = {
        loop: true,
        items: 1,
        margin: 70,
        dots: true,
        nav: false,
        mouseDrag: true,
        autoplay: false,
        autoplayTimeout: 4000,
        smartSpeed: 800,
        responsive: {
            0: {
                items: 1,
                margin: 0
            },
            767: {
                items: 1,
                margin: 0
            },
            991: {
                items: 1,
                margin: 0
            },
            1024: {
                items: 1
            }
        }

    }
    const homeslide = {
        loop: true,
        items: 3,
        // margin: 70,
        dots: true,
        nav: false,
        center: true,
        mouseDrag: true,
        autoplay: true,
        autoplayTimeout: 4000,
        smartSpeed: 800,
        responsive: {
            0: {
                items: 1,
                margin: 0
            },
            767: {
                items: 1,
                margin: 0
            },
            991: {
                items: 1,
                margin: 0
            },
            1024: {
                items: 3
            }
        }

    }


    return (
        <>
            <Helmet>
                <title>best microlearning platform| CMS for upskilling and reskilling</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="microlearning platform, CMS, upskilling, reskilling, AI text to video, organizational knowledge transfer" />
                <meta property="og:title" content='best microlearning platform| CMS for upskilling and reskilling' />
                <meta property="og:description" content='upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.' />
                <meta property="og:url" content='https://byteedge.com/' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='best microlearning platform| CMS for upskilling and reskilling' />
                <meta name="twitter:description" content='upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.' />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            {/* ----------------section1------------------ */}
            <section>
                <div className='about-app'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <div className='content'>
                                    <h2>World's 1st AI powered knowledge transfer solution for organization capability development</h2>
                                    <ul className='bullet_points'>
                                        <li>pathbreaking mobile <span>learning experience platform</span> for frontline workforce</li>
                                        <li>enterprise grade <span>learning management system</span> with video content library</li>
                                        <li>document to cinematic <span>video creation</span></li>
                                        <li>global delivery with <span>deep analytics</span> and reporting</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="demo-video">
                                    <video loop muted autoPlay>
                                        <source src="https://cdn.jwplayer.com/videos/3b5j6V2f-VXoqnFRg.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className='flex aiplatform'>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>01</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={experience} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>pathbreaking mobile <span>learning experience platform</span> for frontline workforce</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>02</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={management} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>enterprise grade <span>learning management system</span> with video content library</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>03</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={creation} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>document to cinematic <span>video creation</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>04</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={analytics} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>global delivery with <span>deep analytics</span> and reporting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section2------------------ */}
            <section>
                <div className="home-sec-E">
                    <div className="container">
                        <div className='partners'>
                            <div className='flex'>
                                <div className='col'>
                                    <Partner imglogo={logo1} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo2} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo3} />
                                </div>
                                {/* <div className='col'>
                                    <Partner imglogo={logo4} />
                                </div> */}
                                <div className='col'>
                                    <Partner imglogo={logo5} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo7} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo8} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo10} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo9} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo6} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo11} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo12} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo13} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo14} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo15} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section3------------------ */}
            <section>
                <div className="home-sec-D">
                    <div className="container">
                        {/* <div className="heading">
                            <h1>impact</h1>
                        </div> */}
                        <div className="flex">
                            {/* <Data startcount={0} endcount={45000} title="Users" sign="+" /> */}
                            <Data startcount={0} endcount={13} title="Countries" />
                            <Data startcount={0} endcount={75} title="Languages" sign="+" />
                            <Data startcount={0} endcount={3000} title="learning modules" sign="+" />
                            <Data startcount={0} endcount={30000} title="courses completed" sign="+" />
                            {/* <Data startcount={0} endcount={500} title="hrs of content creation" />
                            <Data startcount={0} endcount={85} title="adoption rates" sign="%" /> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section4------------------ */}
            <section>
                <div className="product-sec-7">
                    <div className="container">
                        <div className="product-data">
                            <div className="image">
                                <span>83% would rather watch live video than read instructional or information content</span>
                            </div>
                            <div className="image">
                                <span>75% of workers prefer video than documents, email, blogs, books etc - forrester </span>
                            </div>
                            <div className="image">
                                <span>95% knowledge retention in a video compared to 10% when reading it as text </span>
                            </div>
                            <div className="image">
                                <span>96% of people have watched an explainer video to learn more about a product or service </span></div>
                            <div className="image">
                                <span>66% say they pay the most attention to short-form videos which is 2.5x more than long content</span>
                            </div>
                            <div className="image">
                                <span>1200% more clicks, shares and engagements than images and text combined </span>
                            </div>
                            <div className="image">
                                <span>49% of learners don't have time to learn - linkedin workplace learning report</span>
                            </div>
                            <div className="image">
                                <span>human attention span now at 8 secs is lower than that of a goldfish - microsoft brain research</span>
                            </div>
                            <div className="image">
                                <span>28% faster ability to answer questions with microlearning - dresden univ study </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section5------------------ */}

            <section>
                <div className="home-sec-B1">
                    <div className="container">
                        <div className="heading">
                            <h1>overcome challenges with legacy approaches</h1>
                        </div>
                        <div className="challenges">
                            <div className="flex">
                                <div className="col">
                                    <div className="hexa">
                                        <div className="icon">
                                            <img src={challenge1} alt="shifting-behaviour" />
                                        </div>
                                    </div>
                                    <p className='text'>modernize your industry specific product, policies and processes content
                                        to cinematic videos</p>
                                </div>
                                <div className="col">
                                    <p className='text2'>ignite curiosity and user engagement with short video reels</p>
                                    <div className="hexa hexa2">
                                        <div className="icon">
                                            <img src={challenge2} alt="access-data" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="hexa hexa3">
                                        <div className="icon">
                                            <img src={challenge3} alt="no-time-to-read" />
                                        </div>
                                    </div>
                                    <p className='text3'>improve adoption with cinematic micro-learning video content</p>
                                </div>
                                <div className="col">
                                    <p className='text4'>ai powered in-platform nudging for digital, hybrid and blended
                                        interventions</p>
                                    <div className="hexa hexa4">
                                        <div className="icon">
                                            <img src={challenge4} alt="video-creation" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="hexa hexa5">
                                        <div className="icon">
                                            <img src={challenge5} alt="budget-drain" />
                                        </div>
                                    </div>
                                    <p className='text5'>enhance accessibility with our localised and multi-lingual content in
                                        75+ languages.</p>
                                </div>
                                <div className="col">
                                    <p className='text6'>measure and inspect everything with deep video analytics and data
                                        visualisation</p>
                                    <div className="hexa hexa6">
                                        <div className="icon">
                                            <img src={challenge6} alt="inability-to-calibrate" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section6------------------ */}
            <section>
                <div className='home-sec-C'>
                    <div className='container'>
                        <div className="heading">
                            <h1>unleash full potential of your people and ecosystem</h1>
                        </div>
                        <OwlCarousel className='homeslider' {...homeslide}>
                            <div className='itemslider'>
                                <Link to="/frontline-sales" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={transform_sales} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Transform Your Frontline Sales and Services Workforce</p>
                                            <Link to="/frontline-sales" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/peer-to-peer-learning" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={communication} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Improve Communication and Collaboration</p>
                                            <Link to="/peer-to-peer-learning" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/compliance" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={compliance} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Create Engaging Compliance Programs</p>
                                            <Link to="/compliance" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/workplace-communication" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={conflicts} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Reduce Workplace Conflicts</p>
                                            <Link to="/workplace-communication" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/workforce-effectiveness" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={workforce} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Calibrate Workforce Effectives</p>
                                            <Link to="/workforce-effectiveness" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/text-to-video" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={trainings} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Modernize Process and Safety Trainings</p>
                                            <Link to="/text-to-video" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/pulse-and-polls" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={listening} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Voice of your workforce</p>
                                            <Link to="/pulse-and-polls" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/* <div className='flex'>
                            <div className='item'>
                                <p>Transform Your Frontline Sales and Services Workforce</p>
                                <div className='readmore'>
                                    <Link to='/frontline-sales'>read more..</Link>
                                </div>
                            </div>
                            <div className='item'>
                                <p>Improve Communication and Collaboratione</p>
                                <div className='readmore'>
                                    <Link to='/peer-to-peer-learning'>read more..</Link>
                                </div>
                            </div>
                            <div className='item'>
                                <p>Create Engaging Compliance Programs</p>
                                <div className='readmore'>
                                    <Link to='/compliance'>read more..</Link>
                                </div>
                            </div>
                            <div className='item'>
                                <p>Reduce Workplace Conflicts</p>
                                <div className='readmore'>
                                    <Link to='/workplace-communication'>read more..</Link>
                                </div>
                            </div>
                            <div className='item'>
                                <p>Calibrate Workforce Effectives</p>
                                <div className='readmore'>
                                    <Link to='/workforce-effectiveness'>read more..</Link>
                                </div>
                            </div>
                            <div className='item'>
                                <p>Modernize Process and Safety Trainings</p>
                                <div className='readmore'>
                                    <Link to='/text-to-video'>read more..</Link>
                                </div>
                            </div>
                            <div className='item'>
                                <p>Listen from your workforce</p>
                                <div className='readmore'>
                                    <Link to='/pulse-and-polls'>read more..</Link>
                                </div>
                            </div>
                        </div> */}


            {/* ----------------section7------------------ */}
            <section>
                <div className="testimonial-sec">
                    <div className="container">
                        <div className="heading text-center">
                            <h1>voices of delight</h1>
                        </div>
                        <OwlCarousel {...options}>
                            {testimonials && testimonials.data.data.map((element) => {
                                return <div className="testimonial-card" key={element.id}>
                                    <Clients quote={element.comment} name={element.user_name} desgination={element.user_bio} image={element.user_avatar}></Clients>
                                </div>
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/*----------------section-8------------------ */}
            <section>
                <div className='review-sec'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_e05f195f-e536-41df-81d1-ae4d14ef9669' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/e05f195f-e536-41df-81d1-ae4d14ef9669' style={{overflow: 'hidden'}} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_a9345eb2-3eab-43d3-99bf-9240e985d69e' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/a9345eb2-3eab-43d3-99bf-9240e985d69e' style={{overflow: 'hidden'}} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_ec36c936-a137-4ed0-ab48-b04b3ec33b15' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/ec36c936-a137-4ed0-ab48-b04b3ec33b15' style={{overflow: 'hidden'}} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_ec36c936-a137-4ed0-ab48-b04b3ec33b15' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/ec36c936-a137-4ed0-ab48-b04b3ec33b15' style={{overflow: 'hidden'}} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_07e7ec28-7d4a-4b5a-a61c-8e1782cc0718' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/07e7ec28-7d4a-4b5a-a61c-8e1782cc0718' style={{overflow: 'hidden'}} scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
