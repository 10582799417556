import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/style.css';
// import './sass1/style.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// import './index.css'



const client = new ApolloClient({
  uri: "https://backend.byteedgelearning.com/graphql",
  cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
