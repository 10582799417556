import React, { useEffect } from 'react'
import workplace from '../images/product/management.jpg'
import { Helmet } from 'react-helmet'

export default function Assessments() {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute('content', "Discover byteEDGE's comprehensive assessment framework for aligning performance criteria with skills, tailored for organizational objectives.")
    })
    return (
        <>
            <Helmet>
                <title>assessment-based learning platform</title>
                <meta charset="utf-8" />
                <meta name="keywords" content=" Assessment-based learning platform, Polls and Survey feature, gauge, nerve, organization " />
                <meta property="og:title" content='assessment-based learning platform' />
                <meta property="og:description" content="Discover byteEDGE's comprehensive assessment framework for aligning performance criteria with skills, tailored for organizational objectives." />
                <meta property="og:url" content='https://byteedge.com/workforce-effectiveness' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='assessment-based learning platform' />
                <meta name="twitter:description" content="Discover byteEDGE's comprehensive assessment framework for aligning performance criteria with skills, tailored for organizational objectives."/>
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/workforce-effectiveness" />
            </Helmet>

            <div className="assesment-sec-A">
                <div className="container">
                    <div className="flex">
                        <div className="col">
                            <div className="content">
                                <h3>aligning performance criteria with the skills, organizations can now provide clear expectations and feedback to employees & how their performance relates to the desired competencies and behavioral skills. </h3>
                                <ul className="bullet_points">
                                    <li>comprehensive assessment framework for calibrating workforce effectiveness</li>
                                    <li>baselined on global competency framework designed for enterprises across industries</li>
                                    <li>fully customized for alignment with organization cultural pillars </li>
                                    <li>user grouping based on role or personas</li>
                                    <li>organizations can effectively identify areas for growth and tailor development plans</li>
                                    <li>the model covers a wide range of skills and behaviors, such as communication, problem-solving, adaptability, and leadership, integral to culture of organization</li>
                                    <li>the model ensures a systematic and objective approach to succession planning and talent mobility</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col">
                            <img src={workplace} alt="byteedge" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="assesment-sec-A assesment-sec-B">
                <div className="container">
                    <div className="flex">
                        <div className="col">
                            <img src={workplace} alt="byteedge" />
                        </div>
                        <div className="col">
                            <div className="content">
                                <h3>so, how does it work?</h3>
                                <ul className="bullet_points">
                                    <li>each competency is calibrated based on 5 point Likert scale</li>
                                    <li>5-6 competencies make a cultural pillar of organizational competency framework</li>
                                    <li>industry standard of 12 such pillars are pre-calibrated by our global expert for immediate application</li>
                                    <li>user selects response based on self-calibration of ability to demonstrate the proficiency in workplace by selecting pillars</li>
                                    <li>users can be assigned any pillar for assessment from backend</li>
                                    <li>you can create your own competency pillar from the drop-down list of all 67 competencies and assign to user groups</li>
                                    <li>user sees broad calibration of the pillar, backend has all the data and analytics</li>
                                    <li>user sees personalized recommendation of courses for improvement in their mobile app</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
