import React, { useEffect } from 'react'
import kamal from '../images/team/kamal.jpg'
import akash from '../images/team/akash.jpg'
import heide from '../images/team/heide_abelli.jpg'
import Team from './Team'
import { Helmet } from 'react-helmet'

export default function People() {
    useEffect(() => {
        document.querySelector('meta[name="description"]').setAttribute('content', 'Join the byteEDGE team and be part of the learning revolution. Explore exciting career opportunities and contribute to reshaping the future of corporate training.')
    })
    return (
        <>
            <Helmet>
                <title>Careers | Join the byteEDGE Team</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="byteEDGE, careers, job opportunities, learning revolution, corporate training, career opportunities" />
                <meta property="og:title" content='Careers | Join the byteEDGE Team' />
                <meta property="og:description" content='Join the byteEDGE team and be part of the learning revolution. Explore exciting career opportunities and contribute to reshaping the future of corporate training.' />
                <meta property="og:url" content='https://byteedge.com/people' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Careers | Join the byteEDGE Team' />
                <meta name="twitter:description" content='Join the byteEDGE team and be part of the learning revolution. Explore exciting career opportunities and contribute to reshaping the future of corporate training.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/people" />

            </Helmet>

            <section>
                <div className="team-sec">
                    <div className="container">
                        <div className="heading text-center">
                            <h2 className='mb-2'>founders</h2>
                        </div>
                        <div className="team-details">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <Team img={kamal} name="kamal dutta" desig="co-founder" linked_url="https://www.linkedin.com/in/kamaldutta/" />
                                </div>
                                <div className="col-md-4 col-12">
                                    <Team img={akash} name="akash gupt" desig="co-founder" linked_url='https://www.linkedin.com/in/akash-gupt-54913712/' />
                                </div>
                                <div className="col-md-4 col-12">
                                    <Team img={heide} name="heide abelli" desig="co-founder" linked_url='https://www.linkedin.com/in/heideabelli/' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='about-sec-A'>
                    <div class="container">
                        <div class="slide-mission vission">
                            <div class="title title-vission">our vision</div>
                            <div class="desc desc-vission"><p>global platform of choice to unlock, uplift and unleash human and organizational potential.</p></div>
                        </div>
                        <div class="slide-mission mission">
                            <div class="title">our purpose</div>
                            <div class="desc">
                                <p>great strategies are irrelevant without skilled people. we believe that the toughest part of organizational transformation and growth acceleration is its people and their capabilities to execute.</p>
                                <p>our purpose is to build a leading-edge ai-powered full stack solution that empowers organizations to transform their legacy organizational knowledge and information to immersive mobile video experiences to uplift their people and ecosystem to accelerate business growth. keeping people at front and centre, we are constantly pushing the boundaries of digital experiences with artificial intelligence to ensure that we play a small role in impacting the lives of 3 billion global workforce and can serve the underserved in ways previously inconceivable.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
