import React, { useEffect } from 'react'
import download from '../images/download-banner.jpg'
import qrcode from '../images/downloadapp-qr.png'
import downloadimage from '../images/download-app.png'
import playstore from '../images/playstore.png'
import appstore from '../images/app-store.png'
import { Helmet } from 'react-helmet'

export default function Download() {
  useEffect(()=>{
    document.querySelector('meta[name="description"]').setAttribute('content', 'Access the byteEDGE app and unlock a world of knowledge on your mobile device. Download now to enjoy seamless learning wherever and whenever you want.')
  })
  return (
    <>
      <Helmet>
        <title>Download byteEDGE App | Microlearning Platform</title>
        <meta charset="utf-8" />
        <meta name="keywords" content="byteEDGE, download, mobile app, microlearning platform, seamless learning, knowledge access" />
        <meta property="og:title" content='best microlearning platform| CMS for upskilling and reskilling' />
        <meta property="og:description" content='Access the byteEDGE app and unlock a world of knowledge on your mobile device. Download now to enjoy seamless learning wherever and whenever you want. ' />
        <meta property="og:url" content='https://byteedge.com/download-our-app' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content='best microlearning platform| CMS for upskilling and reskilling' />
        <meta name="twitter:description" content='Access the byteEDGE app and unlock a world of knowledge on your mobile device. Download now to enjoy seamless learning wherever and whenever you want. ' />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://byteedge.com/download-our-app" />
      </Helmet>
      <div className='download-banner'>
        <div className='bg'>
          <img src={download} alt='download'></img>
        </div>
        <div className='content'>
          <h2>download our app</h2>
        </div>
      </div>
      <section>
        <div className='download-sec'>
          <div className='container'>
            <div className='download-app'>
              <div className='flex'>
                <div className='col'>
                  <div className='app-design'>
                    <img src={downloadimage} alt='byteedge' />
                  </div>
                </div>
                <div className='col'>
                  <img src={qrcode} alt='byteedge' className='qrcode' />
                  <div className='app-links'>
                    <div className='app-store'>
                      <a href='https://apps.apple.com/in/app/byteedge/id1631500899' target='_blank'>
                        <img className="ios" src={appstore} alt="App-Store" />
                      </a>
                    </div>
                    <div className='playstore'>
                      <a href='https://play.google.com/store/search?q=byteedge&c=apps&hl=en_IN&gl=US' target='_blank'>
                        <img className="play" src={playstore} alt="Google-Play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
