import React, { useEffect } from 'react'
// import img1 from '../images/img1.jpg'
// import img2 from '../images/img2.png'
// import img3 from '../images/img3.png'
// import img4 from '../images/img4.jpg'
// import img5 from '../images/social-community.jpg'
// import img6 from '../images/sales-journey.jpg'
// import img7 from '../images/sales-transformation.jpg'
import Slider from './Slider'
import product1 from '../images/product/scalable.png'
import product2 from '../images/product/micro-service.png'
import product3 from '../images/product/GDPR.png'
import product4 from '../images/product/authetication.png'
import product5 from '../images/product/data-encryption.png'
import product6 from '../images/product/3rd-party-security.png'
import product7 from '../images/product/data-sorted.png'
import product8 from '../images/product/dmr-protection.png'
import product9 from '../images/product/learn.png'
import icon1 from '../icons/scalable1.png'
import icons1 from '../icons/scalable.png'
import icon2 from '../icons/micro-services1.png'
import icons2 from '../icons/micro-services.png'
import icon3 from '../icons/GDPR1.png'
import icons3 from '../icons/GDPR.png'
import icon4 from '../icons/oauth1.png'
import icons4 from '../icons/oauth.png'
import icon5 from '../icons/data-encryption1.png'
import icons5 from '../icons/data-encryption.png'
import icon6 from '../icons/security1.png'
import icons6 from '../icons/security.png'
import icon7 from '../icons/client-data1.png'
import icons7 from '../icons/client-data.png'
import icon8 from '../icons/dmr-protection1.png'
import icons8 from '../icons/dmr-protection.png'
import icon9 from '../icons/safe-place-to-learn1.png'
import icons9 from '../icons/safe-place-to-learn.png'
import proposition from '../images/product/proposition.png'
import aipowerd from '../images/banner/Ai-powered-mobile.png'
import multi from '../images/banner/multilingual.jpg'
import organize from '../images/banner/organizational.png'
import realtime from '../images/banner/real-time-data.jpg'
import unique from '../images/banner/Unique-Video-Experience.jpg'
import { Helmet } from 'react-helmet'
import OwlCarousel from 'react-owl-carousel'

export default function Product() {
  useEffect(() => {
    window.onscroll = function () {
      const mainNav = document.getElementById('roto');

      if (mainNav) {
        if (window.pageYOffset > 1) {
          mainNav.classList.add("active");

        } else {
          mainNav.classList.remove("active");

        }
      }
    }
    document.querySelector('meta[name="description"]').setAttribute('content', 'Get access to a huge library of curated content, bespoke content, live session streaming, question banks with random quizzes with our smart LMS.')

  }, [])
  const gradesolution ={
    items: 1,
    loop: true,
    autoplay: true,
    smartSpeed: 10000,
    margin: 10,
    dots: true,
    nav: true,
    mouseDrag: false,
    animateOut: 'fadeOut'
  }

  return (
    <>

      <Helmet>
        <title>Learning Management System</title>
        <meta charset="utf-8" />
        <meta name="keywords" content="Learning Management System, curated content, live session streaming, question banks, smart LMS " />
        <meta property="og:title" content='Learning Management System' />
        <meta property="og:description" content='Get access to a huge library of curated content, bespoke content, live session streaming, question banks with random quizzes with our smart LMS.' />
        <meta property="og:url" content='https://byteedge.com/' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content='Learning Management System' />
        <meta name="twitter:description" content='Get access to a huge library of curated content, bespoke content, live session streaming, question banks with random quizzes with our smart LMS.' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <div className="header-space"></div> */}
      
      {/* <section id="roto">
        <div id="rotoTransition" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000"
          data-bs-pause="false">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="slide">
                <img src={img1} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img1} alt="slide-1" /></div>
                <div className="roundInner"><img src={img1} alt="slide-1" /></div>
                <div className="slideText">

                  <p className="animate__animated animate__fadeInUp ">new age reels drive people's curiosity and engagement.  </p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slide">
                <img src={img2} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img2} alt="slide-1" /></div>
                <div className="roundInner"><img src={img2} alt="slide-1" /></div>
                <div className="slideText">

                  <p className="animate__animated animate__fadeInUp">empower ecosystem including blue collar with 75+ languages.</p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slide">
                <img src={img3} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img3} alt="slide-1" /></div>
                <div className="roundInner"><img src={img3} alt="slide-1" /></div>
                <div className="slideText">

                  <p className="animate__animated animate__fadeInUp">transform workplace communication with pragmatic “how-to” videos. </p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slide">
                <img src={img4} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img4} alt="slide-1" /></div>
                <div className="roundInner"><img src={img4} alt="slide-1" /></div>
                <div className="slideText">
                  <p className="animate__animated animate__fadeInUp">cinematic video content library of 250+ courses for modern workforce.  </p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slide">
                <img src={img4} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img5} alt="slide-1" /></div>
                <div className="roundInner"><img src={img5} alt="slide-1" /></div>
                <div className="slideText">
                  <p className="animate__animated animate__fadeInUp">social community with video sharing to drive collaboration.</p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slide">
                <img src={img4} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img6} alt="slide-1" /></div>
                <div className="roundInner"><img src={img6} alt="slide-1" /></div>
                <div className="slideText">
                  <p className="animate__animated animate__fadeInUp">curated sales journey for frontline retail workforce</p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="slide">
                <img src={img4} alt="slide-1" className="mainImage" />
                <div className="roundOuter"><img src={img7} alt="slide-1" /></div>
                <div className="roundInner"><img src={img7} alt="slide-1" /></div>
                <div className="slideText">
                  <p className="animate__animated animate__fadeInUp">professional value selling 101 journeys for sales transformation.</p>
                  <div className="buttonNav animate__animated animate__fadeInDown">
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="prev"><i className="fa-solid fa-arrow-left-long"></i></button>
                    <span></span>
                    <button type="button" data-bs-target="#rotoTransition" data-bs-slide="next"><i className="fa-solid fa-arrow-right-long"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="home-sec-A">
          <OwlCarousel {...gradesolution}>
            <Slider imageurl={aipowerd} content="ai-powered document to video platform" bullet1="Powerful Gen AI capabilities"
             bullet2="Multi-lingual content creation in 75+ languages" bullet3="Cinematic Immersive Content creation in just few clicks" link="/text-to-video"/>
            <Slider imageurl={multi} content="content management & global video delivery networks" bullet1="Designed for hyper personalization"
             bullet2="Built for Large Videos file sizes" bullet3="Video Analytics with modern APIs" link="/content-library"/>
            <Slider imageurl={organize} content="pathbreaking native mobile LXP" bullet1="Reels drive user curiosity and engagement"
             bullet2="Social Communities for peer-to-peer video sharing" bullet3="Gamified Dashboards, Blended and Hybrid Read" link="/pathbreak"/>
            <Slider imageurl={unique} content="next generation hybrid LMS" bullet1="Frictionless reporting, data visualization and analytics"
             bullet2="Value Realization Dashboard by business and functions" bullet3="Customizable with branding, themes and more" link="/management-system"/>
            <Slider imageurl={realtime} content="cinematic video courses library" bullet1="3000+ micro learning modules – soft skills, productivity and compliance"
             bullet2="brain science research & evidence-based andragogy" bullet3="Curated frontline sales journeys"/>

          </OwlCarousel>
        </div>
      </section>

      {/* ----------------section3------------------ */}
      <section>
        <div className="product-sec-C">
          <div className="container">
            <div className="heading">
              <h2>drive better results with confidence</h2>
            </div>
            <div className="flex">
              <div className="col">
                <div className="content">
                  <p>improved organizational agility as per industry reports </p>
                </div>
                <div className="icon">
                  <span>34%</span>
                </div>
              </div>
              <div className="col">
                <div className="content">
                  <p>improved customer satisfaction with information at their palm</p>
                </div>
                <div className="icon">
                  <span>45%</span>
                </div>
              </div>
              <div className="col">
                <div className="content">
                  <p>improved in people productivity with content served at moment of need</p>
                </div>
                <div className="icon">
                  <span>19%</span>
                </div>
              </div>
              <div className="col">
                <div className="content">
                  <p>improved in workplace communication and collaboration</p>
                </div>
                <div className="icon">
                  <span>50%</span>
                </div>
              </div>
              <div className="col">
                <div className="content">
                  <p>improved confidence on employee knowledge to manage workforce safety & security with mobile e-learning</p>
                </div>
                <div className="icon">
                  <span>90%</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* ----------------section3------------------ */}
      <section>
        <div className="product-sec-D">
          <div className="container">
            <div className="heading">
              <h2>enterprise grade solution</h2>

            </div>
            <div className="flex">
              <div className="col1">
                <div className="item">
                  <img src={product1} alt="product1" className='img' />
                  <p>scalable across enterprise and entire extended ecosystem </p>
                  <div className="icon">
                    <img src={icon1} alt="product1" />
                    <img src={icons1} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product2} alt="product1" className='img' />
                  <p>micro-services-based architecture deployed on state of art cloud infrastructure. </p>
                  <div className="icon">
                    <img src={icon2} alt="product1" />
                    <img src={icons2} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product3} alt="product1" className='img' />
                  <p>gdpr compliant</p>
                  <div className="icon">
                    <img src={icon3} alt="product1" />
                    <img src={icons3} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product4} alt="product1" className='img' />
                  <p>oauth2.0 token based user authentication</p>
                  <div className="icon">
                    <img src={icon4} alt="product1" />
                    <img src={icons4} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product5} alt="product1" className='img' />
                  <p>full data encryption even at transit </p>
                  <div className="icon">
                    <img src={icon5} alt="product1" />
                    <img src={icons5} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product6} alt="product1" className='img' />
                  <p>3rd party security audit certification</p>
                  <div className="icon">
                    <img src={icon6} alt="product1" />
                    <img src={icons6} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product7} alt="product1" className='img' />
                  <p>client data stored only on client instance.</p>
                  <div className="icon">
                    <img src={icon7} alt="product1" />
                    <img src={icons7} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product8} alt="product1" className='img' />
                  <p>dmr protection enabled.</p>
                  <div className="icon">
                    <img src={icon8} alt="product1" />
                    <img src={icons8} alt="" className='white' />
                  </div>
                </div>
              </div>
              <div className="col1">
                <div className="item">
                  <img src={product9} alt="product1" className='img' />
                  <p>safe place to learn.</p>
                  <div className="icon">
                    <img src={icon9} alt="product1" />
                    <img src={icons9} alt="" className='white' />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="pathbreak-sec-B product-sec-6">
          <div className="container">
            <div className="flex">
              <div className="col1">
                <div className="img">
                  <img src={proposition} alt="byteedge" />
                </div>
              </div>
              <div className="col2">
                <h4 className="mb-3 text-black">unmatched value proposition</h4>
                <ul className="bullet_points">
                  <li>ai powered “text to video” solution at low cost for wide content transformation</li>
                  <li>calibrate workforce effectiveness and alignment</li>
                  <li>conduct polls and pulse surveys for an engaged workforce</li>
                  <li>measure, inspect, analyse everything in real time</li>
                  <li>next day launch ready</li>
                  <li>dedicated customer success manager</li>
                  <li>pay-per-use commercial frameworks and models</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------------section2------------------ */}
      <section>
        <div className="prodouct-demo-video">
          <video loop muted autoPlay>
            <source src='https://cdn.jwplayer.com/videos/QhwyNoXr-RPZCi6Mj.mp4' />
          </video>
        </div>
      </section>
    </>
  )
}
