import React, { useEffect } from 'react'
import bella from '../video/Introduction-bella.mp4'
import selfgrow from '../images/solution/grow-self.jpg'
import members from '../images/solution/members.jpg'
import customers from '../images/solution/customers.jpg'
import stakeholders from '../images/solution/stakeholders.jpg'
import manager from '../images/solution/manager.jpg'
import OwlCarousel from 'react-owl-carousel'
import { Helmet } from 'react-helmet'

export default function Communication() {
    useEffect(()=>{
        document.querySelector('meta[name="description"]').setAttribute('content', 'Elevate your communication game with Bella, our innovative platform designed to help you express yourself better and foster deeper connections. Explore Bella now!')
    })
    const slider = {
        loop: true,
        items: 3,
        center: true,
        dots: true,
        nav: false,
        mouseDrag: true,
        // autoplay: true,
        autoplayTimeout: 4000,
        smartSpeed: 800,
        responsive: {
            0: {
                items: 1,
            },
            767: {
                items: 1,
            },
            991: {
                items: 1,
            },
            1024: {
                items: 3
            }
        }
    }
    return (
        <>
            <Helmet>
                <title>Enhance Communication and Engagement with Bella</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="Communication, engagement, Bella, express better, connection, innovative platform " />
                <meta property="og:title" content='Enhance Communication and Engagement with Bella' />
                <meta property="og:description" content='Elevate your communication game with Bella, our innovative platform designed to help you express yourself better and foster deeper connections. Explore Bella now!' />
                <meta property="og:url" content='https://byteedge.com/workplace-communication' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Enhance Communication and Engagement with Bella' />
                <meta name="twitter:description" content='Elevate your communication game with Bella, our innovative platform designed to help you express yourself better and foster deeper connections. Explore Bella now!' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/workplace-communication" />
            </Helmet>
            <section>
                <div className='communc-sec-A'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <h3>high velocity “how-to” content for improved workplace communication at the point of need</h3>
                                <ul className='bullet_points'>
                                    <li>express better</li>
                                    <li>communicate better</li>
                                    <li>perform better</li>
                                    <li>demand better</li>
                                    <li>collaborate better</li>
                                    <li>grow better</li>
                                </ul>
                            </div>
                            <div className='col'>
                                <video controls>
                                    <source src="https://cdn.jwplayer.com/videos/W2vVEZm7-ezgnzKqf.mp4"></source>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='communc-sec-B'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>key themes</h2>
                        </div>
                        <div className='flex'>
                            <div className='col'>
                                <div className='img'>
                                    <img src={selfgrow}  alt='selfgrow'/>
                                </div>
                                <p>express better to grow self</p>
                            </div>
                            <div className='col'>
                                <div className='img'>
                                    <img src={members}  alt='members'/>
                                </div>
                                <p>express better with team members</p>
                            </div>
                            <div className='col'>
                                <div className='img'>
                                    <img src={customers} alt='byteedge'/>
                                </div>
                                <p>express better with customers</p>
                            </div>
                            <div className='col'>
                                <div className='img'>
                                    <img src={stakeholders} alt='byteedge'/>
                                </div>
                                <p>express better with key stakeholders</p>
                            </div>
                            <div className='col'>
                                <div className='img'>
                                    <img src={manager} alt='byteedge'/>
                                </div>
                                <p>express better with your manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='communc-sec-C'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>unleash your potential</h2>
                        </div>
                        <div className='flex'>
                            <div className='col'>
                                <div className='item'>
                                    <p>curated 200+ common everyday workplace situations</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>just-in-time application at point of need.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>prescriptive and pragmatic real-life content for use</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>mobile content format, reel style delivery for the workforce on the go</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='communc-sec-D'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>unique solutions to address workplace communication challenges </h2>
                        </div>
                        <OwlCarousel className='uniqueslider'
                            {...slider}
                        >
                            <div className='item'>
                                <p>each course is 5 mins duration</p>
                            </div>
                            <div className='item item2'>
                                <p>real workplace business situations</p>
                            </div>
                            <div className='item item3'>
                                <p>things to keep in mind for framing conversation </p>
                            </div>
                            <div className='item item4'>
                                <p>scenario based learning</p>
                            </div>
                            <div className='item item5'>
                                <p>prescriptive verified “what to say” content</p>
                            </div>
                            <div className='item item6'>
                                <p>learn “ what and how to say” with bella</p>
                            </div>
                            <div className='item item7'>
                                <p>practice, score and track progress</p>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    )
}
