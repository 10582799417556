import React from 'react'

export default function Clients(props) {
    return (
        <>
            <div className="single-testimonial">
                <p>{props.quote}</p>

                <div className="client-details">
                    <div className='client_img mb-2'>
                        <img src={props.image} alt='byteedge'></img>
                    </div>
                    <h6>{props.name}</h6>
                    <span>{props.desgination}</span>
                </div>
            </div>
        </>
    )
}

