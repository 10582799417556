import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-D51RX0KZ6T', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
};

export default useAnalytics;
