import React, { useState } from 'react'
import contact from '../images/contact.jpg'
import location from '../icons/location_icon.gif'
import { Helmet } from 'react-helmet'


export default function Contact() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, SetSubject] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false)

    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleSubject = (e) => {
        SetSubject(e.target.value)
    }
    const handlePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleMessage = (e) => {
        setMessage(e.target.value)
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!name) {
            newErrors.name = "name is required";
            isValid = false;
        }
        if (!email) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            newErrors.email = "please enter valid email";
            isValid = false;
        }
        if (!subject) {
            newErrors.subject = "subject is required";
            isValid = false;
        }
        if (!phone) {
            newErrors.phone = "phone is required";
            isValid = false;
        } else if (isNaN(phone)) {
            newErrors.phone = "please enter numbers only";
            isValid = false;
        } else if (phone.length < 10) {
            newErrors.phone = "please enter 10 digit number"
            isValid = false;
        }

        if (!message) {
            newErrors.message = "message is required";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };
    function handleApi(event) {
        event.preventDefault()
        if (validateForm() === false) {
            setSubmitted(true);
            return true
        }
        const formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("message", message);
        formdata.append("subject", subject);
        formdata.append("phone", phone);
        const requestOptions = {
            method: "POST",
            body: formdata,
        };
        const responseDiv = document.getElementById('contact-message');
        fetch("https://backend.byteedgelearning.com/contactapi.php", requestOptions)
            .then((response) => response.text())

            .then((result) => {
                result = JSON.parse(result)
                if (result.message) {
                    responseDiv.innerText = result.message
                    responseDiv.classList.remove("error");
                    setName('')
                    setEmail('')
                    SetSubject('')
                    setPhone('')
                    setMessage('')
                } else {
                    responseDiv.innerText = result.error
                    responseDiv.classList.add("error");
                }
            })
            .catch((error) => console.error(error));
    }
    // const handleApi =()=>{
    //     console.log(name,email,subject,phone,message)
    //     axios.post('https://backend.byteedgelearning.com/contactapi.php',{
    //         name: name,
    //         email: email,
    //         subject:subject,
    //         phone: phone,
    //         message: message,
    //     })
    //     .then(result=>{
    //         console.log(result.data, 'data successfully')
    //     })
    //     .catch(err=>{
    //         console.log(err)
    //     })
    // }
    return (
        <div>
            <Helmet>
                <title>Contact Us | Get in Touch with byteEDGE </title>
                <meta charset="utf-8" />
                <meta name="description" content="Reach out to byteEDGE's dedicated team for any inquiries or assistance. We're here to support your organization's learning journey and answer your questions." />
                <meta name="keywords" content="byteEDGE, contact us, support, learning journey, inquiries, assistance" />
                <meta property="og:title" content='Contact Us | Get in Touch with byteEDGE' />
                <meta property="og:description" content="Reach out to byteEDGE's dedicated team for any inquiries or assistance. We're here to support your organization's learning journey and answer your questions. " />
                <meta property="og:url" content='https://byteedge.com/contact-us' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Contact Us | Get in Touch with byteEDGE' />
                <meta name="twitter:description" content="Reach out to byteEDGE's dedicated team for any inquiries or assistance. We're here to support your organization's learning journey and answer your questions." />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/contact-us" />
            </Helmet>

            <section>
                <div className='contact-sec'>
                    <div className='container'>
                        <div className="heading">
                            <h2><span className="text-darkpink">get in touch</span> with us</h2>
                            <p>please fill the form to request a callback and someone will get back to you
                                shortly. thank you!</p>
                        </div>
                        <div className='contact-form'>
                            <div className='img_cnt'>
                                <img src={contact} alt='' />
                            </div>
                            <form>
                                <div className="flex">
                                    <div className="col">
                                        <div className="back-input">
                                            <label>name</label>
                                            <input id="name" type="text" name="name" className='frm-cntrl' value={name} onChange={handleName} />
                                            {errors.name && <div className="error">{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="back-input">
                                            <label>organization email</label>
                                            <input id="email" type="email" name="email" className='frm-cntrl' value={email} onChange={handleEmail} />
                                            {errors.email && <div className="error">{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="back-input">
                                            <label>context</label>
                                            <input id="subject" type="text" name="subject" className='frm-cntrl' value={subject} onChange={handleSubject} />
                                            {errors.subject && <div className="error">{errors.subject}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="back-input">
                                            <label>mobile</label>
                                            <input id="phone" type="text" name="phone" className='frm-cntrl' value={phone} onChange={handlePhone} />
                                            {errors.phone && <div className="error">{errors.phone}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="back-textarea">
                                            <label>type message here....</label>
                                            <textarea id="message" name="message" className='frm-cntrl' rows={5} value={message} onChange={handleMessage}></textarea>
                                            {errors.message && <div className="error">{errors.message}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <button className="demo-link" onClick={handleApi}><a href="#"><span>submit</span></a></button>
                                    </div>
                                </div>
                                <div id="contact-message"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='office-sec'>
                    <div className='container'>
                        <div className="heading">
                            <h2>office <span className="text-darkpink">locations</span></h2>
                        </div>
                        <div className='flex'>
                            <div className="col">
                                <div className="offer_card text-center">
                                    <img src={location} alt="icon" className="mb-3" />
                                    <h4 className='mb-2'>singapore office</h4>
                                    <p>byteEDGE learning Singapore pvt. ltd.</p>
                                    <p> 30 cecil street, #19-08 prudential tower, Singapore</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="offer_card text-center">
                                    <img src={location} alt="icon" className="mb-3" />
                                    <h4 className='mb-2'>india office</h4>
                                    <p>byteEDGE learning pvt. ltd.</p>
                                    <p>91 Springboard - 5th Floor, Building Number 145 Sector 44, Gurugram, Haryana 122003. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
