import React from 'react'
import { UseBlogs } from '../hooks/UseBlog';
import {
    Link
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import loader from '../images/loader.gif'

export default function Blog() {
    const { loading, error, data } = UseBlogs()
    if (loading) return <div className='text-center py-5 show cs-loader'>
        <img src={loader} alt='loader' />
        <div className="waviy">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
        </div>
    </div>
    if (error) {
        console.log(error.message)
    }
    // console.log(data)
    return (
        <>
            <Helmet>
                <title>blogs on learning and development trends</title>
                <meta charset="utf-8" />
                <meta name='description' content="stay updated with byteedge's insightful blogs on learning and development trends, best practices, and industry news. gain valuable knowledge to fuel your organization's growth." />
                <meta name="keywords" content="Learning and development, L&D Trends, Best practices in upskilling, Skilling Industry news, Learning Insights, Employee Growth, Organization development " />
                <meta property="og:title" content='blogs on learning and development trends' />
                <meta property="og:description" content="stay updated with byteedge's insightful blogs on learning and development trends, best practices, and industry news. gain valuable knowledge to fuel your organization's growth." />
                <meta property="og:url" content='https://byteedge.com/' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='blogs on learning and development trends' />
                <meta name="twitter:description" content="stay updated with byteedge's insightful blogs on learning and development trends, best practices, and industry news. gain valuable knowledge to fuel your organization's growth. " />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/blogs" />
            </Helmet>

            <section>
                <div className="blog">
                    <div className="container">
                        <div className="row mb-5">
                            {data && data.posts.edges.map(element => {
                                var str = element.node.date;
                                var PDate = str.split("T");
                                return <div className="col-md-4" key={element.id}>
                                    <div className="blog-grid mb-5">
                                        <div className="blog-img">
                                            <div className="date">{PDate[0]}</div>
                                            <Link to={`/blogs/${element.node.slug}`} className='img'>
                                                <img src={element.node.featuredImage.node.sourceUrl} alt="downlaod-app" />
                                            </Link>
                                        </div>
                                        <div className="blog-info">
                                            <h5 className='mb-3'>{element.node.title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: element.node.excerpt }} />
                                            <Link to={`/blogs/${element.node.slug}`}>
                                                <button className='btn btn-sm btn-primary border-0 mt-3'>read more <i className='fa-solid fa-arrow-right'></i></button></Link>
                                        </div>
                                    </div>
                                </div>
                            })}
                            {data.posts.pageInfo.hasPreviousPage && (
                                <div className=''>Load more....</div>
                            )}

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
