import React, { useEffect } from 'react'
import management from '../images/product/management.jpg'
import analytics from '../images/product/real-time-data.jpg'
import { Helmet } from 'react-helmet'

export default function Management() {
  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', 'Containing the power of both an LMS and an LXP, byteEDGE makes learning and knowledge transferring seamless.')
  })
  return (
    <>
      <Helmet>
        <title>Learning Management System| LXP </title>
        <meta charset="utf-8" />
        <meta name="keywords" content="Learning Management System, LXP, learning, knowledge transfer, seamless" />
        <meta property="og:title" content='Learning Management System| LXP' />
        <meta property="og:description" content='Containing the power of both an LMS and an LXP, byteEDGE makes learning and knowledge transferring seamless.' />
        <meta property="og:url" content='https://byteedge.com/management-system' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content='Learning Management System| LXP' />
        <meta name="twitter:description" content=' Containing the power of both an LMS and an LXP, byteEDGE makes learning and knowledge transferring seamless.' />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://byteedge.com/management-system" />
      </Helmet>
      {/* <div className="header-space"></div> */}
      <section>
        <div className="managment-sec-A">
          <video loop controls playsInline>
            <source src='https://content.jwplatform.com/videos/L3OfKNzp-VXoqnFRg.mp4' type="video/mp4" />
          </video>
        </div>
      </section>
      <section>
        <div className="management-sec-B">
          <div className="container">
            <div className="flex">
              <div className="col1">
                <div className="content">
                  <h3>enterprise grade scalability, engagement and management</h3>
                  <p>our management system delivers an unrivalled learning experience. we’ve included the best parts of a modern consumer driven experience that everyone will find familiar and intuitive. our focus is to deliver knowledge content, information and engagement for your people and ecosystem development. whatever your modern workforce consumes, we can track it and deliver it seamlessly, and all this is underpinned with detailed analytics and reporting. </p>
                </div>
              </div>
              <div className="col2">
                <div className="img">
                  <img src={management} alt="byteedge" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="management-sec-C">
          <div className="container">
            <div className="flex">
              <div className="item">
                <p>next day launch ready </p>
              </div>
              <div className="item">
                <p>enterprise grade scalability and security</p>
              </div>
              <div className="item">
                <p>inspect, measure & report everything</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="management-sec-D">
          <div className="container">
            <div className="content">
              <h3>develop your people and ecosystem with confidence</h3>
              <ul className="bullet_points">

                <li>full-fledged modern content management system for deep knowledge classification</li>
                <li>global content delivery network with best in class embedded players </li>
                <li>personas and proficiency based hyper personalization</li>
                <li>gamified learning with rewards, recognition, ranking, leaderboard and more</li>
                <li>drm based ip protection</li>
                <li>latest user token based multi factor authentication</li>
                <li>gdpr compliant</li>
                <li>full data encryption and protection</li>
                <li>auto nudging and notifications</li>
              </ul>
            </div>

          </div>
        </div>
      </section>
      <section>
        <div className="management-sec-E">
          <div className="container">
            <div className="heading">
              <h2>rich functionality and features of learning management system</h2>
            </div>
            <div className="flex">
              <div className="item">
                <h4>manage knowledge & learning</h4>
                <ul className="bullet_points">
                  <li>skill and category management </li>
                  <li>virtual and physical event scheduling, attendance with feedback</li>
                  <li>journey – 20+ curated + custom journey + custom courses</li>
                  <li>blended journey - online courses+ ilt+vilt+quiz</li>
                  <li>custom courses - upload video chapters, youtube, vimeo, tedx, & more</li>
                  <li>question banks with random quiz</li>
                  <li>live session streaming</li>
                </ul>
              </div>
              <div className="item">
                <h4>drive user adoption & engagement</h4>
                <ul className="bullet_points">
                  <li>token based OAuth2.0 based authentication</li>
                  <li>workforce effectivenes calibration assessment</li>
                  <li>pulse surveys & polls</li>
                  <li>gamification, quizzes, and more</li>
                  <li>experience karma points for rewards and motivation</li>
                  <li>digital perpetual certificates </li>
                  <li>auto nudging tech</li>
                  <li>offline video players - downloaded courses.</li>
                </ul>
              </div>
              <div className="item">
                <h4>measure everything - data visualization & analytics </h4>
                <ul className="bullet_points">
                  <li>dashboard and user filter like zone/location/ department/ manager/ division/ dealers etc </li>
                  <li>course based reports</li>
                  <li>journey reports</li>
                  <li>assignment report</li>
                  <li>roles based user profile - admin, regular, instructors, coaches, mentors. </li>
                  <li>fix support / tickets</li>
                  <li>user consumption trends</li>
                  <li>user reviews & ratings</li>
                  <li>course assignment - by user/ group from manager/ admin </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="pathbreak-sec-B">
          <div class="container">
            <div class="flex">
              <div class="col1">
                <div class="img">
                  <img src={analytics} alt="byteedge" />
                </div>
              </div>
              <div class="col2">
                <h4 class="mb-3 text-white">measure everything - data analytics in real time</h4>
                <ul class="bullet_points">
                  <li>video delivery & analytic capabilities</li>
                  <li>user analytics in real time – measure everything</li>
                  <li>learning agility and analytics report</li>
                  <li>engagement analytics</li>
                  <li>value realization and roi based real time dashboards</li>
                  <li>key trends, consumption preferences and more</li>
                  <li>social & community activities</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
