import React from 'react'
import download from '../images/download-banner.jpg'
export default function Termsbusiness() {
    return (
        <>
            <div className='download-banner'>
                <div className='bg'>
                    <img src={download} alt='byteedge'></img>
                </div>
                <div className='content'>
                    <h2>standard terms of business </h2>
                </div>
            </div>
            <section>
                <div className='privacy-sec-A'>
                    <div className='container'>
                        <div className='content'>
                            <h2>right to access and limitations on use</h2>
                            <p>to gain access to and use the services, the customer will be required to register and create an account on the
                                byteedge platform (the <b>“app”</b> or the <b>“platform”</b>) by providing the requested details requested therein
                                and agreeing to the terms of use of the platform.</p>
                            <p>further each user designated by the customer that shall, or be permitted to, use the services on the platform
                                subscribed by the customer (<b>“end -user”</b>) shall also be required to register on the platform by providing the
                                details requested therein and agreeing to the terms of use, community guidelines and privacy policy for the platform
                                applicable to the end-user.</p>
                            <p>the customer shall not misuse any information and materials provided on the byteedge platform and shall use such
                                information and materials solely for availing the services in accordance with this agreement. the customer shall
                                not, and shall procure that the end-user does not:
                            </p>
                            <ul className='bullet_points'>
                                <li>use the services or platform for any purpose other than as described in the order form, or as otherwise
                                    expressly permitted by byteedge-india</li>
                                <li>license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make
                                    available to any third party the services or contents thereof in any way</li>
                                <li>modify or reverse engineer the services or contents thereof or make derivative works based on the services or
                                    platform.
                                </li>
                            </ul>
                            <h2>fees, invoicing and payment terms</h2>
                            <p>byteedge-india shall raise invoices for the services provided in accordance with the order form and the customer shall pay the fees and expenses specified in invoices in accordance with the order form (“subscription fee”). all out-of-pocket expenses, cess and taxes (such as gst) shall be payable by the customer over and above the subscription fee and shall be separately invoiced. </p>
                            <p>the subscription fee and the payment thereof shall be governed by the terms of this agreement and the order form.</p>
                            <p>unless otherwise agreed in the order form, byteedge-india shall raise quarterly invoices for the subscription fees (along with taxes) in advance of the provision of the services to the customer and such subscription fee shall be payable within prior to the commencement of each such quarter . the customer acknowledges and understands that byteedge-india may refuse the services unless the entire value of the accrued subscription fee is received by byteedge-india. all amounts received by byteedge-india pursuant to this clause 2 shall be non-refundable. if customer designates another entity to receive invoices on its behalf, the customer shall bear the cost of any tax leakage that may arise to byteedge-india on account of such change and shall remain liable to byteedge-india for the timely payment of the said invoice. </p>
                            <p>any out-of-pocket expenses incurred by byteedge-india shall be invoiced to, and payable by, the customer at the end of the term of the services, or at such intervals as may be mutually agreed between the parties.</p><p>
                            </p><p>if there is a dispute regarding an invoice, the customer must notify byteedge-india in writing within 5 (five) days of receipt of the invoice. the parties agree to cooperate in good faith to resolve any dispute in relation to an invoice. if the parties are unable to resolve the dispute within 30 (thirty) days of the customer notifying byteedge-india of such dispute, the dispute shall be settled in accordance with clause 12.1 (governing law and dispute resolution), provided that the pendency of any such dispute shall not affect or impact the provision of any ongoing services between byteedge-india and the customer.</p>
                            <p>if any usage report or audit reveals that customer’s use of the services or platform exceeds the number of permitted end-users as mentioned in the order form, then byteedge-india shall notify customer in writing of such excess (an “overage”). upon such notification </p>
                            <ul className="bullet_points"> <li>customer will promptly execute amendments or order forms necessary to memorialize such overage</li><li>pay additional subscription fees to byteedge-india, depending on the overage.</li></ul>

                            <h2>obligations of byteEDGE-india</h2>


                            <p>byteEDGE-india shall carry out its duties and obligations under this agreement in a timely and diligent manner with
                                expertise, the highest professional standards and ethical business practices.</p>
                            <p>byteEDGE-india shall enable the services in compliance with the order form and the agreed service standards including
                                in relation to up-time and maintenance specified therein. </p>
                            <p>byteEDGE-india shall ensure services in compliance with the applicable laws of the jurisdiction of its incorporation
                                and shall ensure that all content displayed on the platform is in compliance with applicable law.</p>
                            <p>byteEDGE-india shall obtain and maintain all licenses, registrations, approvals, permissions, sanctions, under
                                applicable laws, if any, necessary to perform the services.</p>
                            <p>during the term of this agreement, byteEDGE-india shall disclose all material and relevant information, which may
                                either affect byteEDGE-india’s engagement with the customer currently or in the future or may be in conflict with
                                the terms of this agreement, either directly or indirectly.</p>

                            <h2>obligations of the customer</h2>


                            <p>the customer shall provide byteEDGE-india a list of end-users that require access to the services and the platform
                                and shall from time to time update this list of end -users and enable byteEDGE-india to and shall co-operate with
                                byteEDGE-india in, tracking the usage of the platform by the end-users.</p>
                            <p>the customer shall, and shallprocure that the end-users, shall access/use the services and the platform in compliance
                                with all applicable laws in the jurisdiction of such access/use.</p>
                            <p>the customer shall conduct its own analysis on the compliance of the service and platform and contents thereof with
                                the applicable laws of the jurisdiction in which the services and platform are used and/or accessed whether by the
                                customer or the end-users.</p>
                            <p>the customer covenants that it shall, and shall procure that the end-users shall:</p>

                            <ul className="bullet_points">
                                <li>not swap or share the login identifications or passwords (“logins”) used to access the services and associated
                                    with any end-user or the customer under any circumstance</li>

                                <li>not use the provided storage to store any information that is extraneous to or unnecessary for the operation or
                                    legitimate use of byteEDGE-india’s services(s) or in violation of the order form</li>
                                <li>not use the user-defined fields to store sensitive personally identifiable information</li>
                                <li>notify byteEDGE-india immediately of any unauthorized use of any logins used to access byteEDGE-india’s services
                                    or any other known or suspected breach of security
                                </li>
                                <li>report to byteEDGE-india immediately and use reasonable efforts to stop immediately any copying or distribution
                                    of content that is known or suspected by the customer to be infringing or unlawful</li>
                                <li>notify byteEDGE-india promptly if the permission to avail services set forth in an order form or this agreement
                                    is exceeded and promptly (i) execute any amendments or order form necessary to memorialize such excess; and (ii)
                                    pay subscription fees associated with such excess</li>

                                <li> not share any information, document or description, or use any of the content provided pursuant to the services
                                    to any act that is defamatory, obscene, offensive, abusive, objectionable, pornographic, invasive of another’s
                                    privacy including bodily privacy, insulting or harassing on the basis of gender, libelous, racially or
                                    ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with
                                    or contrary to the applicable laws or terms of use or community guidelines of the platform and services</li>

                                <li> not share any information which is harmful to a child</li>

                                <li> not infringe any patent, trademark, copyright or other proprietary rights of byteEDGE-india or
                                    byteEDGE-singapore and any of byteEDGE-singapore group companies in relation to the byteEDGE platform.
                                </li>
                                <li> not violate or procure the violation of any law, statue, ordinance or regulation applicable to the service,
                                    platform, or byteEDGE-india or byteEDGE-singapore or any of byteEDGE-singapore group companies, or the terms of
                                    this agreement or the terms of use of the platform and/or services;
                                </li>
                                <li> not deceive or mislead any user about the origin of the message or knowingly and intentionally communicates any
                                    information which is patently false or misleading in nature but may reasonably be perceived as a fact;
                                </li>
                                <li> not impersonate another person;
                                </li>
                                <li> not display or publish any information which is patently false and untrue, and is written or published in any
                                    form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury
                                    to any person;
                                </li>
                                <li> not display or publish any information which is false, inaccurate or misleading;</li>
                                <li> at any time, post or publish any comments or act in any way which will amount to harassment of any other user
                                    or customer of byteEDGE-india or byteEDGE-singapore or any of byteEDGE-singapore group companies</li>
                                <li> contain any viruses, trojan horses, worms, cancelbots or other computer programming routines that may damage,
                                    detrimentally interfere with, surreptitiously intercept, or expropriate any system, data or information.</li>
                            </ul>
                            <h2>representations and warranties</h2>
                            <p> each party represents and warrants to the other party that:</p>
                            <ul className="bullet_points">

                                <li>it is duly organized, validly existing and in good standing under the laws of the jurisdiction of its
                                    incorporation or registration</li>
                                <li>it has the corporate power and authority to execute and deliver this agreement and to perform its obligations
                                    under this agreement</li>
                                <li>this agreement constitutes a valid and binding agreement of such party, enforceable against it in accordance with
                                    its terms</li>
                                <li>performance under this agreement does not and will not materially conflict with or result in a breach of any
                                    provision of any other agreement by which it may be bound</li>
                                <li>it shall comply with all applicable laws in the performance of its obligations under this agreement.</li>
                            </ul>
                            <h2>term and termination</h2>
                            <p>this agreement shall commence on the effective date and will not expire unless terminated pursuant to the terms of
                                this agreement.</p>

                            <p>byteEDGE-india may terminate this agreement forthwith in the event the customer or any of the end-users fail to
                                comply with the terms of this agreement (including the obligations of the customer set out under clause 5 above), any
                                current and valid order form, or the terms of use displayed on the platform or any other conditions or terms governing
                                the use of the services or the platform or the contents thereof.</p>

                            <p>the customer may terminate this agreement forthwith in the event (i) byteEDGE-india fails to provide its services
                                in accordance with any current and valid order form(s); (ii) in the event of a downtime which exceeds the number of
                                permissible downtime hours mentioned in the current and valid order form.</p>

                            <p>notwithstanding anything to the contrary in agreement, customer will not have a right to terminate this agreement
                                at convenience during lock-in period.</p>

                            <p>notwithstanding anything to the contrary, this agreement may be terminated by mutual agreement of the parties in
                                writing.</p>

                            <p>consequences of termination: upon termination or expiry of this agreement:</p>
                            <ul className="bullet_points">
                                <li>byteEDGE-india shall, and shall procure that the end-users do, immediately cease the use of the platform and
                                    services</li>

                                <li>the customer and/or the end-users shall forthwith cease to use any trademarks, logos, trade names and any other
                                    intellectual property of byteEDGE-india as may be authorized pursuant to this agreement</li>

                                <li>the customer shall promptly return or destroy as per the instructions of byteEDGE-india, all the tangible and/or
                                    intangible confidential information and intellectual property of byteEDGE-india or byteEDGE-singapore or
                                    byteEDGE-singapore group companies in its possession or control</li>

                                <li>the expiration or termination of this agreement for any reason will not release parties from any liabilities or
                                    obligations set forth herein which (a) the parties have expressly agreed will survive any such expiration or
                                    termination; or (b) remain to be performed; or (c) by their nature are intended to survive expiry or termination of the
                                    agreement. further, the customer shall continue remain liable to byteEDGE-india for any outstanding payments (including
                                    any fees, out of pocket expenses, etc.) that remain payable to byteEDGE-india for the services rendered up till the
                                    termination of the agreement and/or the services.</li>
                            </ul>
                            <h2>intellectual property rights</h2>

                            <p>for the purpose of this agreement, “intellectual property” shall mean and include all patents, patentable rights,
                                copyright, registered and unregistered design rights, utility models, trademarks and service marks (whether or not
                                registered), software, videos, audio-visual content, music, recordings, trade names, domain names, rights in inventions,
                                rights in data, database rights, rights in know-how and confidential information, trade secrets and all other
                                intellectual and industrial property and similar or analogous rights existing under the laws of any country and all
                                pending applications for and rights to apply for any registration of the same (present, future and contingent, and
                                including all renewals, extensions and revivals of them) which shall be held by byteEDGE-india or byteEDGE-singapore or
                                any of byteEDGE-singapore group companies.</p>

                            <p>the customer hereby agrees that all intellectual property of byteEDGE-india or byteEDGE-singapore or any of
                                byteEDGE-singapore group companies existing on the effective date and as developed subsequently by it and shared with
                                the customer whether as a part of the services or on the platform shall remain the exclusive and absolute property of
                                byteEDGE-india or byteEDGE-singapore or any of byteEDGE-singapore group companies, as the case may be, and shall not be
                                used by the customer without prior written consent of byteEDGE-india. customer shall not have any rights over the
                                services, platform, or the content displayed thereon. subject to the payment of the subscription fees under the relevant
                                order form, the customer is only granted a non-exclusive, non-transferable and limited right to enjoy the services and
                                the byteEDGE-platform, strictly in accordance with this agreement and solely for the purposes specified in the order
                                form.</p>

                            <p>the customer shall immediately notify byteEDGE-india on obtaining knowledge of any actual or threatened:</p>
                            <ul className="bullet_points">
                                <li>
                                    infringement or misappropriation of the proprietary and intellectual property rights in the services, platform or a
                                    intellectual property of byteEDGE-india or byteEDGE-singapore or any of byteEDGE-singapore group companies, as the case
                                    may be,</li>
                                <li>proceedings regarding a claim of infringement or misappropriation of a third party’s proprietary and
                                    intellectual property rights by the services, platform or the customer’s intellectual property. the customer shall
                                    further provide all reasonable assistance to byteEDGE-india in enforcing and defending its rights under this agreement.</li>
                            </ul>
                            <p>this clause 7 shall survive termination or expiry of this agreement.</p>
                            <h2>indemnities and limitation of liability</h2>
                            <p>byteEDGE-india shall defend, indemnify and hold harmless the customer from and against any claims and direct losses
                                arising out of:</p>
                            <ul className="bullet_points">
                                <li>violation of applicable laws by byteEDGE-india in rendering the services</li>
                                <li>infringement of third party intellectual property rights by byteEDGE-india</li>
                                <li>gross negligence, fraud, willful misrepresentation by byteEDGE-india.</li>
                            </ul>

                            <p>in order to seek or receive indemnification pursuant to the provisions of this section 9.1, (i) byteEDGE-india
                                shall be notified promptly in writing by customer within 10 (ten) days of any claim of which it is aware for which
                                indemnification may be claimed; (ii) byteEDGE-india shall have the sole control of the defence of any such service claim
                                and of all negotiations for its settlement or compromise; and (iii) customer shall cooperate reasonably with
                                byteEDGE-india in the defense, settlement, or compromise of such service claim. byteEDGE-india will not be responsible
                                for the expenses, including attorney’s fees, of the customer incurred, but the customer may participate therein and
                                retain counsel at its own expense. byteEDGE-india will not be responsible for any settlement or compromise made by the
                                customer without byteEDGE-india’s written consent.</p>

                            <p>if customer’s use of byteEDGE-india’s services is enjoined, or if in byteEDGE-india’s sole judgment is likely to be
                                enjoined, byteEDGE-india may, at its sole option and expense, and as a complete remedy to customer, either </p>
                            <ul className="bullet_points">
                                <li>substitute equivalent non-infringing software for the infringing item</li><li>modify the infringing item so that it no
                                    longer infringes but remains functionally equivalent</li><li>obtain for customer the right to continue using such item</li><li>if byteEDGE-india deems none of the foregoing are commercially practicable, terminate the services, accept return of
                                        the infringing services, and grant the customer a pro-rated credit of the portion of the subscription fees collected by
                                        byteEDGE-india and attributable to the part of the services which were not rendered by byteEDGE-india to the customer.</li></ul>

                            <p>further, byteEDGE-india shall not have any liability to the customer under this section 8 to the extent that any
                                infringement or claim thereof is based upon </p>
                            <ul className="bullet_points">
                                <li> content and/or materials which does not belong to byteEDGE-india or
                                    byteEDGE-singapore or any of byteEDGE-singapore group companies, as the case may be,</li>
                                <li>use of byteEDGE-platform
                                    services in combination with any equipment or software not supplied hereunder where byteEDGE-india’s services would not
                                    otherwise be infringing</li>
                                <li>compliance with designs, plans, or specifications provided by the customer</li>
                                <li>use of
                                    byteEDGE-platform and any services in an application or environment for which it was not designed or not contemplated
                                    under this agreement</li>
                                <li>use of other than the most recent release of byteEDGE-platform provided to the customer</li>
                                <li>use of byteEDGE-india’s services in breach of this agreement; and/or (vii) any claims of infringement in which customer
                                    or any affiliate of has an interest or license in the property allegedly infringed upon by the applicable
                                    byteEDGE-india’s services.</li>
                            </ul>

                            <p> the customer shall indemnify, defend, and hold byteEDGE-india, its directors, partners, officers, employees,
                                agents, group companies and affiliates from and against any and all losses, damages, claims (including third party
                                claims), suits, actions, judgments, costs and expenses (including reasonable attorney’s fees) arising out of or in
                                connection with:</p>

                            <ul className="bullet_points">
                                <li>fraud, gross negligence or willful misconduct by the customer and/or any end-user;</li>
                                <li>a breach of the terms of this agreement including but not limited to breach of obligations of the customer,
                                    representations and warranties and breach of the terms relating to intellectual property, data protection and privacy
                                    and confidentiality provisions</li>
                                <li>claims arising out of or in connection with the use (including wrongful use) of the platform and/or the content
                                    thereon or projection or such content (which is not authorized under this agreement) by the customer and/or end-users or
                                    any of their representatives or any third party who gains access through the customer or end-users.</li>
                            </ul>

                            <p>notwithstanding any other provision of this agreement:</p>
                            <ul className="bullet_points">
                                <li>byteEDGE-india’s liability to the customer under this
                                    agreement, will be limited to the subscription fees paid by the customer to byteEDGE-india under the order form executed
                                    under this agreement</li>
                                <li>in no event will byteEDGE-india be liable to the customer under this agreement or under
                                    any theory of contract, tort or otherwise for any indirect, special, incidental, consequential, exemplary or punitive
                                    damages including but not limited to costs of procurement of substitute services, loss of goodwill or loss of business,
                                    even if byteEDGE-india has been advised of the possibility of such damages.</li>
                            </ul>

                            <p>notwithstanding any other provision of this agreement, byteEDGE-india will have no liability under this agreement
                                for any claims arising out of or in connection with: </p>
                            <ul className="bullet_points">
                                <li> use of the customer’s intellectual property by byteEDGE-india for the provision of services</li>
                                <li>use of the services or platform by the customer, end-users, or persons ostensibly acting for or through them in a manner not authorized by byteEDGE-india or against the instructions issued by
                                    byteEDGE-india or the provisions, terms and conditions of this agreement and/or the order form</li>
                                <li>use of the services in a manner and for a purpose that is unlawful or prohibited under this agreement and/or the order form.</li></ul>

                            <p>notwithstanding anything contained in this agreement the customer shall have perpetual and uncapped liability in
                                respect for all actions of the end-users and all persons acting on their behalf or who gain access to the services or
                                the platform through the customer or end-users that have an adverse impact on byteEDGE-india, the services or the
                                platform, irrespective of whether such actions was authorized by the customer and also irrespective of whether
                                byteEDGE-india can or is pursuing a claim against such person.</p>

                            <p>this clause 8 shall survive termination or expiry of this agreement.</p>

                            <h2>confidentiality</h2>

                            <p>for the purpose of this agreement, “confidential information” shall mean and include any information disclosed by
                                byteEDGE-india to the customer, in writing, orally, electronic or other form, including, without limitation, technical
                                and business information, intellectual property rights, software programs, reports, employee details, know-how,
                                financial information, pricing policies, marketing and product/ service development plans, personal information or any
                                other commercially valuable information. confidential information shall also include any non-public information or any
                                information which by its nature and in the circumstances shall be treated as confidential and proprietary by a
                                reasonable person. however, confidential information shall not include information that </p>
                            <ul className="bullet_points">
                                <li>is or becomes publicly available without any fault on part of byteEDGE-india </li>
                                <li> is lawfully acquired by byteEDGE-india from a third party without any corresponding confidentiality obligations</li>
                                <li> as can be evidenced by written records, is independently
                                    developed by byteEDGE-india without reference to any confidential information</li>
                                <li> as can be evidence by written records, was in possession of byteEDGE-india prior to disclosure of confidential information</li><li> is disclosed pursuant
                                    to the order of a court or governmental agency or where required by operation of law, only to the extent legally
                                    required, provided a prompt notification of such requirement is sent to byteEDGE-india to enable byteEDGE-india to
                                    obtain a protective order against such disclosure.</li>
                            </ul>

                            <p>the customer shall keep in strict confidence any and all the confidential information of byteEDGE-india and shall
                                not use the confidential information for any purpose other than for performing its obligations under this agreement. the
                                customer shall take all measures it uses for protecting its own confidential information, but in no event less than
                                reasonable measures.</p>

                            <p>the customer shall restrict disclosure of the confidential information solely to those of its employees, agents,
                                consultants and other professional advisors (collectively “representatives”) strictly on a need-to-know basis and who
                                are bound by confidentiality obligations no less restrictive than those contained in this agreement. the customer shall
                                remain responsible and liable for any unauthorized disclosure or use of confidential information by its representatives.</p>

                            <p>upon customer’s discovery of any unauthorized use or disclosure of confidential information, the customer shall
                                immediately without undue delay notify byteEDGE-india of such unauthorized use or disclosure and shall take such actions
                                as maybe required by byteEDGE-india.</p>

                            <p>the provisions of this clause 9 shall survive the termination of this agreement.</p>

                            <p>the customer shall not disclose the fact of execution of this agreement, or any other agreement, terms or documents
                                executed by it, or its relationship/engagement, with byteEDGE-india or byteEDGE-singapore or any of byteEDGE-singapore
                                group companies, or the terms thereof, to any third party without the prior written consent of the relevant byteEDGE
                                entity. both parties shall however free to make any press release or other public announcement regarding this
                                relationship/engagement.</p>
                            <h2>force majeure</h2>

                            <p>neither party shall be liable for any delay in performance any of its obligations under this agreement due to
                                causes beyond the reasonable control of the party invoking this provision, including but not limited to act of god,
                                government order, change in existing laws, acts of civil or military authorities, riots or civil disobedience, wars,
                                strikes, pandemic, epidemic, lockdown, man-made / natural calamity, outage of internet or telecommunication services or
                                labour disputes (each, a “force majeure event”).</p>

                            <p>within seven (7) days of occurrence of force majeure event, the affected party shall notify the other party. if
                                the force majeure event continues for more than 30 (thirty) days, byteEDGE-india may terminate this agreement.</p>
                            <h2> miscellaneous</h2>
                            <p>other terms and conditions – (i) this agreement and the services and the customer’s use of the platform and
                                services shall be governed in addition to this agreement by the terms of use, privacy policy, and other conditions as
                                may be updated and communicated to the customer from time to time; (ii) the provisions of annexure ii shall apply to
                                this agreement as if inserted in the appropriate place in the main agreement, and they shall not be construed as being
                                independent of this agreement.</p>

                            <p>assignment – no rights, privileges or obligations under this agreement may be assigned, transferred or
                                sub-contracted by the customer without the prior written consent of byteEDGE-india.</p>

                            <p>waiver – failure by either party to require performance of any provision of this agreement shall not affect such
                                party’s right to full performance thereof at any time thereafter. a waiver by either party of a breach of any provision
                                of this agreement shall not constitute a waiver of a similar or any other breach in the future. no waiver shall be
                                effective unless in writing and duly executed by an authorized representative of the relevant party.</p>

                            <p>reservation of rights – the rights of the parties under this agreement are independent of, and in addition to,
                                such other rights and remedies that the parties may have under law, equity or otherwise, including the right to seek
                                specific performance and injunctive relief.</p>

                            <p>modification – any waiver, amendment, replacement, modification or cancellation of any part of this agreement or
                                order form shall not be effective unless agreed in writing by both parties.</p>

                            <p>survival. the provisions of clause 6.5 (consequences of termination), clause 7 (intellectual property rights),
                                clause 8 (indemnification and limitation of liability), clause 9 (confidentiality), clause 11 (miscellaneous) of this
                                agreement and clause 12.2 (non-solicitation), clause 11.10 (notices), clause 12.1 (governing law and dispute
                                resolution), shall survive termination of this agreement or any order form.</p>

                            <p>severability – any provision of this agreement which is prohibited or held to be unenforceable in any jurisdiction
                                by a court or tribunal of competent jurisdiction, shall be ineffective to the extent of such prohibition or
                                unenforceability without invalidating the remaining provisions of this agreement. the prohibited or unenforceable
                                provision shall be substituted, by mutual consultation and agreement of the parties, with a provision that most closely
                                reflects the original intent of the parties to the extent permitted by applicable law.</p>

                            <p>entire agreement – subject to clause 11.1, this agreement together with the annexures, and order forms,
                                constitutes the entire understanding and agreement between the parties with respect to the subject matter hereof and
                                supersedes, replaces and terminates all prior understandings, negotiations, discussions, writings and agreements between
                                the parties, if any.</p>

                            <p>counterparts – this agreement may be executed in any number of counterparts, each of which shall be deemed an
                                original and enforceable against the parties, and all of which together shall constitute one and the same instrument.</p>

                            <p>notices – any notice or communication to be given under this agreement by either party to the other shall be in
                                writing, in english and delivered </p>
                            <ul className="bullet_points">
                                <li>personally</li>
                                <li>by registered mail, postage prepaid, return receipt requested</li>
                                <li>electronic mail; or (iv) by a commercial overnight courier that guarantees next day delivery and provides a
                                    receipt to the address mentioned in annexure ii or as may be provided by the parties.</li>
                            </ul>

                            <p>relationship between the parties – the parties agree that byteEDGE-india is an independent contractor with
                                respect to the services performed under this agreement. the parties agree that this agreement does not create a joint
                                venture or principal agent relationship between the parties. nothing in this agreement shall be construed as authorizing
                                either party to make any representations or create any binding obligations on behalf of the other.</p>

                            <p>feedback – if customer provides feedback, requests features, changes or tools, or otherwise provides comments
                                relating to the service or provides suggestions or ideas for improving the service (all the aforementioned referred to
                                as “feedback”), notwithstanding anything stated to the contrary in this agreement, the customer agrees that such
                                feedback will be fully assigned to byteEDGE-india without any obligation of service provider to pay separate
                                compensation, and byteEDGE-india shall have the title of, and own all rights to, such feedback and may thus use and
                                incorporate them into its product offerings.</p>

                            <p>further assurances. each party shall take all reasonable actions necessary to give effect to the terms of this
                                agreement and to ensure that it fulfils its obligations under this agreement. each party shall further provide all
                                reasonable assistance to the other party in enforcing and defending its rights under this agreement.</p>
                            <h2> jurisdiction specific terms </h2>

                            <p>governing law and dispute resolution- this agreement (and any dispute or claim relating to it, its enforceability,
                                or its termination) is to be governed by and construed in accordance with the laws of india. each of the parties agrees
                                that, if any dispute(s) or difference(s) shall arise between the parties in connection with or arising out of this
                                agreement, the parties shall attempt, for a period of 30 (thirty) days from the receipt of a notice from the other party
                                of the existence of a dispute(s), to settle such dispute(s) by mutual discussions between the parties. if the said
                                dispute(s) cannot be settled by mutual discussions within the thirty-day period provided above, either party may refer
                                the matter to a sole arbitrator to be mutually appointed in accordance with the arbitration and conciliation act, 1996.
                                the arbitration proceedings shall be held under the provisions of the arbitration and conciliation act, 1996. the
                                arbitration proceedings shall be held in english language at new delhi. the courts at new delhi shall have the exclusive
                                jurisdiction over any disputes relating to the subject matter of this agreement.</p>

                            <p>non-solicit – the customer shall not, without the prior written consent of byteEDGE-india, directly or indirectly
                                solicit, hire or contract with any employee of byteEDGE-india during the term of this agreement and for a period of 1
                                (one) year after the effective date of termination of this agreement. this clause shall not restrict the customer from
                                hiring or contracting with an employee of byteEDGE-india who responds to a public notice regarding a job opening posted
                                by it.</p>

                            <h3>data privacy </h3>

                            <p>each party shall at all times, ensure that it is in compliance with applicable data protection and privacy
                                regulations in the relevant jurisdictions, including singapore and india, in connection with its business and this
                                agreement.</p>

                            <p>byteEDGE-singapore and byteEDGE-india shall collect, store, access, use, disclose or otherwise process any personal
                                data strictly for the purpose of performing its obligations under this agreement and for its internal business
                                operations and ensure that it has appropriate data security arrangements in place to prevent unauthorized collection,
                                storage, access, use, disclosure or other processing of personal data.</p>

                            <p>each party shall promptly notify the other party of any information security breaches or incidents that could impact
                                the performance of either party’s obligations under this agreement or detrimentally impact any data or protocols of the
                                other party or the platform. the parties shall cooperate in good faith to jointly determine the corrective action
                                required to be taken in connection with such information security breach or incident.</p>

                            <p>each party shall be liable for any acts or omissions of its authorized representatives that result in a contravention
                                or breach of this clause.</p>

                            <p>byteEDGE-singapore and byte-edge india collects certain information to identify the customer and end-users such as
                                first name, last name, username, business address, corporate or personal email address, telephone numbers, interests and
                                preferences in receiving marketing from byteEDGE-india and byteEDGE-singapore and select third-parties, usage of
                                byteEDGE-india’s services.</p>

                            <p>the basis for collection of such information is:</p>


                            <ul className="bullet_points">
                                <li>laws, customer’s consent is sought at the time of collection of the information and such processing will be performed
                                    where consent is secured.</li>
                                <li>compliance with a legal obligation: the information collected may be processed by us, to the extent that such
                                    processing is necessary to comply with a legal obligation.</li>
                            </ul>

                            <p>the collected information shall be used to analyse trends, to conduct research, to administer the services, to learn
                                about learning patterns and movements around the services and products and to gather demographic information and usage
                                behaviour as a whole. further the collection and usage of all information/data is detailed in and shall be governed by
                                the company's published privacy policy on the platform.</p>

                            <p>byteEDGE-india may use the information so collected in compliance with applicable law in the jurisdictions in which it
                                and the customer operate to contact the customer from time to time, to provide the customer with the services, important
                                information, required notices and marketing promotions. further, the customer shall, at any time while using the
                                services, also have an option to withdraw its consent by providing a written notice to byteEDGE-india.</p>

                            <p>the contracts (rights of third parties) act (cap. 53b) of singapore shall not apply to this agreement and, unless
                                otherwise expressly provided herein, no person who is not a party to this agreement shall have or acquire any right to
                                enforce any term of this agreement pursuant to the contracts (rights of third parties) act (cap. 53b) of singapore.</p>						</div >

                    </div>
                </div>

            </section >
        </>
    )
}
