import { useQuery, gql } from '@apollo/client';


const SLUGLIST = gql`
query ($slug: ID!) {
    post(id: $slug, idType: SLUG) {
      id
      title
      date
      slug
      content
      tags {
        nodes {
          id
          name
          slug
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      author {
        node {
          name
        }
      }
      date
        
    }
  }`

  export const UseBlogdetails=(slug)=>{
    console.log(slug)
    const { loading, error, data } = useQuery(SLUGLIST,{
        variables: {
            slug,
        }
    })

    return{
        loading,
        error,
        data,
    };
  };
  