import React from 'react'

export default function Casestudypop(props) {
    return (
        <>
            <div class="casestudy_popup" id={props.caseid}>
                <div class="close_model"></div>
                <div class="case_detail">
                    <div class="caseimg">
                        <div class="name">
                            {props.title}
                        </div>
                    </div>
                    <div class="summary">
                        <ul className='bullet_points'>
                            <li><b>Business Challenge:</b>  {props.challenge}</li>
                            <li><b>Solution:</b>  {props.solution}</li>
                            <li><b>Business Benefit:</b>  {props.benefit}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
