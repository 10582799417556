import React from 'react'
import download from '../images/download-banner.jpg'
export default function Privacypolicy() {
    return (
        <div>
            <div className='download-banner'>
                <div className='bg'>
                    <img src={download} alt='byteedge'></img>
                </div>
                <div className='content'>
                    <h2>privacy policy</h2>
                </div>
            </div>
            <section>
                <div className='privacy-sec-A'>
                    <div className='container'>
                        <div className='content'>
                            <h2>introduction</h2>
                            <p><b>byteEDGE learning singapore pte ltd </b>and its subsidiaries and affiliates (“byteEDGE”/ “we” / “us” / “our”) respect your privacy and are committed to protecting your personal data.the term “you”/”your” is referred hereto as the data subject, as defined herein.</p>
                            <p>this privacy notice will inform you about how we look after your personal data when you visit our website (regardless of where you visit it from), handle your personal data in connection with this website, and tell you about your privacy rights and how the law protects you. </p>
                            <p>it also describes your choices regarding use, access and correction of your personal data.</p>
                            <h3>1. important information & who we are</h3>
                            <p>this privacy notice aims to give you information on how byteEDGE collects and processes your personal data through your use of this website.</p>
                            <p>this website is not intended for children and we do not knowingly collect data relating to children.</p>
                            <p>it is important that you read this privacy notice together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. this privacy notice supplements the other notices and is not intended to override them.</p>
                            <p>byteEDGE is the entity that is the controller of, and responsible for, the contact information we collect in connection with our marketing initiatives and employment opportunities that often originate with this website or our website backend.byteedgelearning.com.</p>
                            <p>we have appointed a data protection officer (“dpo”) who is responsible for overseeing questions in relation to this privacy notice. if you have any questions about this privacy notice or would like to exercise your rights in your personal data, please contact our dpo using the details set out below.</p>
                            <h3>2. byteEDGE business services</h3>
                            <p>your use of the products and services of byteEDGE business services is administered by your employer pursuant to a business-to-business (“b2b”) contract between byteEDGE and your employer.</p>
                            <p>it follows that your employer may have access to your personal information processed by a byteEDGE business service and that your employer may have its own privacy policy applicable to your use of byteEDGE business services.</p>
                            <p>in the context of these byteEDGE business services, byteEDGE - as a 'processor' of your personal data - is neither responsible for our b2b customers' policies and practices related to data privacy and security, nor in a position to respond to requests concerning personal data processed by byteEDGE business services.</p>
                            <p>instead, your employer - as the 'controller' of your personal data - administers your use of each byteEDGE business service. accordingly, please contact your employer with any privacy questions related to your use of that byteEDGE business service or your employer's privacy policy governing the same.</p>
                            <p>rest assured that there is an agreement between byteEDGE and its business customer, here, your employer. that agreement will, in every event, take precedence over conflicting terms in this privacy notice.</p>
                            <p>to further clarify the different roles with respect to you and your personal information as between, for example, you (i.e., a licensed end-user), your employer (i.e., byteEDGE business user) and byteEDGE, the following grid categorizes each entity or person according to its, his, or her role as articulated by the general data protection regulation 2016/679 (“gdpr”) of the european union (“eu”):</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <th colSpan={1}>data subject(s)</th>
                                        <th colSpan={1}>controller</th>
                                        <th colSpan={1}>processor</th>
                                        <th colSpan={3}>what governs the handling and use of the personal data of the data subject(s) as between controller and processor?</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={1}>licensed end-user</td>
                                        <td colSpan={1}>byteEDGE business user</td>
                                        <td colSpan={1}>byteEDGE</td>
                                        <td colSpan={3}>master services/license agreement together with a data protection addendum, if one exists.</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} align='center'>definitions under the gdpr</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={1}><b>data subject</b> means an identified or identifiable natural person.</td>
                                        <td colSpan={1}><b>controller</b> means the natural or legal person (i.e., corporation) which, alone or jointly with others, determines the purposes and means of the processing of personal data.</td>
                                        <td colSpan={1}><b>processor</b> means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.</td>
                                        <td colSpan={3}><b>personal data</b> means any information relating to a data subject who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that data subject.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>3. byteEDGE service to individual users</h3>
                            <p>if you are accessing byteEDGE services as an individual user, this privacy notice shall be applicable to you, mutatis mutandis.</p>
                            <h4>contact details</h4>
                            <p>please contact us: via e-mail at <a href='mailto:info@byteedge.com'>info@byteedge.com</a> with any general questions about our website.</p>
                            <h4>the full contact details of our data protection officer are:</h4>
                            <b>full name of legal entity:</b>
                            <p>byteEDGE learning services pvt ltd</p>
                            <p><b>title of dpo:</b> data protection officer</p>
                            <p>email address:  <a href='mailto:info@byteedge.com'>info@byteedge.com</a></p>
                            <h3>4. changes to this privacy notice and your duty to inform us of changes</h3>
                            <p>this version was last updated on 1stapril 2022. as you may already be aware, significant changes to the data protection laws in the eu, as well as the uk, went into effect on may 25, 2018, with similar changes having gone into effect for californian’s on january 1, 2020. this privacy notice outlines, or provides mechanisms for exercising, most of your rights under these legal frameworks including timelines for when you should expect to hear back from us.</p>
                            <p>we may update this privacy notice and its last updated date to reflect changes to our data governance practices. if we propose to make any material changes, we will notify you by means of a notice on this page prior to the change becoming effective. we encourage you to periodically review this page for the latest information on our privacy practices.</p>
                            <p>it is important that the personal data we hold about you is accurate and current. please keep us informed if your personal data changes during your relationship with us.</p>
                            <h3>5. third-party links</h3>
                            <p>this website may include links to third-party websites, plug-ins and applications. clicking on those links or enabling those connections may allow third parties to collect or share data about you. we do not control these third-party websites and are not responsible for their privacy statements. accordingly, you will hold us harmless in case such usage by a third party results in data breaches which is beyond the reasonable control of byteEDGE. when you leave our website, we encourage you to read the privacy notice of every website or websiteyou visit.</p>
                            <h3>6. the data we collect about you</h3>
                            <p>personal data, or personal information, means any information about an individual from which that person can be identified. we may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                            <ul className='bullet_points'>
                                <li><b>identity data</b> includes first name, last name, username or similar identifier, title, your employer.</li>
                                <li><b>contact data</b> includes business address, corporate or personal email address and telephone numbers.</li>
                                <li><b>technical data</b> includes internet protocol (ip) address, browser type and version time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</li>
                                <li><b>usage data</b> includes information about how you use our website, products and services.</li>
                                <li><b>marketing and profile data</b> data includes your interests and preferences in receiving marketing from us and select third parties, your communication preferences and survey responses.</li>
                                <li>as noted in section 2 (above), in the likely event your employer is administering your use of a byteEDGE business service, please contact your employer with any privacy questions related to the personal information processed in connection with your company’s license of that byteEDGE business service.</li>
                                <li>we also collect, use and share aggregated data such as statistical or demographic data for any purpose. aggregated data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. for example, we may aggregate your usage data to calculate the percentage of users accessing a specific website feature. however, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</li>
                                <li>we do not collect any special categories of personal data (categorised as sensitive or critical personal data under certain laws) about you. special categories of personal data include details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, financial data, information about your health and genetic and biometric data. nor do we collect any information about criminal convictions and offences.</li>
                            </ul>
                            <h3>7. how is your personal data collected?</h3>
                            <p>we use different methods to collect personal data from and about you including through:</p>
                            <ul className='bullet_points'>
                                <li><b>direct interactions: you may share with</b> us some of your identity and contact information by filling in forms or by corresponding with us by post, phone, email or otherwise. this includes personal data you provide when you: inquire about our products or services; register on our website, apply for a job opening; request marketing to be sent to you; enter a competition, promotion or survey; or give us some feedback.</li>
                                <li><b>automated technologies or interactions:</b> as you interact with our website, we may automatically collect technical information about your equipment, browsing actions and patterns. we collect this personal data by using cookies, server logs and other similar technologies.</li>
                                <li><b>third parties or publicly available sources:</b> we may receive analytics and advertising data about you from the sources listed below, as well as, other vendors and public sources. please use the following links for more information about how these third parties collect and use personal data: google – linkedin – salesforce.com etc.</li>
                            </ul>
                            <h3>8. how we use and process your personal data</h3>
                            <p>we will only use your personal data when the law allows us to. before undertaking any processing activity, the legal ground shall be identified and recorded. if we process any personal data without any legal basis or grounds as provided below, then such processing would be rectified to the extent possible and adequate measures would be taken to remedy any breach of processing norms as per the best and reasonable practices adopted by us.</p>
                            <p>we will use your personal data in the following circumstances, without consent:</p>
                            <ul className='bullet_points'>
                                <li>where we need to perform the contract we are about to enter into or have entered into with you.</li>
                                <li>where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                                <li>where it is required to protect your vital interest</li>
                                <li>where we need to comply with a legal or regulatory obligation.</li>
                            </ul>
                            <p>where we need to perform a task in public interest or in the exercise of official authority. the basis or grounds for processing may vary depending upon the jurisdictions and applicable law, byteEDGE would exercise discretion to examine such grounds as per the laws applicable in other countries/jurisdiction and thereafter process/use such personal data.</p>
                            <p>generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending our own or third party direct marketing communications to you via email, text message or in platform (“byteEDGE”) communications. you have the right to withdraw consent to marketing at any time by following the “unsubscribe” link in any marketing message sent to you or by writing to the dpo.</p>
                            <h3>purposes for which we will use your personal data</h3>
                            <p>we have set out below, in a table format, a description of all the ways we may use your personal data, and which of the legal bases we rely on to do so. we have also identified what our legitimate interests are where appropriate.</p>
                            <p>note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. these grounds are available under the eu gdpr, however, such grounds may vary (under certain other laws) and hence would be processed as per the applicability.</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b>purpose/activity</b></td>
                                        <td><b>type of datalawful basis for processing including basis of legitimate interest, as websitelicable</b></td>
                                        <td><b>lawful basis for processing including basis of legitimate interest, as websitelicable</b></td>
                                    </tr>
                                    <tr>
                                        <td>to register you as a new customer or contact</td>
                                        <td>
                                            <span>1. identity</span>
                                            <span>2. contact</span>
                                        </td>
                                        <td>performance of a contract with you</td>
                                    </tr>
                                    <tr>
                                        <td>to manage our relationship with you which will include:• notifying you about changes to our terms or privacy policy• asking you to leave a review or take a survey</td>
                                        <td>
                                            <span>1. identity</span>
                                            <span>2. contact</span>
                                            <span>3. marketing and profile</span>
                                        </td>
                                        <td>
                                            <span>1. performance of a contract with you</span>
                                            <span>2. necessary to comply with a legal obligation </span>
                                            <span>3. necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>to administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                                        <td>
                                            <span>1. identity</span>
                                            <span>2. contact</span>
                                            <span>3. technical</span>

                                        </td>
                                        <td>
                                            <span>necessary for our legitimate interests (for running our business, provision of administration and it services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</span>
                                            <span>necessary to comply with a legal obligation</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>to deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                                        <td>
                                            <span>1. identity</span>
                                            <span>2. contact</span>
                                            <span>3. usage</span>
                                            <span>4. marketing and profile</span>
                                            <span>5. technical</span>

                                        </td>
                                        <td>necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</td>
                                    </tr>
                                    <tr>
                                        <td>to use data analytics to improve our website, products/services, marketing, customer relationships and experiences</td>
                                        <td>
                                            <span>1. usage</span>
                                            <span>2. technical</span>
                                        </td>
                                        <td>necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)to improve your experience in furtherance of our legitimate interests (or where needed, with consent), including using automated systems that analyze data using machine learning and other analytic techniques</td>
                                    </tr>
                                    <tr>
                                        <td>to make suggestions and recommendations to you about goods or services that may be of interest to you</td>
                                        <td>
                                            <span>1. identity</span>
                                            <span>2. contact</span>
                                            <span>3. usage</span>
                                            <span>4. marketing and profile</span>
                                            <span>5. technical</span>
                                        </td>
                                        <td>necessary for our legitimate interests (to develop our products/services and grow our business)</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ul className='bullet_points mt-5' >
                                <li><b>marketing: </b> we strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. if you have any other questions about this privacy notice or would like to exercise your rights in your personal data (please see clause 10 below), please contact our dpo.</li>
                                <li><b>promotional offers: </b>we may use your identity, contact, technical, usage and profile data to form a view on what we think you may want or need, or what may be of interest to you. this is how we decide which products, services and offers may be relevant for you (we call this marketing).you will receive marketing communications from us if you have requested information from us or purchased products or services from us or if you provided us with your details when you entered a competition or registered for a promotion and, in each case, you have not opted-out of receiving that marketing.</li>
                                <li><b>third-party marketing: </b>we will get your express opt-in consent before we share your personal data with any company outside the byteEDGE family of companies for marketing purposes.</li>
                            </ul>
                            <h3>10. opting out</h3>
                            <p>you can ask us or third parties to stop sending you marketing messages at any time by following the “unsubscribe” links on any marketing message sent to you or by writing to the dpo. where you opt-out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>
                            <h3>11. cookies</h3>
                            <p>we use cookies across our website to improve performance and enhance the experience.</p>
                            <p>you can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. if you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.</p>
                            <p>usage of cookies: when consulting our website, cookies may get placed on your computer, mobile or tablet. cookies could contain the following data:</p>
                            <ul className='bullet_points'>
                                <li>information about the browser; an identifier, in the form of a unique identification number.</li>
                                <li>an expiration date (some cookies only)</li>
                            </ul>
                            <h3>12. change of purpose</h3>
                            <p>we will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. if we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
                            <h3>13. disclosures of your personal data</h3>
                            <p>we may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>
                            <ul className='bullet_points'>
                                <li>byteEDGE family of companies.</li>
                                <li>external third parties such as google, linkedin, salesforce.</li>
                                <li>third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. alternatively, we may seek to acquire other businesses or merge with them. if a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</li>
                                <li>we may also disclose your personal data as required by law, such as to comply with a subpoena or other legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</li>
                            </ul>
                            <p>we require all third parties to respect the security of your personal data and to treat it in accordance with the law. we do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
                            <h3>14. international transfers</h3>
                            <p>we share your personal data within the byteEDGE family of companies. this will involve transferring your data outside your country.</p>
                            <p>we ensure your personal data is protected by requiring all our group companies to follow the same rules when processing your personal data. these rules are binding company policies. many of our external third parties are based outside the european union, the united kingdom, and switzerland, so their processing of your personal data will necessarily involve a transfer of data outside the european union, the united kingdom, and switzerland. whenever we transfer your personal data out of the european union, the united kingdom, and switzerland, we ensure a similar degree of protection is afforded to it by using specific contracts approved by the european commission, which give personal data the same protection it has in the european union.</p>
                            <p>byteEDGE is responsible for the processing of personal data it receives and subsequently transfers to a third party acting as an agent on its behalf. byteEDGE complies applicable data protection laws for all onward transfers of personal data from the eu and the united kingdom and switzerland, including the onward transfer liability provisions of applicable data protection laws.</p>
                            <p>where personal data is transferred outside the eu (i.e. to a third country located outside of the eu), we ensure the necessary protection, data transfer mechanism, is in place for an adequate level of protection in the third country as per such country’s reasonable security standards. we shall only transfer personal data to a third country when the country ensures an adequate level of protection of the rights and freedoms of the data subject in relation to the processing of their personal data. with respect to personal data received or transferred pursuant to, among other data protection law, the gdpr. in certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
                            <h3>15. data security and data breach notification</h3>
                            <p>we have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. in addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. they will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                            <p>we will make your data accessible only to those who need to use it, and access will be granted only in line with the relevant company policies or procedures of byteEDGE. we will make best efforts to delete or dispose of your personal data that is not required in line with the applicable law and our company’s policies.</p>
                            <p>in case of breach related to any privacy aspects, we shall inform the authority as required under the applicable law, in the form of a notice, about the breach of any personal data processed by us where such breach is likely to cause harm to you.</p>
                            <p>the said notice shall include the following particulars:</p>
                            <ul className='bullet_points'>
                                <li>nature of personal data which is the subject-matter of the breach;</li>
                                <li>number of data subjects affected by the breach;</li>
                                <li>possible consequences of the breach; and</li>
                                <li>action being taken by us to remedy the breach.</li>
                            </ul>
                            <p>the said notice shall be made by us to the authority as soon as possible and within a specified time period, following the breach, after accounting for any period that may be required to adopt any urgent measures to remedy the breach or mitigate any immediate harm. where it is not possible to provide all the information at the same time, we shall provide such information to the authority in phases without undue delay.</p>
                            <p>you will hold us harmless in case there is a security breach due to no fault of ours or where such breach is pursuant to reasons beyond control of byteEDGE.</p>
                            <h3>16. data retention</h3>
                            <p>we will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                            <p>to determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
                            <p>in some circumstances you can ask us to delete your data, see, “request erasure” (below) for further information.</p>
                            <p>in some circumstances we may aggregate your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you. we will undertake periodic review to determine whether it is necessary to retain personal data in our possession.</p>
                            <h3>17. your rights in your personal data</h3>
                            <p>under certain circumstances, you have rights under data protection laws in relation to your personal data. if you wish to exercise any of these rights set out below, please contact our dpo and help us help you by specifying which right(s) outlined below is intended to be exercised by you:</p>
                            <ul className='bullet_points'>
                                <li><b>request access</b> to your personal data (commonly known as a “data subject access request”). this enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                                <li><b>request correction</b> of the personal data that we hold about you. this enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                                <li><b>request erasure</b> of your personal data. this enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. you also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                                <li><b>object</b> to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. you also have the right to object where we are processing your personal data for direct marketing purposes. in some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                                <li><b>request restriction</b> of processing of your personal data. this enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                                <li><b>request transfer</b> of your personal data to you or to a third party. we will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                            </ul>
                            <p><b>general condition for the exercise of the right: </b>you are required to make a request in writing to us for exercising the aforementioned rights. on receipt of such request, we will evaluate the request and decide upon accepting such request. post evaluation on whether such request can be accepted or not, we will communicate our acceptance or denial for legitimate reasons. byteEDGE’s decision would be based on the business feasibility and shall be final and binding upon you.</p>
                            <p>as noted elsewhere in this privacy notice, if you are using any byteEDGE business services, please contact your employer with any questions or requests related to your personal information.</p>
                            <p>it follows that we are only able to act on requests related to the personal information that we have collected through our website(s). if we cannot act on your request, we will explain the reason(s) why. for example, a request might impact the rights of another person or another company.</p>
                            <p><b>no fee usually required: </b>you will not have to pay a fee to access your personal data (or to exercise any of the other rights). however, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. alternatively, we may refuse to comply with your request for these or other legitimate reasons.</p>
                            <p><b>what we may need from you: </b>we may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). this is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. we may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                            <p><b>time limit to respond: </b>we try to respond to all legitimate requests within one month of our receiving them. occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests.</p>
                            <h3>18. glossary</h3>
                            <p><b>website</b> means this website and other applications owned by byteEDGE learning singapore pte ltd.</p>
                            <p><b>lawful basis</b> means the interest of our organization in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. we make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. we do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted by law).</p>
                            <p><b>performance of contract</b> means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
                            <p><b>comply with a legal or regulatory obligation</b> means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
