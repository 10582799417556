import React from 'react'
import { UseBlogdetails } from '../hooks/UseBloglist'
import { Navigate, useParams } from "react-router-dom";
import loader from '../images/loader.gif'

import Pagenotfound from './Pagenotfound';
export default function Blogdetails() {
    
    const { slug } = useParams()
    const { loading, error, data } = UseBlogdetails(slug)
  
    if (loading) return  <div className='text-center py-5 show cs-loader'>
    <img src={loader} />
    <div class="waviy">
        <span>L</span>
        <span>O</span>
        <span>A</span>
        <span>D</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
    </div>
</div>
    if (error) return <h1>something went wrong</h1>
    if(data.post == null){
      return (<Navigate replace to="/404"  />)

    }

    return (
      <>
        <div className="blog_detail_sec">
          <div className="blog-wrap">
            <img src={data.post.featuredImage.node.sourceUrl} alt="Breadcrumbs" />
            <div className="blog-inner">
              <div className="container">
                <div className="blog-text text-left">
                  <h2 className="blog-title">{data.post.title}</h2>
                  <ul className="user-section d-flex align-items-center">
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>{data.post.date}
                    </li>
                  </ul>
                </div>
              </div>
            </div> 
          </div>
          <div className="blog-detailcontent">
            <div className="container">
              <p dangerouslySetInnerHTML={{ __html: data.post.content }} />
            </div>
          </div>
        </div>
  
      </>
    )
}
