import React, { useState } from 'react'
import images from '../images/black-logo.png'
import iso from '../icons/organization.png'
import certified from '../file/ISO-Certificate.pdf'
import {
    Link
} from "react-router-dom"

export default function Footer() {
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false)
    const handleEmail = (e) => {
        setEmail(e.target.value)
    }
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!email) {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(email)) {
            newErrors.email = "please enter valid email";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };
    function handleApi(event) {
        event.preventDefault()
        if (validateForm() === false) {
            setSubmitted(true);
            return true
        }
        const formdata = new FormData();
        formdata.append("email", email);
        const requestOptions = {
            method: "POST",
            body: formdata,
        };

        const responseDiv = document.getElementById('newsletter-message');
        fetch("https://backend.byteedgelearning.com/newsletterapi.php", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                result = JSON.parse(result)
                if (result.message) {
                    responseDiv.innerText = result.message
                    responseDiv.classList.remove("error");
                    setEmail('')

                } else {
                    responseDiv.innerText = result.error
                    responseDiv.classList.add("error");
                }
            })
            .catch((error) => console.error(error));
    }
    return (
        <footer id="react-footer" className="react-footer">
            <div className="footer-top">
                <div className="container">
                    <div className="flex">
                        <div className="col">
                            <div className="footer-widget">
                                {/* <div className="footer-logo">
                                    <Link to="/" className="logo-text"> <img src={images} alt="logo" /></Link>
                                </div> */}
                                {/* <div className="flex reviews"> */}
                                    {/* <div className="col"> */}
                                        <div className="software-logo">
                                            <Link to="https://www.softwareadvice.com/lms/byteedge-profile/reviews/" target='_blank' rel="noreferrer"> <img border="0" src="https://brand-assets.softwareadvice.com/badge/449f2e17-9a1d-4f59-a73e-e6bfaee233f7.png"  /> </Link>
                                        </div>
                                    {/* </div> */}
                                    {/* <div className="col"> */}
                                        <div className="software-logo">
                                            <Link to="https://www.getapp.com/education-childcare-software/a/byteedge/reviews/" target='_blank' rel="noreferrer"> <img border="0" src="https://brand-assets.getapp.com/badge/3609dd82-8efe-4231-92ee-c2f9f0c90958.png"  /> </Link>

                                        </div>
                                    {/* </div> */}
                                    {/* <div className="col"> */}
                                        <div className="software-logo">
                                            <Link to="https://www.capterra.com/p/10016744/byteEDGE/reviews/" target='_blank' rel="noreferrer"> <img border="0" src="https://brand-assets.capterra.com/badge/f85dc330-afa6-4109-8119-0d381ac7cba2.svg"  /> </Link>
                                        </div>
                                    {/* </div> */}
                                {/* </div> */}

                               
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-widget footer-widget-2">
                                <h3 className="footer-title text-white">quick links</h3>
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/products">products</Link></li>
                                        <li><Link to="/solution">solutions</Link></li>
                                        <li><Link to="/solution">people</Link></li>
                                        <li><Link to="/contact-us">contact us</Link></li>
                                        <li><Link to="/download-our-app">downloads</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-widget footer-widget-3">
                                <h3 className="footer-title text-white">policies</h3>
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/privacy-policy">privacy policy</Link></li>
                                        <li><Link to="/terms-and-conditions">terms & conditions</Link></li>
                                        <li><Link to="/standard-terms-of-business">standard terms of business</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-widget footer-widget-4">
                                <h3 className="footer-title text-white">newsletter</h3>
                                <div className="footer3__form">
                                    <p>get the latest byteEDGE news delivered to your inbox</p>
                                    <form action="#">
                                        <div className='email'>
                                            <input type="email" placeholder="Enter your email" value={email} onChange={handleEmail} />
                                            <button className="footer3__form-1" onClick={handleApi}>
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </button>
                                        </div>
                                        {errors.email && <div className="error">{errors.email}</div>}
                                        <div id="newsletter-message"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="copyright">
                <div className="container">
                    <div className="flex">
                        <div className="col">
                            <span> © 2023 <Link to="/" className='byteedge'>byteEDGE.</Link> all rights reserved</span>

                        </div>
                        <div className='col'>
                            <Link to={certified} target='_blank' title="ISO Certified" className='isocerti'>
                                <img src={iso} alt="ISO Certification Badge" style={{ width: '30px' }} />
                                <span>ISO 27001 Certified</span>
                            </Link>
                        </div>
                        <div className="col">
                            <ul className="social-links">
                                <li className="follow mr-3">follow us :</li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/byteedge-learning/mycompany/" target='_blank' rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/byteedge_learning/" target='_blank' rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                        </svg>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="https://www.facebook.com/byteEDGE/" target='_blank' rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                        </svg>
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
