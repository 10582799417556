import React from 'react'

export default function Video() {
    return (
        <>
            <div className='video-sec'>
                <div className='container-fluid'>
                    <div className='flex'>
                        <div className='video-item'>
                            <div className='video-heading text-center'>
                                <h3>Solution Overview</h3>
                                <p>byteEDGE - AI powered Knowledge Transfer and Capability development Solution Intro - 2mins viewing time</p>
                            </div>
                            <video controls>
                                <source src='https://cdn.jwplayer.com/videos/AjNHMHVq-RPZCi6Mj.mp4'></source>
                            </video>
                        </div>
                        <div className='video-item'>
                            <div className='video-heading text-center'>
                                <h3 className='text-center'>Preview our Next Generation</h3>
                                <p>Pathbreaking Modern Mobile Consumer Grade Experience Platform for your modern workforce - 1min viewing time</p>
                            </div>
                            <video controls>
                                <source src='https://cdn.jwplayer.com/videos/QhwyNoXr-RPZCi6Mj.mp4'></source>
                            </video>
                        </div>
                        <div className='video-item'>
                            <div className='video-heading text-center'>
                                <h3 className='text-center'>Recorded Demo of our LMS</h3>
                                <p>New Age Knowledge Management Platform - Evolution beyond Legacy LMS-  5mins Platform Demo</p>
                            </div>
                            <video controls>
                                <source src='https://cdn.jwplayer.com/videos/gyRB79WU-RPZCi6Mj.mp4'></source>
                            </video>
                        </div>
                        <div className='video-item'>
                            <div className='video-heading text-center'>
                                <h3 className='text-center'>AI powered Video Creation with byteEDGE Demo</h3>
                                <p>ByteEDGE Latest Generation Meta Explaining how to create immersive videos</p>
                            </div>
                            <video controls>
                                <source src='https://cdn.jwplayer.com/videos/uJqsxoOK-RPZCi6Mj.mp4'></source>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
