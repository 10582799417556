import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper from "swiper";
import { EffectCoverflow, Pagination, Autoplay, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import OwlCarousel from 'react-owl-carousel'
import logo1 from '../images/logo/AD.png'
import logo2 from '../images/logo/GT.png'
import logo3 from '../images/logo/Pollinate-group.png'
import logo5 from '../images/logo/Seagull.png'
import logo6 from '../images/logo/Healthium.png'
import logo7 from '../images/logo/LT.png'
// import logo8 from '../images/logo/neulandlabs.png?v=11'
import logo9 from '../images/logo/dcm-logo.png'
import logo10 from '../images/logo/Sysnet.png'
import logo11 from '../images/logo/tatacommunication_white.png'
import logo12 from '../images/logo/ESAF.png'
import logo13 from '../images/logo/ARKA.png'
import logo14 from '../images/logo/honda-logo.png'
import logo15 from '../images/logo/JK-cement.png'
import logo16 from '../images/logo/Amazon-Logo.png'
import logo17 from '../images/logo/cocacola-logo.png'
import challenge1 from '../images/short-format-video.jpg'
import challenge2 from '../images/mobile-has-surpassed-desktop.jpg'
import challenge3 from '../images/no-time-to-read-texts.jpg'
import challenge4 from '../images/1.jpg'
import challenge5 from '../images/budget-drain.jpg'
import challenge6 from '../images/inability-to-calibrate.jpg'
import experience from '../icons/learning.png'
import management from '../icons/management.png'
import creation from '../icons/video-creation.png'
import analytics from '../icons/analytic.png'
import transform_sales from '../images/home/transform-sales-workforce.jpg'
import communication from '../images/home/communication.jpg'
import compliance from '../images/home/compliance.jpg'
import conflicts from '../images/home/conflicts.jpg'
import workforce from '../images/home/workfrorce.jpg'
import trainings from '../images/home/modern-trainings.jpg'
import listening from '../images/home/listening-workforce.jpg'
import partner1 from '../images/home/partners/iAccel_GBI.png'
import partner2 from '../images/home/partners/EmployeeWise.png'
import partner3 from '../images/home/partners/peer_consualting.png'
import casestudy1 from '../images/home/case-study1.jpg'
import casestudy2 from '../images/home/case-study2.jpg'
import casestudy3 from '../images/home/case-study3.jpg'
import casestudy4 from '../images/home/case-study4.jpg'
import casestudy5 from '../images/home/case-study5.jpg'
import casestudy6 from '../images/home/case-study6.jpg'
import casestudy7 from '../images/home/case-study7.jpg'
import casestudy8 from '../images/home/case-study8.jpg'
import casestudy9 from '../images/home/case-study9.jpg'
import casestudy10 from '../images/home/case-study10.jpg'
import casestudy11 from '../images/home/case-study11.jpg'
import casestudy12 from '../images/home/case-study12.jpg'
import Data from './Data'
import Partner from './Partner'
import Clients from './Clients'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Casestudy from './Casestudy';
import Casestudypop from './Casestudypop';



export default function Home() {
    const [testimonials, Settestimonials] = useState(null)
    // useEffect(() => {
    //     const url = 'https://lms.byteedgebiz.com/api/v2/testimonial'
    //     axios
    //         .get(url)
    //         .then((res) => {
    //             Settestimonials(res.data)
    //         })
    //     document.querySelector('meta[name="description"]').setAttribute('content', 'upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.')
    // }, [])
    useEffect(() => {
        const cachedData = localStorage.getItem('testimonials');
        if (cachedData) {
            setTimeout(() => {
                Settestimonials(JSON.parse(cachedData));
            }, 500);
           // console.log(JSON.parse(cachedData))
            // console.log('local')
           
        } else {
            const url = 'https://lms.byteedgebiz.com/api/v2/testimonial';
            axios.get(url)
                .then((res) => {
                    Settestimonials(res.data);
                    // console.log('server')
                    localStorage.setItem('testimonials', JSON.stringify(res.data));
                })
                .catch((error) => console.error('Error fetching testimonials:', error));
            }
              
        document.querySelector('meta[name="description"]').setAttribute('content', 'Upskill your workforce with AI text-to-video generation, CMS, Microlearning, organizational knowledge transfer capabilities.');
    }, []);
    const options = {
        loop: true,
        items: 1,
        margin: 70,
        dots: true,
        nav: false,
        mouseDrag: true,
        autoplay: false,
        autoplayTimeout: 4000,
        smartSpeed: 800,
        responsive: {
            0: {
                items: 1,
                margin: 0
            },
            767: {
                items: 1,
                margin: 0
            },
            991: {
                items: 1,
                margin: 0
            },
            1024: {
                items: 1
            }
        }

    }
    const homeslide = {
        loop: true,
        items: 3,
        // margin: 70,
        dots: true,
        nav: false,
        center: true,
        mouseDrag: true,
        autoplay: true,
        autoplayTimeout: 4000,
        smartSpeed: 800,
        responsive: {
            0: {
                items: 1,
                margin: 0
            },
            767: {
                items: 1,
                margin: 0
            },
            991: {
                items: 1,
                margin: 0
            },
            1024: {
                items: 3
            }
        }

    }
    const swiperRef = useRef(null);

    // useEffect(() => {
    //     if (!swiperRef.current) return;

    //     new Swiper(swiperRef.current, {
    //         slidesPerView: 1,
    //         spaceBetween: 0,
    //         grabCursor: true,
    //         effect: "coverflow",
    //         speed: 1500,
    //         loop: true, // Ensures infinite looping
    //         coverflowEffect: {
    //             rotate: 100,
    //             stretch: 0,
    //             depth: 100,
    //             modifier: 1,
    //             slideShadows: false,
    //         },
    //         autoplay: {
    //             delay: 5000,
    //             disableOnInteraction: false,
    //             pauseOnMouseEnter: true,
    //         },
    //         pagination: {
    //             clickable: true,
    //         },
    //         navigation: {
    //             nextEl: ".swiper-button-next",
    //             prevEl: ".swiper-button-prev",
    //         },
    //         on: {
    //             slideChange(swiper) {
    //                 changeBackground(swiper);
    //             },
    //         },
    //     });

    //     function changeBackground(swiper) {
    //         const activeSlide = swiper.slides[swiper.activeIndex];
    //         if (!activeSlide) return;

    //         const bgColor = activeSlide.getAttribute("data-bg-color");
    //         document.body.style.backgroundColor = bgColor; // Example of background color change
    //     }
    // }, [])

    return (
        <>
            <Helmet>
                <title>best microlearning platform| CMS for upskilling and reskilling</title>
                <meta charset="utf-8" />
                <meta name="keywords" content="microlearning platform, CMS, upskilling, reskilling, AI text to video, organizational knowledge transfer" />
                <meta property="og:title" content='best microlearning platform| CMS for upskilling and reskilling' />
                <meta property="og:description" content='upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.' />
                <meta property="og:url" content='https://byteedge.com/' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='best microlearning platform| CMS for upskilling and reskilling' />
                <meta name="twitter:description" content='upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.' />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            {/* ----------------section1------------------ */}
            <section>
                <div className='about-app'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <div className='content'>
                                    <h2>World's 1st AI powered knowledge transfer solution for organization capability development</h2>
                                    <ul className='bullet_points'>
                                        <li>pathbreaking mobile <span>learning experience platform</span> for frontline workforce</li>
                                        <li>enterprise grade <span>learning management system</span> with video content library</li>
                                        <li>document to cinematic <span>video creation</span></li>
                                        <li>global delivery with <span>deep analytics</span> and reporting</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="demo-video">
                                    <video loop muted autoPlay>
                                        <source src="https://cdn.jwplayer.com/videos/3b5j6V2f-VXoqnFRg.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className='flex aiplatform'>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>01</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={experience} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>pathbreaking mobile <span>learning experience platform</span> for frontline workforce</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>02</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={management} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>enterprise grade <span>learning management system</span> with video content library</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>03</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={creation} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>document to cinematic <span>video creation</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <div className='num'>
                                        <span>04</span>
                                    </div>
                                    <div className='circle'>
                                        <div className='round'>
                                            <img src={analytics} alt='byteedge' />
                                        </div>
                                    </div>
                                    <div className='desc'>
                                        <p>global delivery with <span>deep analytics</span> and reporting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section2------------------ */}
            <section>
                <div className="home-sec-E">
                    <div className="container">
                        <div className='partners'>
                            <div className='flex'>
                                <div className='col'>
                                    <Partner imglogo={logo1} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo2} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo3} />
                                </div>
                                {/* <div className='col'>
                                    <Partner imglogo={logo4} />
                                </div> */}
                                <div className='col'>
                                    <Partner imglogo={logo16} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo17} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo5} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo7} />
                                </div>
                                {/* <div className='col'>
                                    <Partner imglogo={logo8} />
                                </div> */}
                                <div className='col'>
                                    <Partner imglogo={logo10} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo9} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo6} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo11} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo12} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo13} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo14} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={logo15} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
            {/* ----------------section3------------------ */}
            <section>
                <div className="home-sec-D">
                    <div className="container">
                        {/* <div className="heading">
                            <h1>impact</h1>
                        </div> */}
                        <div className="flex">
                            <Data startcount={0} endcount={13} title="Countries" />
                            <Data startcount={0} endcount={75} title="Languages" sign="+" />
                            <Data startcount={0} endcount={3000} title="learning modules" sign="+" />
                            <Data startcount={0} endcount={30000} title="courses completed" sign="+" />

                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section4------------------ */}
            <section>
                <div className="product-sec-7">
                    <div className="container">
                        <div className="product-data">
                            <div className="image">
                                <span>83% would rather watch live video than read instructional or information content</span>
                            </div>
                            <div className="image">
                                <span>75% of workers prefer video than documents, email, blogs, books etc - forrester </span>
                            </div>
                            <div className="image">
                                <span>95% knowledge retention in a video compared to 10% when reading it as text </span>
                            </div>
                            <div className="image">
                                <span>96% of people have watched an explainer video to learn more about a product or service </span></div>
                            <div className="image">
                                <span>66% say they pay the most attention to short-form videos which is 2.5x more than long content</span>
                            </div>
                            <div className="image">
                                <span>1200% more clicks, shares and engagements than images and text combined </span>
                            </div>
                            <div className="image">
                                <span>49% of learners don't have time to learn - linkedin workplace learning report</span>
                            </div>
                            <div className="image">
                                <span>human attention span now at 8 secs is lower than that of a goldfish - microsoft brain research</span>
                            </div>
                            <div className="image">
                                <span>28% faster ability to answer questions with microlearning - dresden univ study </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section-casestudy------------------ */}
            <section>
                <div className='case-study-sec'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>we empower organizations with cutting-edge knowledge transfer solutions that drives business outcomes.</h2>
                            <p>explore how leading enterprises across industries have leveraged our next generation mobile Learning Experience Platform (LXP) and new age Learning Management System (LMS), and our AI powered custom video content creation solutions to upskill their teams, enhance productivity, drive growth and accelerate time to market empowering their modern workforce and ecosystem.</p>
                        </div>
                        <Swiper
                            effect="coverflow"
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView="auto"
                            loop={true}
                            speed={1000}
                            coverflowEffect={{
                                rotate: 100,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: false,
                            }}
                            mousewheel= {{
                                forceToAxis: true, 
                                sensitivity: 1,
                            }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                                el: ".swiper-pagination", // Ensure correct selector
                            }}
                            modules={[EffectCoverflow, Pagination, Mousewheel, Autoplay]}
                            className="swiper" ref={swiperRef}>
                            <div className="slide-container swiper-wrapper">
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#1'} title={'One of India’s Most Respected Pharmaceutical Company'} image={casestudy1} industry={'Pharma'} content={'Use Case: Empowering Frontline Sales Teams'} caseid={'case1'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#2'} title={'Fortune 500 Global Multinational'} image={casestudy2} industry={'Materials Sciences'} content={'Use Case: Upskilling Workforce in Multiple Countries in local languages'} caseid={'case2'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#3'} title={'A Leading Multinational Consulting Services Firm'} image={casestudy3} industry={'Professional Services'} content={'Use Case: Workforce Development with Upskilling and Training'} caseid={'case3'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#4'} title={' Leading business conglomerate'} image={casestudy4} industry={'Agritech | Sustainability '} content={'Use Case: Tech-Driven Sales Empowerment of Frontline Teams'} caseid={'case4'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#5'} title={'Popular Global Beverages Brand'} image={casestudy5} industry={'Beverages'} content={'Use Case: Empowering Frontline Sales Workforce dealing with stockist and distributers.'} caseid={'case5'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#6'} title={' A Clean Energy NGO'} image={casestudy6} industry={'Clean Energy NGO'} content={'Use Case: Empowering Marginalized Women and Communities'} caseid={'case6'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#7'} title={'A Global Precision Medtech company'} image={casestudy7} industry={'Medtech'} content={'Use Case:  Empowering Frontline Sales Workers'} caseid={'case7'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#8'} title={'A Global Precision Medtech company'} image={casestudy8} industry={'Medtech'} content={'Use Case:  Empowering Frontline Sales Workers'} caseid={'case8'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#9'} title={' A Global Legendary Watch Brand'} image={casestudy9} industry={'Watch Manufacturing'} content={'Use Case: Empowering Frontline Retail Sales'} caseid={'case9'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#10'} title={'Large Telecom with Cloud & Mobility solutions'} image={casestudy10} industry={'Telecom & Communications'} content={'Use Case: Digital Transformation & Cloud Tools Adoption'} caseid={'case10'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#11'} title={'Large Bank with over 800+ Branches'} image={casestudy11} industry={'banking and Financial Services'} content={'Use Case:  Workforce Capability Development'} caseid={'case11'}/>
                                </SwiperSlide>
                                <SwiperSlide className="slide swiper-slide">
                                    <Casestudy subtitle={'#12'} title={' leading global D2C e-commerce brand'} image={casestudy12} industry={'D2C retail E-Commerce'} content={'Use Case:  Workforce Capability Development'} caseid={'case12'}/>
                                </SwiperSlide>
                            </div>
                            <div className="swiper-pagination"></div>
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* ----------------section5------------------ */}
            <section>
                <div className="home-sec-B1">
                    <div className="container">
                        <div className="heading">
                            <h1>overcome challenges with legacy approaches</h1>
                        </div>
                        <div className="challenges">
                            <div className="flex">
                                <div className="col">
                                    <div className="hexa">
                                        <div className="icon">
                                            <img src={challenge1} alt="shifting-behaviour" />
                                        </div>
                                    </div>
                                    <p className='text'>modernize your industry specific product, policies and processes content
                                        to cinematic videos</p>
                                </div>
                                <div className="col">
                                    <p className='text2'>ignite curiosity and user engagement with short video reels</p>
                                    <div className="hexa hexa2">
                                        <div className="icon">
                                            <img src={challenge2} alt="access-data" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="hexa hexa3">
                                        <div className="icon">
                                            <img src={challenge3} alt="no-time-to-read" />
                                        </div>
                                    </div>
                                    <p className='text3'>improve adoption with cinematic micro-learning video content</p>
                                </div>
                                <div className="col">
                                    <p className='text4'>ai powered in-platform nudging for digital, hybrid and blended
                                        interventions</p>
                                    <div className="hexa hexa4">
                                        <div className="icon">
                                            <img src={challenge4} alt="video-creation" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="hexa hexa5">
                                        <div className="icon">
                                            <img src={challenge5} alt="budget-drain" />
                                        </div>
                                    </div>
                                    <p className='text5'>enhance accessibility with our localised and multi-lingual content in
                                        75+ languages.</p>
                                </div>
                                <div className="col">
                                    <p className='text6'>measure and inspect everything with deep video analytics and data
                                        visualisation</p>
                                    <div className="hexa hexa6">
                                        <div className="icon">
                                            <img src={challenge6} alt="inability-to-calibrate" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ----------------section6------------------ */}
            <section>
                <div className='home-sec-C'>
                    <div className='container'>
                        <div className="heading">
                            <h1>unleash full potential of your people and ecosystem</h1>
                        </div>
                        <OwlCarousel className='homeslider' {...homeslide}>
                            <div className='itemslider'>
                                <Link to="/frontline-sales" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={transform_sales} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Transform Your Frontline Sales and Services Workforce</p>
                                            <Link to="/frontline-sales" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/peer-to-peer-learning" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={communication} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Improve Communication and Collaboration</p>
                                            <Link to="/peer-to-peer-learning" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/compliance" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={compliance} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Create Engaging Compliance Programs</p>
                                            <Link to="/compliance" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/workplace-communication" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={conflicts} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Reduce Workplace Conflicts</p>
                                            <Link to="/workplace-communication" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/workforce-effectiveness" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={workforce} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Calibrate Workforce Effectives</p>
                                            <Link to="/workforce-effectiveness" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/text-to-video" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={trainings} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Modernize Process and Safety Trainings</p>
                                            <Link to="/text-to-video" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='itemslider'>
                                <Link to="/pulse-and-polls" className="test" target="_blank"></Link>
                                <div className="card">
                                    <img src={listening} alt='byteedge' />
                                    <div className="card__content">
                                        <div className="card__content-inner">
                                            <p className="card__excerpt">Voice of your workforce</p>
                                            <Link to="/pulse-and-polls" className="card__link">learn more...</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/* ----------------section7------------------ */}
            <section>
                <div className="testimonial-sec">
                    <div className="container">
                        <div className="heading text-center">
                            <h1>voices of delight</h1>
                        </div>
                        <OwlCarousel {...options}>
                            {testimonials && testimonials.data.data.map((element) => {
                                return <div className="testimonial-card" key={element.id}>
                                    <Clients quote={element.comment} name={element.user_name} desgination={element.user_bio} image={element.user_avatar}></Clients>
                                </div>
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
            {/*----------------section-8------------------ */}
            <section>
                <div className='review-sec'>
                    <div className='container'>
                        <div className='flex'>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_e05f195f-e536-41df-81d1-ae4d14ef9669' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/e05f195f-e536-41df-81d1-ae4d14ef9669' style={{ overflow: 'hidden' }} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_a9345eb2-3eab-43d3-99bf-9240e985d69e' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/a9345eb2-3eab-43d3-99bf-9240e985d69e' style={{ overflow: 'hidden' }} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_ec36c936-a137-4ed0-ab48-b04b3ec33b15' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/ec36c936-a137-4ed0-ab48-b04b3ec33b15' style={{ overflow: 'hidden' }} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_ec36c936-a137-4ed0-ab48-b04b3ec33b15' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/ec36c936-a137-4ed0-ab48-b04b3ec33b15' style={{ overflow: 'hidden' }} scrolling="no"></iframe>
                            </div>
                            <div className='col'>
                                <iframe id='gdm-vp-snippet-quotations_07e7ec28-7d4a-4b5a-a61c-8e1782cc0718' src='https://datainsights-cdn.dm.aws.gartner.com/vp/snippet/07e7ec28-7d4a-4b5a-a61c-8e1782cc0718' style={{ overflow: 'hidden' }} scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*----------------section-patners------------------ */}
            <section>
                <div className="home-sec-G">
                    <div className="container">
                        <div className='heading'>
                            <h2>our parnters</h2>
                        </div>
                        <div className='partners'>
                            <div className='flex'>
                                <div className='col'>
                                    <Partner imglogo={partner1} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={partner2} />
                                </div>
                                <div className='col'>
                                    <Partner imglogo={partner3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <------------------ casestudy-popup---------------------- */}
            <Casestudypop caseid={'case1'} title={"One of India’s Most Respected Pharmaceutical Company"}
             challenge={"raditional physical training was expensive and delayed time to market, onboarding of sales teams, longer sales cycle, inadequate sales pitches around the benefits and science behind the product to doctors."}
             solution={"Mobile LXP and LMS with latest content library of soft skills alongwith over 60+ custom courses about medicine products and the science behind it  enabling salespersons to use the byteEDGE app to learn and apply knowledge at moments of need during sales conversations with doctors and medical practitioners."}
             benefit={"Improved sales growth driven by improved sales conversation, shorter sales cycle,  Improved Time to Market. Lowed cost of knowledge transfer to workforce."}
            />
            <Casestudypop caseid={'case2'} title={"Fortune 500 Global Multinational"}
             challenge={"Need for effective mobile training platform and content across multiple countries in multiple languages for multi region rollout."}
             solution={"Provided behavioural and soft skills courses in 9 languages including Hindi, Thai, Japanese, Korean, Bahasa, Malay and Zulu across 11 countries."}
             benefit={"Enhanced communication, collaboration and productivity of grey collar workforce globally using a single vendor to deploy across multiple regions at low cost."}
            />
            <Casestudypop caseid={'case3'} title={"A Leading Multinational Consulting Services Firm"}
             challenge={"Lack of Mobile and Engaging platform for e-learning lead to limited deployment and poor adoption, completion rates. L&D team spending more time on reporting, nudging and program management."}
             solution={"Curated journeys of byteEDGE courses for all employees, including first time managers, onboarding and various other use cases. Video Reels used as a key driver of user engagement."}
             benefit={"Onboarding and training new joiners with targeted and curated journeys, to democratize learning and build organization capability across 8000+ workforce."}
            />
            <Casestudypop caseid={'case4'} title={" Leading business conglomerate"}
             challenge={"Need to Transform they empower their sales teams including providing product, policies and sales demonstrations for driving growth."}
             solution={"Full stack solution of mobile LXP embedded within salesforce, backend LMS for targeted content deployment, customized domain specific video content on product, policies, salesforce, and more in multiple Indian languages"}
             benefit={"Improved sales techniques, drive improved sales knowledge transfer and knowledge agility and improved engagement with customers, improved time to market and organizational agility."}
            />
            <Casestudypop caseid={'case5'} title={"Popular Global Beverages Brand"}
             challenge={" Training and onboarding multi-lingual modern sales employees across India, salesforce adoption and communicating process and products knowledge while reducing cost. Legacy systems had high friction, low adoption and unable to serve sales team at their moments of need."}
             solution={"Curated journeys of byteEDGE courses for all employees, including first time managers, onboarding and various other use cases. Video Reels used as a key driver of user engagement Full stack solution of mobile LXP embedded within salesforce, backend LMS for targeted content deployment, customized bespoke video courses on product, policies, salesforce, and more in multiple Indian languages to transform knowledge and improved learning accessibility for field teams."}
             benefit={"Efficient frontline onboarding, skilling on process and products alongwith improved adoption of sales tools to drive faster time to market, reduce workplace conflicts and drive growth."}
            />
            <Casestudypop caseid={'case6'} title={"A Clean Energy NGO"}
             challenge={"High cost, long cycles based on physical training of tribal women in using dashboard, financial transactions for them to become entrepreneurs."}
             solution={"Full Stack solution of Mobile LXP, Multi-lingual custom content short videos on company products like solar cookers and fans"}
             benefit={"Empowering tribal women at scale in hard to reach places, enables rapid growth and business transformation with lowered cost."}
            />
            <Casestudypop caseid={'case7'} title={" A Global Precision Medtech company"}
             challenge={"Instructor lead training was ineffective with changing market landscape, delayed onboarding and high cost. Lack of Video based content on medical products, and sales materials essential to sales conversions."}
             solution={" Mobile LXP with Modern LMS, AI powered custom video content on feature, position and role play simulations to improve B2B Sales of medical surgical equipment."}
             benefit={"Significant improvements in sales conversation and conversions with improved product knowledge. Ability to transform from ILT to digital interventions by L&D."}
            />
            <Casestudypop caseid={'case8'} title={"Fintech and Financial Services"}
             challenge={"Compliance with RBI regulations, communication related to product, policy, process change for customer facing workforce."}
             solution={" LXP, LMS, Content Library with Custom video courses on financial domain including process, products and RBI regulations."}
             benefit={"Improved Time to Market, Low Cost and faster compliance to regulatory changes and process onboarding."}
            />
            <Casestudypop caseid={'case9'} title={"Fintech and Financial Services"}
             challenge={"Training frontline retail sales employees on product positioning, reduce cost of training and knowledge transfer. Modernize content like user manuals for increased adoption."}
             solution={" Mobile LXP, LMS, Converting User Manuals to Short Video Cinematic Content including product features for consumer sales."}
             benefit={"Improved sales, faster conversions. Faster Time to Market with New Products."}
            />
            <Casestudypop caseid={'case10'} title={"Fintech and Financial Services"}
             challenge={"Physical Knowledge Transfer leads to delayed time to market, longer sales cycles and lower adoption of tools and new products."}
             solution={" LXP, LMS and Custom Videos to drive digital tool adoption and improve customer experience. Training employees on products features, processes, tasks on the home grown tools."}
             benefit={"Enhanced Tool adoption improving productivity and efficiency, driving growth and adoption."}
            />
            <Casestudypop caseid={'case11'} title={"Large Bank with over 800+ Branches"}
             challenge={"Poor adoption of previous LMS solution, not able to drive outcomes, complex reporting."}
             solution={"Full Stack E-learning Solution with LXP, LMS, Content Library for Workforce Soft Skills that was launch ready."}
             benefit={"Improved employee communication, collaboration leading to improved productivity, and efficiency of a large multi-generational team."}
            />
            <Casestudypop caseid={'case12'} title={"Leading Global D2C E-Commerce Brand"}
             challenge={"Lowered Attention Spans and Distraction needed Micro Learning Approach for skilling modern workforce on soft skills and domain skills."}
             solution={" Full Stack E-learning Solution with LXP, LMS, Content Library for Workforce Soft Skills and AI powered custom content micro videos delivered on a launch ready SaaS solution."}
             benefit={" Improved employee communication, collaboration leading to improved productivity, and efficiency of a large multi-generational workforce."}
            />
        </>
    )
}
