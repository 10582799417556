import React from 'react'

export default function Slider(props) {
    return (
        <>
            <div className="item">
                <img src={props.imageurl} alt="byteedge" />
                <div className="cover">
                    <div className="container">
                        <div className="header-content">
                            <div className="line"></div>
                            <h1>{props.content}</h1>
                            <ul className="bullet_points">
                                <li>{props.bullet1}</li>
                                <li>{props.bullet2}</li>
                                <li>{props.bullet3}</li>
                            </ul>
                            <div className='readmore'>
                                <a href={props.link}>read more...</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
