import React, { useEffect } from 'react'
// import ai from '../video/AI-text-to-video.mp4'
import integrated from '../video/ai-integrated.mp4'
import policy from '../images/product/product-and-policy.jpg'
import sales from '../images/product/sales.jpg'
import ecosystem from '../images/product/ecosystem.jpg'
import social from '../images/product/social-media.jpg'
import learning from '../images/product/e-learning.jpg'
import training from '../images/product/functional-traning.jpg'
import hand from '../images/product/hand.jpg'
import { Helmet } from 'react-helmet'

export default function Textvideo() {
  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', 'With our AI based learning platform, you can create fresh micro learning content in your workforce and deliver it in 75+ languages.')
  })
  return (
    <>
      <Helmet>
        <title>AI based learning platform</title>
        <meta charset="utf-8" />
        <meta name="keywords" content=" AI-based learning platform, text to video solutions, microlearning content, workforce, 75+ languages" />
        <meta property="og:title" content='AI based learning platform' />
        <meta property="og:description" content='With our AI based learning platform, you can create fresh micro learning content in your workforce and deliver it in 75+ languages.' />
        <meta property="og:url" content='https://byteedge.com/text-to-video' />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content='AI based learning platform' />
        <meta name="twitter:description" content='upskill your workforce with AI text to video generation, CMS, Microlearning, organizational knowledge transfer capabilities.' />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://byteedge.com/text-to-video" />
      </Helmet>

      <section>
        <div className="ai-sec-A">
          <div className="bg">
            <video loop autoPlay src="https://cdn.jwplayer.com/videos/JCWibkUK-jscA07lz.mp4"></video>
          </div>
          <div className="content">
            <h2>our generative-ai and deep tech ai for converting your text to video offers</h2>
            <ul className='bullet_points'>
              <li>self service portal</li>
              <li>protects your organization's videos - generate and store in your own cloud instance </li>
              <li>lowest cost always</li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <div className="ai-sec-B">
          <div className="container">
            <div className="heading">
              <h2>legacy text to ai-generated impactful video content in minutes.</h2>
            </div>
            <div className="item">
              <div className="content left">
                <div className="icon">
                  <span>01</span>
                </div>
                <div className="text-box">
                  <p>LLM based assisted writing for target content deployment.</p>
                </div>
              </div>
              <div className="content right">
                <div className="icon">
                  <span>02</span>
                </div>
                <div className="text-box">
                  <p>andragogy & brain science based unique framework.</p>
                </div>
              </div>
              <div className="content left">
                <div className="icon">
                  <span>03</span>
                </div>
                <div className="text-box">
                  <p>ai text extraction from documents with auto summarization.</p>
                </div>
              </div>
              <div className="content right">
                <div className="icon">
                  <span>04</span>
                </div>
                <div className="text-box">
                  <p>ai images and rich media for powerful immersive and impactful videos.</p>
                </div>
              </div>
              <div className="content left">
                <div className="icon">
                  <span>05</span>
                </div>
                <div className="text-box">
                  <p>auto ai generated text to video with 75+ languages.</p>
                </div>
              </div>
              <div className="content right">
                <div className="icon">
                  <span>06</span>
                </div>
                <div className="text-box">
                  <p>proprietary ai meta-avatars as talking head in virtual setting.</p>
                </div>
              </div>
              <div className="content left">
                <div className="icon">
                  <span>07</span>
                </div>
                <div className="text-box">
                  <p>ai-powered rich media video auto synthesis.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section>
        <div className="ai-sec-3">
          <div className="container">
            <div className="ai-video">
              <video loop controls playsInline>
                <source src="https://cdn.jwplayer.com/videos/1F4DOlmR-jscA07lz.mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ai-sec-4">
          <div className="container">
            <div className="heading">
              <h2>empower your business to ideate, create and transform your legacy content.</h2>
            </div>
            <div className="flex">
              <div className="col1">
                <div className="img">
                  <img src={policy} alt="byteedge" />
                </div>
                <div className="content">
                  <p>product & policy documents</p>
                </div>
              </div>
              <div className="col1">
                <div className="img">
                  <img src={sales} alt="byteedge" />
                </div>
                <div className="content">
                  <p>sales enablement</p>
                </div>
              </div>
              <div className="col1">
                <div className="img">
                  <img src={ecosystem} alt="byteedge" />
                </div>
                <div className="content">
                  <p>people & ecosystem development</p>
                </div>
              </div>
              <div className="col1">
                <div className="img">
                  <img src={social} alt="byteedge" />
                </div>
                <div className="content">
                  <p>video for website, blogs & social media</p>
                </div>
              </div>
              <div className="col1">
                <div className="img">
                  <img src={learning} alt="byteedge" />
                </div>
                <div className="content">
                  <p>e-learning modules</p>
                </div>
              </div>
              <div className="col1">
                <div className="img">
                  <img src={training} alt="byteedge" />
                </div>
                <div className="content">
                  <p>functional training videos</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ai-sec-5">

          <div className="img">
            <img src={hand} alt="byteedge" />
          </div>
          <div className="content">
            <h3>ai based video content enhancement</h3>
            <ul className="bullet_points">
              <li>ai dubbing in over 75+ languages of your existing videos</li>
              <li>ai translation for audio from your existing videos</li>
              <li>ai based transcription & closed captioning of your videos</li>
            </ul>
          </div>
        </div>

      </section>
    </>
  )
}
