
import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from './components/Header';
import Home from './components/Home';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-animations/dist/react-animations.js';
import Footer from './components/Footer';
import Product from './components/Product';
import ScrollToTop from './components/ScrollToTop';
import Textvideo from './components/Textvideo';
import Management from './components/Management';
import Pathbreak from './components/Pathbreak';
import Pollssurvey from './components/Pollssurvey';
import Assessments from './components/Assessments';
import Community from './components/Community';
import Complience from './components/Complience';
import Content from './components/Content';
import Solution from './components/Solution';
import Blog from './components/Blog';
import Blogdetails from './components/Blogdetails';
import Contact from './components/Contact';
import Download from './components/Download';
import People from './components/People';
import Pagenotfound from './components/Pagenotfound';
import Privacypolicy from './components/Privacypolicy';
import Termscondition from './components/Termscondition';
import Frontline from './components/Frontline';
// import ReactGA from "react-ga";
import Communication from './components/Communication';
import Termsbusiness from './components/Termsbusiness';
// import Newsletter from './components/Newsletter'
import NewHome from './components/NewHome';
import Video from './components/Video';
import useAnalytics from './hooks/UseAnalytics';

// import './App.css'

// const TRACKING_ID = "G-LEHGQK3WE8";
// ReactGA.initialize(TRACKING_ID)

function App() {

  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  //   ReactGA.send({
  //     hitType: "pageview",
  //     page: window.location.pathname,
  //     title: "homepage"
  //   });
  // },);
  // useAnalytics();
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <AnalyticsWrapper />
        <Routes>
          <Route exact path='/' element={<Home key="home" />} />
          <Route exact path='/products' element={<Product key="product" />} />
          <Route exact path='/text-to-video' element={<Textvideo key="text-to-video" />} />
          <Route exact path='/management-system' element={<Management key="management-system" />} />
          <Route exact path='/pathbreak' element={<Pathbreak key="pathbreak" />} />
          <Route exact path='/pulse-and-polls' element={<Pollssurvey key="pulse-and-polls" />} />
          <Route exact path='/workforce-effectiveness' element={<Assessments key="workforce-effectiveness" />} />
          <Route exact path='/peer-to-peer-learning' element={<Community key="peer-to-peer-learning" />} />
          <Route exact path='/compliance' element={<Complience key="compliance" />} />
          <Route exact path='/content-library' element={<Content key="content-library" />} />
          <Route exact path='/solution' element={<Solution key="solution" />} />
          <Route exact path='/blogs' element={<Blog key="blogs" />} />
          <Route exact path='/blogs/:slug' element={<Blogdetails key="blogs" />} />
          <Route exact path='/contact-us' element={<Contact key="contact-us" />} />
          <Route exact path='/download-our-app' element={<Download key="download-our-app" />} />
          <Route exact path='/people' element={<People key="people" />} />
          <Route exact path='/privacy-policy' element={<Privacypolicy key="privacy-policy" />} />
          <Route exact path='/frontline-sales' element={<Frontline key="frontline-sales" />} />
          <Route exact path='/workplace-communication' element={<Communication key="workplace-communication" />} />
          <Route exact path='/terms-and-conditions' element={<Termscondition key="terms-and-conditions" />} />
          {/* <Route exact path='/newsletter' element={<Newsletter key="newsletter" />} /> */}
          <Route exact path='/pricing' element={<NewHome key="pricing" />} />
          <Route exact path='/demo-video' element={<Video key="demo-video" />} />
          <Route exact path='/standard-terms-of-business' element={<Termsbusiness key="standard-terms-of-business" />} />
          <Route path="/about-us" element={<Navigate replace to="/people" status={301} />} />
          <Route path="/about-us/" element={<Navigate replace to="/people" status={301} />} />
          <Route path="/career_posts/content-writer-2-5-years-1-opening-gurgaon/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/how-it-works/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/curiosity-buzz" element={<Navigate replace to="/" status={301} />} />
          <Route path="/why-us/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/pexels-kampus-production-8636611/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/discover-the-future-of-ld-with-generative-ai" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/background-tree-book-wallpaper-preview/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/the-future-of-blended-learning" element={<Navigate replace to="/" status={301} />} />
          <Route path="/downloads" element={<Navigate replace to="download-our-app" status={301} />} />
          <Route path="/screenshot-4/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/ng/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/coming-soon" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/enhance-your-industry-knowledge/s-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/bebg-4/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/misc-motivational-wallpaper-preview/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/latest_news/a-tactical-guide-to-the-building-blocks-of-an-effective-learning-ecosystem/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/digital-transformation/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2023/08/16/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/l-and-d-needs-to-adapt-to-just-in-time-learning-and-learning-just-enough-models-to-get-the-job-done/" element={<Navigate replace to="/	https://byteedge.com/blogs/l-and-d-needs-to-adapt-to-just-in-time-learning-and-learning-just-enough-models-to-get-the-job-done/" status={301} />} />
          <Route path="/founder/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/building-a-business/s-11/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/	s/building-a-business/s-11/https://www.byteedge.com/csr/pexels-daria-shevtsova-3597111/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/p1-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2023/11/27/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/becoming-a-leader/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/learn-how-generative-ai-can-transform-your-employee-upskilling-efforts/3d-rendering-humanoid-robot-handshake-to-collaborate-future-tech/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/unlocking-professional-potential-with-byteedge-empowering-people-for-success/download/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/career_posts/inside-sales-representative/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/pexels-harry-cunningham-harrydigital-3507767-scaled-jpg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/category/byteedge/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/wp-content/uploads/2023/09/ROI-creative-01.webp" element={<Navigate replace to="/" status={301} />} />
          <Route path="/g-16/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/customer-support/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/modern-lxp_new/new-img/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/md/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/terms-and-conditions" element={<Navigate replace to="/terms-and-conditions" status={301} />} />
          <Route path="/kamal-dutt-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about-us" element={<Navigate replace to="/" status={301} />} />
          <Route path="/become-an-affiliate/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/ca-6-jpg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/living-healthy/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/seo-video/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/partner_img-3-1-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/f279ec_eb597574a9564a3ebb173336432ffb75_mv2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/client-logo-white-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/landing-page/diversity/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/arnold-2-text/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/g-38/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about1-jpg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/career_posts/php-developer-3-5-years-1-opening-gurgaon/php/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/gender-equality-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/1588868473984/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/blog-news-2-750x430/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/app-store/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/playstore/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/slide17/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/g-22/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/boositing-your-career/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeholder-236-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/bg-overlay-counter/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2023/08/24/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/online-learning/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/img-counter-01/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/g-19-750x585/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/team-video/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/pexels-kelly-lacy-5633999-1/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2022/07/26/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/download-our-app-image/with-qr/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/tag/adapting-workforce-to-industry-trends/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/shield-1-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeholder-259-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/service-1-1/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home-hero-bg1-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/earth-globe-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/trial/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/career_posts/inside-sales-representative/isr/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeho" element={<Navigate replace to="/" status={301} />} />
          <Route path="/trial/heide-abelli-pic/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/bdots/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/phone-x-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/wp-content/uploads/2022/08/Standard-Terms-for-Subscription-Service-Agreement-ByteEdge-Learning-Pvt-Ltd.pdf" element={<Navigate replace to="/" status={301} />} />
          <Route path="/service-long-1/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/unlocking-professional-potential-with-byteedge-empowering-people-for-success/creative/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/welcome-to-the-world-of-new-age-learning/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/modern-lxp_new/pexels-pixabay-38544-min/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/products/pexels-photo-7648476/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/download-our-app-image/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/learn-how-generative-ai-can-transform-your-employee-upskilling-efforts/portrait-of-two-businessmen-working-together/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2023/10/19/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home-hero-bg1-jpg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeholder-1-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/improving-work-life-balance/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/monitoring03/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/modern-lxp_new/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/building-a-business/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2022/07/01/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2022/07/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/seo2-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/cultivating2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/cultivating1/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/latest_news/pandemic-impact-companies-increase-spending-in-upskillig-workforce/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/reskilling2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about-us/untitled-1/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about-us/sd/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/video/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about5-jpg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2023/10/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/alvin-2-text/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/the-future-of-blended-learning/blended-learning2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/bg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/speciality_big_img-jpeg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/reskilling-banner/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/twitter/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/img_1/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/p-20/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeholder-299-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/latest_news/heres-how-youth-can-acquire-the-currency-of-the-future-skills/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/2023/07/10/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/g-27/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/ca-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/does-your-leadership-development-program-leverage-all-five-adult-learning-theories-heres-why-it-should/back-lit-business-people-discussion-cityscape-meeting-concept/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/img-consultants-01/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about-us/heide-abelli/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/slide7-jpg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/facebook/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/mail/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/services/thinking-creatively/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/g-23/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/test/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about-us/goutami-2/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/abgal3/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/cultivating-a-culture-of-learning-within-your-organization/" element={<Navigate replace to="/blogs/cultivating-a-culture-of-learning-within-your-organization/" status={301} />} />
          <Route path="/4-3/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/call-back_img-jpeg/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/modern-learner/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeholder-301-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/home/alvin-3-text/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/placeholder-283-png/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/about-us/who-are-we/" element={<Navigate replace to="/" status={301} />} />
          <Route path="/video/" element={<Navigate replace to="/" status={301} />} />
          <Route path='/*' element={<Pagenotfound />} />

        </Routes>
        <Footer />
      </Router>

    </>
  );
}
const AnalyticsWrapper = () => {
  useAnalytics();  // Use the hook to track page views
  return null;  // This component doesn't need to render anything
};

export default App;
