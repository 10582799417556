import React,{useEffect} from 'react'
import transfrom from '../images/solution/transform.jpg'
import growth from '../images/solution/growth.jpg'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


export default function Frontline() {
    useEffect(()=>{
        document.querySelector('meta[name="description"]').setAttribute('content', 'Empower your sales team with cutting-edge strategies and tools for unparalleled success. Transform your frontline sales approach and achieve breakthrough results.x')
    })
    return (
        <>
            <Helmet>
                <title>Revolutionize Your Sales Force with Frontline Sales Transformation </title>
                <meta charset="utf-8" />
                <meta name="keywords" content="Frontline sales, sales transformation, sales strategies, sales tools, sales success" />
                <meta property="og:title" content='Revolutionize Your Sales Force with Frontline Sales Transformation' />
                <meta property="og:description" content='Empower your sales team with cutting-edge strategies and tools for unparalleled success. Transform your frontline sales approach and achieve breakthrough results.' />
                <meta property="og:url" content='https://byteedge.com/frontline-sales' />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content='Revolutionize Your Sales Force with Frontline Sales Transformation' />
                <meta name="twitter:description" content='Empower your sales team with cutting-edge strategies and tools for unparalleled success. Transform your frontline sales approach and achieve breakthrough results.' />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href="https://byteedge.com/frontline-sales" />
            </Helmet>
            <section>
                <div className='frontline-sec-B'>
                    <div className='bg'>
                        <img src={transfrom} alt="bytedge" />
                    </div>
                    <div className='content'>
                        <h2>transform sales readiness!</h2>
                        <p>traditional training is ineffective and not driving the level of productivity. performance in growth, engagement and knowledge are what businesses need to better from their frontline sales teams</p>
                    </div>
                    <div className='growth-sec'>
                        <div className='container'>
                            <div className='flex'>
                                <div className='col'>
                                    <img src={growth} alt='byteedge' />
                                </div>
                                <div className='col'>
                                    <div className='text'>
                                        <h3>overcome challenges and drive growth</h3>
                                        <ul className='bullet_points'>
                                            <li>sales reps often struggle to convert deals</li>
                                            <li>sales giving away too much discount and leaving no money on the table</li>
                                            <li>sales unable to communicate the value</li>
                                            <li>sales unable to tell the brand story</li>
                                            <li>sales not being updated with latest product literature</li>
                                            <li>sales not being engaged, lacking motivation and being unproductive</li>
                                            <li>difficulty in transforming marketing materials into effective, immersive, conversational micro short videos  </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section>
                <div className='frontline-sec-A'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>we empower sales teams for peak performance everyday.</h2>
                        </div>
                        <div className='flex'>
                            <div className='col'>
                                <div className='item'>
                                    <p>drive deal-winning behaviour with video-based sales coaching, product training, objection handling and deal closure.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>inspire them with world class curated content for every sales stage - discovery, prospecting to closing!</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>empower them with immersive product literature videos served at the moment of need.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>transform to a continuous learning and winning sales teams that build your real organization edge.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='item'>
                                    <p>real-time learning of incentive plans, rewards, policies, process, product updates in video formats.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='frontline-sec-C'>
                    <div className='container'>
                        <div className='heading'>
                            <h2>investing in your sales team gives the best roi</h2>
                        </div>

                        <div className='content'>
                            <ul className='bullet_points'>
                                <li>user-friendly mobile app with hd quality immersive short videos for daily consumption so as to change behaviours</li>
                                <li>ai-driven video content creation of your every sales material, policy, rewards and more</li>
                                <li>user-generated content sharing in a community for best practice sharing</li>
                                <li>ability to train and engage your sales force in 75+ languages</li>
                                <li>link every sales order to skill and learning on the platform</li>
                                <li>get real time analytics on content consumption and linkages to sales conversions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='frontline-sec-D'>

                    <div className='heading'>
                        <h2>our key solutions</h2>
                        <p>reduce time to market for new hires <span>|</span> help them succeed by day 1</p>
                    </div>
                    <div className='solution-sec'>

                        <div className='flex'>
                            <div className='col'>
                                <div className='text'>
                                    <h3 className='mb-3'>ai generated micro videos</h3>
                                    <p>transform all your sales material and trainings to cinematic video content using our self-service portal or our professional services-learn<Link to='/text-to-video'> more here</Link></p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='text'>
                                    <h3 className='mb-3'>measure everything!</h3>
                                    <p>unlock the full potential of your customer-facing teams by continuously understanding and refining the skills and behaviors of your frontline sales teams, leading to sustained improvement in sales performance.</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='content'>
                                    <h3 className='mb-3'>onboard and train with confidence</h3>
                                    <ul className='bullet_points'>
                                        <li>curated sales journeys</li>
                                        <li>automated knowledge checks, quiz and certificates</li>
                                        <li>sales tips and tricks in reels</li>
                                        <li>learn from sales veterans in community</li>
                                        <li>gamification of sales team - leaderboard, rankings</li>
                                        <li>engage & motivate - pusle surveys, assessments, & more</li>
                                    </ul>
                                    <p className='mt-2'>learn more about our curated sales journeys <Link to="/content-library#journeys">here.</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
