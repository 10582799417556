import React from 'react'
// import download from '../images/download-banner.jpg'
import { Link } from 'react-router-dom'

export default function Pagenotfound() {
  return (
    <section class="page_404">
      <div class="container">
        <div class="four_zero_four_bg">
          <h1 class="text-center ">404 page! not found</h1>
        </div>
        <div class="contant_box_404">
          <h3 class="h2">
            Look like you're lost
          </h3>
          <p>the page you are looking for not avaible!</p>
          <Link to="/" class="link_404">Go to Home</Link>
        </div>


      </div>
    </section>
    // <div>
    //   <div className='download-banner'>
    //     <div className='bg'>
    //       <img src={download} alt='download'></img>
    //     </div>
    //     <div className='content'>
    //       <h2>404 Page! Not found</h2>

    //     </div>
    //   </div>
    //   <section>
    //     <div class="pagenotfound">
    //       <div class="container">
    //         <div class="heading text-center">
    //           <p>sorry, The page you are looking for no longer exists. perhaps you can return back to the site's <a href="/">homepage</a> and see if you can find what you are looking for.<br /><br /><a href="/">Visit the Home Page</a></p>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

    // </div>
  )
}
