import React from 'react'
import casestudy1 from '../images/home/case-study1.jpg'
import { Link } from 'react-router-dom'
import $ from 'jquery'


export default function Casestudy(props) {
    return (
        <>
            <div className="card">
                <div className="img-wrapper">
                    <img className="floating" src={props.image} alt="casestudy" />
                </div>
                <div className="card-content">
                    <div className="card-sub-title">{props.subtitle} case study</div>
                    <h2 className="card-title">{props.title}
                    </h2>
                    <p className="card-description" style={{ color: "#fff" }}>{props.content}
                    </p>
                    <div className="card-cta" data-value={props.caseid}>
                        <Link to="javascript:void(0);" className="cta-button">read more</Link>
                    </div>
                </div>
                <div className="card-ghost-info">
                    <span style={{ color: '#FF5B79' }}></span>
                    <div className="ghost-name">Industry Vertical</div>
                    <div className='indus-name'>{props.industry}</div>
                </div>
            </div>

           
        </>
    )
}
